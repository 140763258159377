// src/middleware/cartMiddleware.js

const cartMiddleware = store => next => action => {
    next(action); // Laisser passer l'action au reducer
  
    const nextState = store.getState();
    
    // Vérifier si l'action est liée au panier
    if(['cart/addItem', 'cart/removeItem', 'cart/updateQuantity'].includes(action.type)) {
      // Sauvegarder le contenu du panier dans localStorage
      localStorage.setItem('cart', JSON.stringify(nextState.cart.items));
    }
  };
  
  export default cartMiddleware;
  