import { useState } from "react";
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { formatPrice } from "./PriceTag.js";
import { useSelector,useDispatch } from "react-redux";
import { selectCartTotal,applyPromotion } from "../../slices_ReduxToolkit/cartSlice.js";
import { selectIsLoggedIn } from "../../slices_ReduxToolkit/userSlice.js";


export function CartOrderSummary() {
  const { total, tax, discount, isFreeShipping } = useSelector(selectCartTotal);
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  


  const handleApplyPromo = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/promotionCode/apply', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: promoCode })
      });
  
      if (!response.ok) {
        throw new Error(await response.text());
      }
  
      const { discount } = await response.json();
      dispatch(applyPromotion(discount)); 
  
      toast({
        title: "Youpi !",
        description: "Votre code promo a été appliqué avec succès !",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Oups !",
        description: error.message || "Quelque chose a mal tourné !",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  const OrderSummaryItem = (props) => {
    const { label, value, children } = props;
      return (
      <Flex justify="space-between" fontSize="sm">
        <Text fontWeight="medium" color={mode("gray.600", "gray.400")}>
          {label}
        </Text>
        {value ? <Text fontWeight="medium">{value}</Text> : children}
      </Flex>
    );
  };

  const isCartEmpty = total <= 0; // Vérifie si le panier est vide

  const handleCheckoutClick = () => {
    if (isLoggedIn) {
      navigate("/checkAddress"); // Redirection vers /checkAddress si l'utilisateur est connecté
    } else {
      navigate("/subscription"); // Redirection vers /subscription si l'utilisateur n'est pas connecté
    }
  };

  return (
    <Stack
      mt={{
        base: "2%",
        lg: "20%",
      }}
      spacing="8"
      borderWidth="1px"
      rounded="lg"
      padding="5"
      width={{
        base: "100%",
        lg: "100%",
      }}
      backgroundColor="white"
    >
      <Heading size="md" color="#293D36">
        Récapitulatif de la commande
      </Heading>
      <Stack>
      <OrderSummaryItem label="Sous-total" value={formatPrice(total)} />
        <OrderSummaryItem label="TVA (20%)" value={formatPrice(tax)} />
        {isFreeShipping && <OrderSummaryItem label="Frais de livraison" value="Offert" />}
        {discount > 0 && <OrderSummaryItem label="Code promo" value={`- ${formatPrice(discount)}`} />}        {/* Nouvelle ligne pour informer sur la livraison */}
        <OrderSummaryItem label="Frais de livraison" value="Calculés à l'étape suivante" />

        <Text color="#293d36">
        Vous avez un code promo ? Entrez-le et regardez la magie opérer ! 🎩✨
        </Text>
        <Flex mb="2%">
          <Input focusBorderColor="#98b29f" borderColor="293d36" placeholder="Code promo" value={promoCode} onChange={(e) => setPromoCode(e.target.value)} />
          <Button onClick={handleApplyPromo} ml={2}backgroundColor="#98b29f">Appliquer</Button>
        </Flex>

        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold" color="#293D36">
            Total
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(total)}
          </Text>
        </Flex>
      </Stack>

      <Button
        onClick={handleCheckoutClick}
        fontSize="lg"
        color="#293d36"
        rightIcon={<FaArrowRight />}
        isDisabled={isCartEmpty}
        backgroundColor="#98b29f"
      >
        Choix de la livraison
      </Button>
    </Stack>
  );
}
