import React, { useState,useEffect } from "react";
import { API_URLS } from "../apiConfig";
import {
  FormHelperText,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  HStack,
  Heading,
  useToast,
  IconButton,
  CloseButton,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";


const AdminPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [productData, setProductData] = useState({
    sku: "",
    sizes: [
      {
        contenance: "",
        price: 0,
        quantity: 0,
      }
    ],
    discount: 0,
    preTaxTotal: 0,
    tax: 0,
    total: 0,
    title: "",
    underTitle: "",
    category: "",
    description: "",
    information: "",
    mainImage: "",
    images: [],
    videos: [],
  });
  const toast = useToast();

  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const isAdmin = useSelector(state => state.user.isAdmin);

  useEffect(() => {
    // Vérifier si l'utilisateur est connecté et a le statut d'administrateur
    if (!isLoggedIn || !isAdmin) {
      navigate('/');
    }
  }, [isAdmin, isLoggedIn, navigate]);

  const handleSizeChange = (index, field, value) => {
    const newSizes = [...productData.sizes];
    newSizes[index][field] = value;
    setProductData({ ...productData, sizes: newSizes });
  };

  const addSize = () => {
    setProductData({
      ...productData,
      sizes: [...productData.sizes, { contenance: "", price: 0, quantity: 0 }],
    });
  };

  const removeSize = (index) => {
    const newSizes = [...productData.sizes];
    newSizes.splice(index, 1);
    setProductData({ ...productData, sizes: newSizes });
  };

  useEffect(() => {
    // Réinitialiser les alertes si les données du produit changent
    setSuccessMessage('');
    setHasError(false);
  }, [productData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleImageChange = (e) => {
    setProductData({ ...productData, images: e.target.files });
  };

  const handleMainImageChange = (e) => {
    setProductData({ ...productData, mainImage: e.target.files[0]});
  };
  
  

  const handleVideoChange = (e) => {
    setProductData({ ...productData, videos: e.target.files });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("sku", productData.sku);
    formData.append("sizes", JSON.stringify(productData.sizes));
    formData.append("discount", productData.discount);
    formData.append("preTaxTotal", productData.preTaxTotal);
    formData.append("tax", productData.tax);
    formData.append("total", productData.total);
    formData.append("title", productData.title);
    formData.append("underTitle", productData.underTitle);
    formData.append("category", productData.category);
    formData.append("description", productData.description);
    formData.append("information", productData.information);
    formData.append("mainImage", productData.mainImage);


    // Ajouter les images au formData
for (let i = 0; i < productData.images.length; i++) {
  formData.append("images", productData.images[i]);
}

// Ajouter les vidéos au formData
for (let i = 0; i < productData.videos.length; i++) {
  formData.append("videos", productData.videos[i]);
}

    // Envoyer les données à l'API Express 
    fetch(API_URLS.CREATE_ITEM, {
      method: "POST",
      body: formData,
      credentials: 'include'
    })
      .then((response) => response.json())
      .then((data) => {
        // Réinitialiser le formulaire après la soumission réussie
        setProductData({
          sku: "",
          sizes: [
            {
              contenance: "",
              price: 0,
              quantity: 0,
            }
          ],
          discount: 0,
          preTaxTotal: 0,
          tax: 0,
          total: 0,
          title: "",
          underTitle: "",
          category: "",
          description: "",
          information: "",
          mainImage: "",
          images: [],
          videos: [],
        });
    setIsLoading(false); // Fin du chargement
   // Utiliser le toast pour le succès
   toast({
    title: "Succès!",
    description: data.message,
    status: "success",
    duration: 5000,
    isClosable: true,
    position: "bottom-left"
  });
})
.catch((error) => {
  setIsLoading(false); // Fin du chargement en cas d'erreur
  
  // Utiliser le toast pour les erreurs
  toast({
    title: "Erreur.",
    description: "Une erreur est survenue lors de la création du produit.",
    status: "error",
    duration: 5000,
    isClosable: true,
    position: "bottom-left"
  });
});
  };

  return (
    <Box
    p="6"
    mt="10%"
    mx="auto"
    maxW="2xl"
    borderWidth={1}
    borderRadius="lg"
    boxShadow="lg"
    backgroundColor="white"
    position="relative"
    zIndex="2">
    <Heading mb="6" textAlign="center">
      Créer une nouvelle fiche produit
    </Heading>

    <form onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        {/* SKU */}
        <FormControl isRequired>
          <FormLabel>SKU</FormLabel>
          <Input
            type="number"
            name="sku"
            value={productData.sku}
            onChange={handleChange}
            placeholder="Entrez le SKU"
          />
        </FormControl>

        {/* Sizes */}
        {productData.sizes.map((size, index) => (
          <HStack key={index} spacing={4} align="flex-end">
            <FormControl isRequired>
              <FormLabel>Contenance {index + 1}</FormLabel>
              <Input
                name="contenance"
                value={size.contenance}
                onChange={(e) => handleSizeChange(index, 'contenance', e.target.value)}
                placeholder="Entrez la contenance"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Prix {index + 1}</FormLabel>
              <Input
                type="number"
                name="price"
                value={size.price}
                onChange={(e) => handleSizeChange(index, 'price', e.target.value)}
                placeholder="Entrez le prix"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Quantité {index + 1}</FormLabel>
              <Input
                type="number"
                name="quantity"
                value={size.quantity}
                onChange={(e) => handleSizeChange(index, 'quantity', e.target.value)}
                placeholder="Entrez la quantité"
              />
            </FormControl>
            {productData.sizes.length > 1 && (
              <CloseButton onClick={() => removeSize(index)} />
            )}
          </HStack>
        ))}
        <IconButton
          colorScheme="teal"
          aria-label="Ajouter une taille"
          icon={<AddIcon />}
          onClick={addSize}
          mt={2}
          mb={4}
        />

        <FormLabel>Remise:</FormLabel>
        <Input
          type="number"
          name="discount"
          value={productData.discount}
          onChange={handleChange}
        />

        <FormLabel>Total (avant taxes):</FormLabel>
        <Input
          type="number"
          name="preTaxTotal"
          value={productData.preTaxTotal}
          onChange={handleChange}
        />

        <FormLabel>Taxe:</FormLabel>
        <Input
          type="number"
          name="tax"
          value={productData.tax}
          onChange={handleChange}
        />

        <FormLabel>Total (avec taxes):</FormLabel>
        <Input
          type="number"
          name="total"
          value={productData.total}
          onChange={handleChange}
        />

        <FormLabel>Catégorie:</FormLabel>
        <Input
          type="text"
          name="category"
          value={productData.category}
          onChange={handleChange}
        />
        
        <FormLabel>Title:</FormLabel>
        <Input
          type="text"
          name="title"
          value={productData.title}
          onChange={handleChange}
        />
        <FormLabel>Sous-Titre:</FormLabel>
        <Input
          type="text"
          name="underTitle"
          value={productData.underTitle}
          onChange={handleChange}
        />
        <FormLabel>Description:</FormLabel>
        <Textarea
          name="description"
          value={productData.description}
          onChange={handleChange}
        ></Textarea>

<FormLabel>Information:</FormLabel>
        <Textarea
          name="information"
          value={productData.information}
          onChange={handleChange}
        ></Textarea>

          {/* Images */}

          <FormControl>
  <FormLabel>Image principale</FormLabel>
  <Input
    type="file"
    name="mainImage"
    onChange={handleMainImageChange}
    border="none"
  />
  <FormHelperText>Sélectionnez l'image principale du produit.</FormHelperText>
</FormControl>

          <FormControl>
            <FormLabel>Images</FormLabel>
            <Input
              type="file"
              name="images"
              multiple
              onChange={handleImageChange}
              border="none"
            />
            <FormHelperText>Vous pouvez sélectionner plusieurs images.</FormHelperText>
          </FormControl>

          {/* Vidéos */}
          <FormControl>
            <FormLabel>Vidéos</FormLabel>
            <Input
              type="file"
              name="videos"
              multiple
              onChange={handleVideoChange}
              border="none"
            />
            <FormHelperText>Vous pouvez sélectionner plusieurs vidéos.</FormHelperText>
          </FormControl>

          {/* Submit Button */}
          <Button type="submit" colorScheme="blue" isLoading={isLoading}>
            Créer une fiche produit
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default AdminPage;
