import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { API_URLS } from "../../Components/apiConfig.js";


import {
  Flex,
  Box,
  Center,
  List,
  ListItem,
  Button,
  useToast,
  Heading,
  Text,
  Image,
} from "@chakra-ui/react";

const ClientOrders = () => {
  const [orderList, setOrderList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  // Utilisation de useSelector pour récupérer userId depuis le state de Redux
  const userId = useSelector((state) => state.user.userId);

  const STATIC_URL = process.env.REACT_APP_STATIC_URL || 'http://localhost:5000/uploads/';


  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/connexion");
    } else {
      fetchOrderHistory();
    }
  }, [userId, isLoggedIn, navigate]);

  const fetchOrderHistory = async () => {
    try {
      const response = await fetch(
        API_URLS.GET_USER_ORDERS(userId),
        {
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error(
          "Trouble fetching order history. Please try again later."
        );
      }

      const data = await response.json();
      setOrderList(data);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération de l'historique des commandes :", error);
      toast({
        title: "Oups, quelque chose s'est mal passé !",
        description:
          "Nous rencontrons un problème pour afficher l'historique des commandes. Veuillez réessayer plus tard.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleOrderClick = async (orderId) => {
    try {
      // Récupération des détails de la commande
      const response = await fetch(
        API_URLS.GET_ORDER_DETAILS(orderId),
        {
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error(
          "Problème lors de la récupération des détails de la commande"
        );
      }

      const orderData = await response.json();

      // Vérifiez si la commande contient des articles
      if (orderData.items && orderData.items.length > 0) {
        // Récupérer les détails de chaque article de la commande
        const itemsWithDetails = await Promise.all(
          orderData.items.map(async (item) => {
            const itemResponse = await fetch(
              API_URLS.GET_PRODUCT_DETAIL(item.itemId)
            );

            if (!itemResponse.ok) {
              throw new Error(
                "Problème lors de la récupération des détails du produit"
              );
            }

            const itemDetails = await itemResponse.json();
            // Fusionner les détails du produit avec les données de l'article de la commande
            return { ...item, ...itemDetails };
          })
        );

        // Mettre à jour les détails de la commande avec les données complètes des articles
        setSelectedOrder({ ...orderData, items: itemsWithDetails });
      } else {
        // Si pas d'articles, on sélectionne juste la commande sans articles détaillés
        setSelectedOrder(orderData);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des détails de la commande:",
        error
      );
      toast({
        title: "Erreur",
        description: "Impossible de récupérer les détails de la commande.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderOrderDetails = (order) => {
    return (
      <Center>
        <Box
          p="4"
          borderWidth="1px"
          borderRadius="md"
          bg="gray.50"
          width="95%"
          color="#293d36"
          
        >
          <Heading size="md" mb="2">
            Détails de la commande
          </Heading>
          <Text>
            <strong>Date:</strong>{" "}
            {new Date(order.orderDate).toLocaleDateString()}
          </Text>
          <Text>
            <strong>Status:</strong> {order.status}
          </Text>
          <Text>
            <strong>Livraison:</strong> {order.carrier}
          </Text>
          <Text>
            <strong>Total:</strong> €
            {order.totalAmount ? order.totalAmount.toFixed(2) : "0.00"}
          </Text>
          <Text>
            <strong>Discount:</strong> €
            {order.discount ? order.discount.toFixed(2) : "0.00"}
          </Text>
          <Text>
            <strong>Coût de livraison:</strong> €
            {order.shippingCost ? order.shippingCost.toFixed(2) : "0.00"}
          </Text>

          {
  order.mondialRelayPoint ? (
    <Box mt="2">
      <Heading size="sm">Point Mondial Relay:</Heading>
      <Text>
        {order.mondialRelayPoint.Nom}, {order.mondialRelayPoint.Adresse}
      </Text>
    </Box>
  ) : (
    <Box mt="2">
      <Heading size="sm">Adresse de livraison:</Heading>
      <Text>
        {order.shippingAddress.firstName}{" "}
        {order.shippingAddress.lastName}
      </Text>
      <Text>
        {order.shippingAddress.address}, {order.shippingAddress.city},{" "}
        {order.shippingAddress.zipcode}, {order.shippingAddress.country}
      </Text>
    </Box>
  )
}
          {order.items && order.items.length > 0 && (
            <Box mt="4">
              <Heading size="sm" mb="2">
                Articles commandés:
              </Heading>
              {order.items.map((item) => (
                <Flex
                  key={item.itemId}
                  direction="row"
                  align="center"
                  p={{ base: "2", md: "4" }}
                >
                  {/* Image de l'article */}
                  <Image
                    rounded="lg"
                    width={{ base: "40px", md: "80px" }} // Taille réduite
                    height={{ base: "40px", md: "80px" }}
                    fit="cover"
                    src={`${STATIC_URL}/${item.mainImage}`} 
                    alt={item.name}
                    draggable="false"
                    loading="lazy"
                  />

                  {/* Nom et quantité de l'article */}
                  <Box ml="3">
                    <Text fontWeight="medium" fontSize="md">
                      {item.name}
                    </Text>
                    <Text fontSize="sm">Quantité: {item.quantityInCart}</Text>
                  </Box>
                </Flex>
              ))}
            </Box>
          )}
        </Box>
      </Center>
    );
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      mt={{ base: "25%", md: "15%", lg: "15%", xl:"10%" }}
      mb="3%"
      position="relative"
      zIndex="2"
    >
      <Box
        rounded="lg"
        bg="white"
        boxShadow="lg"
        p="4"
        mt="2%"
        w="full"
        maxW={{ base: "90%", md: "20%", lg: "20%" }}
      >
        <Heading as="h1" size="lg" mb="3%" color="#293d36" textAlign="center">
        Mes commandes
      </Heading>

        {loading ? (
          <p>Chargement...</p>
        ) : selectedOrder ? (
          <Box>
                       {renderOrderDetails(selectedOrder)}
                       <Center>
                       <Button
              color="#293d36"
              background="#98b29f"
              mt="3%"
              onClick={() => setSelectedOrder(null)}
            >
              Retour aux commandes
            </Button>
            </Center>
          </Box>
        ) : (
          <List spacing={4}>
            {orderList.map((order) => (
              <ListItem
                key={order._id}
                p="4"
                borderWidth="1px"
                borderRadius="md"
                cursor="pointer"
                _hover={{ bg: "gray.100" }}
                onClick={() => handleOrderClick(order._id)}
                mt="2%"
              >
                <Text>
                  Commande du {new Date(order.orderDate).toLocaleDateString()}
                </Text>
                <Text>
                  <strong>Total:</strong> €
                  {order.totalAmount ? order.totalAmount.toFixed(2) : "0.00"}
                </Text>
                <Text>Status: {order.status}</Text>
              </ListItem>
            ))}
          </List>
        )}
        <Center mt="2%">
          <Button
            as={RouterLink}
            to="/myAccount"
            color="#293d36"
            background="#98b29f"
            mt="1%"
          >
            Retour à mon compte
          </Button>
        </Center>
      </Box>
    </Flex>
  );
};

export default ClientOrders;
