import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  List,
  ListItem,
  Text,
  Box,
  VStack,
  Center,
  Flex,
  HStack,
  Image,
  Icon,
} from "@chakra-ui/react";
import { FaArrowLeft, FaTag, FaLock, FaHome, FaShoppingCart, FaShippingFast, FaMapMarkerAlt } from "react-icons/fa";
import StepProgress from "./StepProgress.js";
import { useNavigate } from "react-router-dom";
import { setLoggedIn } from "../../slices_ReduxToolkit/userSlice.js";
import { Helmet } from 'react-helmet';
import { API_URLS } from "../../Components/apiConfig.js";





// Custom Hook pour la vérification de session
const useSessionCheck = (navigate, dispatch) => {
  useEffect(() => {
    fetch(API_URLS.CHECK_SESSION, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          dispatch(setLoggedIn({ ...data }));
        } else {
          navigate("/Connexion");
        }
      })
      .catch(() => navigate("/Connexion"));
  }, [dispatch, navigate]);
};

// Custom Hook pour les informations de livraison
const useShippingInfo = () => {
  const [shippingData, setShippingData] = useState({});

  useEffect(() => {
    const fetchShippingInfo = async () => {
      try {
        const response = await fetch(API_URLS.GET_SHIPPING_INFO,
          { credentials: "include" }
        );
        if (!response.ok) throw new Error("Failed to fetch shipping info");
        setShippingData((await response.json()).shippingDetails);
      } catch (error) {
        console.error("Error fetching shipping info:", error);
      }
    };
    fetchShippingInfo();
  }, []);

  return shippingData;
};

function StepThree() {
 const cart = useSelector((state) => state.cart);
const order = useSelector((state) => state.order);
const userId = useSelector((state) => state.user.userId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [paymentError, setPaymentError] = useState(null);
  const promotion = useSelector((state) => state.cart.promotion);

    
  // Détermination de l'adresse de livraison ou du point relais
  useSessionCheck(navigate, dispatch);
  const shippingData = useShippingInfo();

  const shippingAddress = shippingData.shippingAddress
    ? `${shippingData.shippingFirstName} ${shippingData.shippingLastName}, ${shippingData.shippingAddress}, ${shippingData.shippingCity}, ${shippingData.shippingZipcode}, ${shippingData.shippingCountry}`
    : "Fallback address here..."; // Assurez-vous que ceci est défini dans le corps du composant

    const shippingCost = useSelector((state) => state.order.shippingCost);

    // Calcul du montant total avec la promotion
  const totalAmount = cart.items.reduce(
    (total, item) => total + item.price * item.quantity, 0
  );

  let discount = 0;
  if (promotion) {
    switch (promotion.type) {
      case 'percentage':
        discount = totalAmount * (promotion.value / 100);
        break;
      case 'fixed':
        discount = promotion.value;
        break;
      case 'freeShipping':
        discount = order.selectedCarrier ? order.selectedCarrier.cost : 0;
        break;
      default:
        discount = 0;
        break;
    }
  }

  const finalTotal = totalAmount + (order.selectedCarrier ? order.selectedCarrier.cost : 0) - discount;



   
    

  const handleCheckout = async () => {

     
    try {
      // Préparation des données pour initier le paiement Stripe
      const paymentData = {
        items: cart.items, // Informations sur les articles
        userId: userId,
        shippingCost: order.selectedCarrier ? order.selectedCarrier.cost : 0,
        discount: discount, // Ajout du montant de la réduction
        promotionType: promotion ? promotion.type : null, // Ajout du type de promotion
      };

      // Initier la session Stripe
      const stripeSessionResponse = await fetch(API_URLS.CREATE_STRIPE_SESSION,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(paymentData),
          credentials: "include",
        }
      );

      const stripeSession = await stripeSessionResponse.json();
      if (stripeSessionResponse.ok) {
        localStorage.setItem("stripeSessionId", stripeSession.sessionId); // Enregistrez l'ID de la session
        window.location.href = stripeSession.url;
      } else {
        throw new Error("URL de session Stripe manquante ou incorrecte");
      }
    } catch (error) {
      console.error("Erreur lors du processus de paiement :", error);
      setPaymentError(error.message || "Erreur lors du traitement du paiement");
    }
  };

   return (
    <Flex direction="column" alignItems="center" mt={{ base: "30%", md: "15%",lg: "15%", xl:"10%" }} mb="5%" position="relative"
    zIndex="2">
      
       <Helmet>
      <title>Récapitulatif de Commande - Imagine Factory</title>
      <meta name="description" content="Vérifiez vos articles et procédez au paiement sécurisé pour finaliser votre commande." />
    </Helmet>

    <StepProgress currentStep={3}/>
    <Box rounded="lg" bg="white" boxShadow="lg" p="4" mt={{ base: "6%", md: "1%",lg: "4%",xl:"2.5%" }} w="full" maxW={{ base: "xs", md: "sm",lg: "sm", xl:"lg" }}>
      <VStack spacing={4}>

        {/* Icône pour le récapitulatif des articles */}
        <Center align="center" w="full">
          <Box mr="2%" color="#293D36"> <Icon as={FaShoppingCart} boxSize={{ base: "24px", md: "36px" }} color="#293D36" mr="5"/></Box>
          <Text fontSize="xl" fontWeight="bold" color="#293D36" mb="3%" mt="3%">
           Récapitulatif de commande
          </Text>
        </Center>

        <List spacing={3} w="full">
            {cart.items.map((item) => (
              <ListItem key={item._id} display="flex" alignItems="center">
                <Image
                  rounded="lg"
                  width={{ base: "50px", md: "80px" }}
                  height={{ base: "50px", md: "80px" }}
                  fit="cover"
                  src={item.mainImage} 
                  alt={item.name}
                  draggable="false"
                  loading="lazy"
                  mr={4}
                />
                 <Box flex="1">
                  <Text fontWeight="bold" color="#293D36">{item.name}</Text>
                  <Text fontSize="sm" color="#293D36">{`Qty: ${item.quantity}`}</Text>
                </Box>
                <Text color="#293D36">{`${(item.price * item.quantity).toFixed(2)}€`}</Text>
              </ListItem>
            ))}
          </List>

        {/* Icône et montant de la réduction */}
        {discount > 0 && (
          <Flex align="center" justify="space-between" w="full">
            <Box mr={2} color="#293D36"><FaTag /></Box>
            <Text fontWeight="medium">{`- ${discount.toFixed(2)}€`}</Text>
          </Flex>
        )}

        {/* Icône et coût de la livraison */}
        <Flex align="center" justify="space-between" w="full">
          <Box mr={2} color="#293D36"><Icon as={FaShippingFast} boxSize={{ base: "24px", md: "36px" }} color="#293D36" mr="5"/></Box>
          <Text fontWeight="medium" color="#293D36">{`${(order.selectedCarrier ? order.selectedCarrier.cost : 0).toFixed(2)}€`}</Text>
        </Flex>

        {/* Total final */}
        <Flex align="center" justify="space-between" w="full">
          <Text fontWeight="bold" color="#293D36">Total:</Text>
          <Text fontWeight="bold" fontSize="xl" color="#293D36">{`${finalTotal.toFixed(2)}€`}</Text>
        </Flex>

        {/* Adresse de livraison avec icône */}
                 {/* Affichage de l'adresse de livraison ou du point relais */}
                 {order.selectedCarrier.name === "MondialRelay" && order.relayPoint ? (
            <Flex align="center">
              <Box mr={2}>
                <Icon as={FaMapMarkerAlt} boxSize={{ base: "24px", md: "36px" }} color="#293D36"  mr="19"/>
              </Box>
              <Text color="#293D36" ml="10">
                {order.relayPoint.Nom},{" "} {order.relayPoint.Adresse}{" "}{order.relayPoint.CP}{" "}{order.relayPoint.Ville}{" "}{order.relayPoint.Pays}
              </Text>
            </Flex>
          ) : (
            <Flex align="center">
              <Box mr={2}>
                <Icon as={FaHome} boxSize={{ base: "24px", md: "36px" }} color="#293D36" mr="5"/>
              </Box>
              <Text color="#293D36">
                {shippingAddress}
              </Text>
            </Flex>
          )}
        
        {/* Boutons de navigation ajustés */}
        <HStack spacing={4} justify="center" w="full" mt={4}>
          <Button
            leftIcon={<FaArrowLeft />}
            onClick={() => navigate(-1)}
            color="#293d36" 
            background="#98b29f"
        
          >
          </Button>
          <Button
            colorScheme="green"
            rightIcon={<FaLock />}
            onClick={handleCheckout}
          
          >
            Paiement sécurisé
          </Button>
        </HStack>
      </VStack>
    </Box>
  </Flex>
);
};

export default StepThree;
