import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URLS } from "../Components/apiConfig.js";


export const fetchArticles = createAsyncThunk(
  'articles/fetchArticles',
  async () => {
    const response = await fetch(API_URLS.GET_PRODUCT_LIST);
    return response.json();
  }
);

const articlesSlice = createSlice({
  name: 'articles',
  initialState: {
    items: [],
    selectedCategory: null,
    loading: false,
  },
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArticles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchArticles.fulfilled, (state, action) => {
      state.items = action.payload.filter(article => article.isVisible);
      state.loading = false;
    });
    builder.addCase(fetchArticles.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setSelectedCategory } = articlesSlice.actions;

export default articlesSlice.reducer;