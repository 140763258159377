import React, { useState, useEffect } from 'react';
import { Box, Text, Heading, Modal, ModalOverlay, ModalContent, ModalCloseButton,Button,ModalFooter } from '@chakra-ui/react';

function WelcomeAndCookieModal() {
  const text = "Je suis Flameus, bienvenue dans ma fabrique de bougies parfumées! Ici, chaque bougie est un voyage, une histoire, un souvenir... Prépare-toi à vivre une expérience unique !";
  const showModalCookie = localStorage.getItem('cookies') ? false : true;
  const [isOpen, setIsOpen] = useState(showModalCookie);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenModal');
    if (!hasSeenModal) {
      setIsOpen(true);
      localStorage.setItem('hasSeenModal', 'true');
    }
  }, []);

  const handleAcceptCookies = () => {
    setIsOpen(false);
    localStorage.setItem('cookies', 'accept');
    // Code pour accepter les cookies
  };

  const handleDeclineCookies = () => {
    setIsOpen(false);
    localStorage.setItem('cookies', 'decline');
    // Code pour refuser les cookies
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
      <ModalContent
        bg="white"
        borderRadius="xl"
        width={{ base: "80%", md: "70%", lg: "28%", xl: "30%" }}
        p="4"
        mt={{ base: "20%", md: "20%", lg: "20%", xl: "10%" }}
        ml={{ base: "0%", md: "0%", lg: "0%", xl: "0%" }}
      >
        <Box textAlign="center">
                    <Text fontSize={{ base: "xl", md: "xl", lg: "xl",xl:"2xl" }} mb="2" fontWeight="bold" color="#ff9900" mt={{ base: "12%", md: "6%", lg: "6%", xl: "6%" }}>Salutations, explorateur du monde olfactif!</Text>
          <Text mb="2">{text}</Text>
          <Heading fontSize="xl" color="#ff9900">Pour découvrir mes bougies choisis dès à présent ta destination olfactive en cliquant sur les icônes!</Heading>
        </Box>
        
        <ModalCloseButton
          fontWeight="bold"
        onClick={handleDeclineCookies}
        />
        <ModalFooter>
        <Text fontSize={{ base: "xs", md: "sm", lg: "sm",xl:"sm" }} mt="2">
    Gestion des cookies, allumez ou éteignez ces petites lueurs numériques ! 🍪✨
  </Text>
          <Button onClick={handleAcceptCookies}mr="2%" ml="2%" width="50%" fontSize={{ base: "xs", md: "sm", lg: "sm",xl:"sm" }}>Allumer✨</Button>
          <Button variant="ghost"  onClick={handleDeclineCookies} fontSize={{ base: "xs", md: "sm", lg: "sm",xl:"sm" }}>Éteindre</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default WelcomeAndCookieModal;
