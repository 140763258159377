import React, { useRef } from "react";
import Experience from "../../Components/3D/Experience.js";
// import CookieConsent from "../../Components/Cookie/Cookie_Consent.js";
import { Box, Image, Center } from "@chakra-ui/react";
import "./Home.css";
import ReactCountryFlag from "react-country-flag";
import Loader from "../../Components/Loader.js";
import { Helmet } from "react-helmet";
import WelcomeComponent from "./WelcomeComponent.js";
import MobileWelcomeComponent from "./MobileWelcomeComponent.js";
import TextBubbleModal from "./TextBubble.js";
import ArticleCarousel from "../../Components/carousel/Carousel.js";
import RotatingIcon from "./icone_home.js";
import QuoteBox from "../../Components/text_presentation/Presentation.js";
import ContactForm from "../../Components/Contact_Form/Contact.js";


export default function Home() {
  
 
  const carouselRef = useRef(null);

  return (
    <Box className="divGen">
      <Helmet>
        <title>Bougies parfumées - Imagine Factory</title>
        <meta
          name="description"
          content="Entrez dans un univers magique avec Imagine Factory, où chaque bougie parfumée éco-créative est un pas dans un jardin enchanté. Choisissez votre destination olfactive et préparez-vous pour un voyage olfactif hors du commun. Laissez-vous guider par Flameus à travers une collection où chaque flamme révèle des secrets parfumés. Vivez une aventure olfactive unique!"
        />
        <meta
          name="keywords"
          content="bougies,bougie parfumée,meilleur bougie, bougies artisanales, bougies parfumées, décoration intérieure, bougies naturelles, bougies françaises, Imagine Factory, cire colza, cire coco"
        />
        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Imagine Factory - Un soupçon de savoir-faire et un peu de magie pour créer une bougie parfumée Imagine Factory"
        />
        <meta
          property="og:description"
          content="Éveillez vos sens avec mes bougies artisanales, confectionnées avec passion en France."
        />
        <meta property="og:image" content="URL_de_ton_image_promotionnelle" />
        <meta property="og:url" content="https://www.imaginefactory-online.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Loader />

      <TextBubbleModal />

      <RotatingIcon  />

      <Box
        id="cloud_three"
        width={{
          base: "25%",
          sm: "30%",
          md: "50%",
          xl: "20%",
        }}
        ml={{
          base: "79%",
          sm: "67%",
          md: "-6%",
          xl: "69%",
          "2xl":"85%"
        }}
        mt={{
          base: "90%",
          sm: "55%",
          md: "95%",
          xl: "50%",
          "2xl": "35%",
        }}
      >
        <Image src="./jpg/cloud.png" alt="Cloud" />
      </Box>

      <Box
        id="cloud_three"
        width={{
          base: "20%",
          sm: "50%",
          md: "50%",
          xl: "13%",
          "2xl": "15%",
        }}
        ml={{
          base: "2%",
          sm: "-12%",
          md: "30%",
          xl: "60%",
          "2xl": "5%",
        }}
        mt={{
          base: "60%",
          sm: "130%",
          md: "35%",
          xl: "5%",
          "2xl": "35%",
        }}
      >
        <Image src="./jpg/cloud.png" alt="Cloud" />
      </Box>

      <Box
      display={{ base: "none", md: "block" }}
        id="cloud_three"
        width={{
          sm: "50%",
          md: "50%",
          xl: "13%",
          "2xl": "30%",
        }}
        ml={{
          sm: "-12%",
          md: "30%",
          xl: "60%",
          "2xl": "36%",
        }}
        mt={{
          sm: "130%",
          md: "35%",
          xl: "5%",
          "2xl": "5%",
        }}
      >
      </Box>


      <Box
        className="three"
        height={{
          base: "80vh",
          sm: "100vh",
          md: "100vh",
          xl: "100vh",
          "2xl": "100vh",
        }}
      >
        <Experience />
      </Box>
      <MobileWelcomeComponent
      carouselRef={carouselRef}
      onCategorySelect={(category) => {
        if (carouselRef.current) {
          carouselRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }}/>
      
        <WelcomeComponent
          carouselRef={carouselRef}
          onCategorySelect={(category) => {
            if (carouselRef.current) {
              carouselRef.current.scrollIntoView({ behavior: "smooth" });
            }
          }}
        />

      <Box className="carousel" id="carousel" ref={carouselRef}>
        <ArticleCarousel />
      </Box>

      <Box>
        <Image
          src="./jpg/cloud.png"
          alt="Cloud"
          id="cloud_carousel"
          width={{
            sm: "40%",
            md: "8%",
            xl: "10%",
          }}
        />
      </Box>

      
        <Box id="quotebox">
          <QuoteBox />
        </Box>
     

      <Box zIndex="3" mt="4%">
        <Box id="airballoon">
          <Image
            src="./jpg/airballoon.png"
            alt="Airballoon"
            ml={{
              base: "70%",
              sm: "70%",
              md: "77%",
              lg: "77%",
              xl: "77%",
              "2xl": "77%",
            }}
            width={{
              base: "40%",
              sm: "40%",
              md: "30%",
              lg: "30%",
              xl: "30%",
              "2xl": "30%",
            }}
            mt={{
              base: "35%",
              sm: "35%",
              md: "20%",
              lg: "5%",
              xl: "5%",
              "2xl": "5%",
            }}
          />
        </Box>

        
          <Box>
            <ContactForm id="contactForm" />
          </Box>
        
      </Box>

      <Box>
        <Image
          src="./jpg/cloud.png"
          alt="Cloud"
          mt={{
            base: "-30%",
            sm: "-30%",
            md: "-25%",
            lg: "-20%",
            xl: "-20%",
            "2xl": "-20%",
          }}
          ml={{
            base: "10%",
            sm: "10%",
            md: "10%",
            lg: "15%",
            xl: "15%",
            "2xl": "15%",
          }}
          width={{
            base: "30%",
            sm: "30%",
            md: "30%",
            lg: "25%",
            xl: "20%",
            "2xl": "20%",
          }}
          position="absolute"
        ></Image>
      </Box>

      <Box>
        <Center color="#dce5df" fontSize="xl" font="Roboto" textAlign="center">
          Made in&thinsp;{" "}
          <ReactCountryFlag
            countryCode="FR"
            svg
            style={{ marginRight: "0.5%", marginLeft: "0.5%" }}
          />{" "}
          by Imagine Factory
        </Center>
      </Box>
    </Box>
  );
}
