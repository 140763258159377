import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import { ChakraProvider } from "@chakra-ui/react";





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
  <BrowserRouter>
    <ChakraProvider>
    <App/>
    </ChakraProvider>
  </BrowserRouter>
  </PersistGate>
  </Provider>
 
);


