import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { API_URLS } from "../apiConfig.js"


import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Spinner,
  Center,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

const ClientDetails = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = React.useRef();

  const { userId } = useParams();
  const toast = useToast();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const navigate = useNavigate();

  // Pour vérifier si l'utilisateur est connecté et est administrateur
  useEffect(() => {
    if (!isLoggedIn || !isAdmin) {
      navigate('/');
    } else {
      fetchUserDetails();
    }
  }, [isLoggedIn, isAdmin, navigate]);
  

  // Pour récupérer les informations de l'utilisateur
    const fetchUserDetails = async () => {
      setIsLoading(true);
      try {
        const url = API_URLS.getUserDetailsUrl(userId);
    
        let response = await fetch(url, {
          credentials: "include",
          }
        );
        let userData = await response.json();
        if (response.ok) {
          setUser(userData);

        } else {
          throw new Error("Failed to load user details");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

  

  // Pour mettre à jour les informations de l'utilisateur
  const handleUpdate = async (event) => {
    event.preventDefault();
    setIsUpdating(true);

    const updatedUserData = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      country: user.country,
      address: user.address,
      additiveAddress: user.additiveAddress,
      city: user.city,
      state: user.state,
      zipcode: user.zipcode,
      shippingFirstName: user.shippingFirstName,
      shippingLastName: user.shippingLastName,
      shippingCountry: user.shippingCountry,
      shippingAddress: user.shippingAddress,
      shippingAdditiveAddress: user.shippingAdditiveAddress,
      shippingCity: user.shippingCity,
      shippingState: user.shippingState,
      shippingZipcode: user.shippingZipcode,
      shippingPhone: user.shippingPhone,
      // isAdmin: user.isAdmin,
    };

    try {
      const url = API_URLS.getUpdateUserUrl(userId);
  
      const response = await fetch(url, {
        credentials: "include",
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedUserData),
        }
      );
      if (response.ok) {
        // Handle successful update
        toast({
          title: "Profil mis à jour.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        const updatedUser = await response.json();
        setUser(updatedUser);
      } else {
        throw new Error("Failed to update user");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  // Afficher un modal de confirmation avant la suppression
  const handleDelete = async () => {
    setIsAlertOpen(true);
  };

  // Confirmer la suppression
  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      const url = API_URLS.getDeleteUserUrl(userId);
  
      const response = await fetch(url, {
        method: "DELETE",
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error(`Erreur: ${response.statusText}`);
      }
      const data = await response.json();
      // Gérer la réponse ici, par exemple en affichant un message ou en redirigeant
      navigate("/Admin/ClientsList"); // ou vers la route appropriée après la suppression
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
      setIsAlertOpen(false);
    }
  };

  if (isLoading) {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <VStack spacing={4} mt="8%"  position="relative"
    zIndex="2">
      <Box>{/* User information goes here */}</Box>
      <form onSubmit={handleUpdate}>
        {/* Form inputs for updating user information */}
        <FormControl id="firstName">
          <FormLabel>Prénom</FormLabel>
          <Input
            type="text"
            defaultValue={user?.firstName}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
          />
        </FormControl>

        <FormControl id="lastName">
          <FormLabel>Nom</FormLabel>
          <Input
            type="text"
            defaultValue={user?.lastName}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          />
        </FormControl>

        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            defaultValue={user?.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
        </FormControl>

        <FormControl id="phone">
          <FormLabel>Téléphone</FormLabel>
          <Input
            type="tel"
            defaultValue={user?.phone}
            onChange={(e) => setUser({ ...user, phone: e.target.value })}
          />
        </FormControl>

        <FormControl id="country">
          <FormLabel>Pays</FormLabel>
          <Input
            type="text"
            defaultValue={user?.country}
            onChange={(e) => setUser({ ...user, country: e.target.value })}
          />
        </FormControl>

        <FormControl id="address">
          <FormLabel>Adresse</FormLabel>
          <Input
            type="text"
            defaultValue={user?.address}
            onChange={(e) => setUser({ ...user, address: e.target.value })}
          />
        </FormControl>

        <FormControl id="additiveAddress">
          <FormLabel>Complément d'adresse</FormLabel>
          <Input
            type="text"
            defaultValue={user?.additiveAddress}
            onChange={(e) =>
              setUser({ ...user, additiveAddress: e.target.value })
            }
          />
        </FormControl>

        <FormControl id="city">
          <FormLabel>Ville</FormLabel>
          <Input
            type="text"
            defaultValue={user?.city}
            onChange={(e) => setUser({ ...user, city: e.target.value })}
          />
        </FormControl>

        <FormControl id="state">
          <FormLabel>État / Province</FormLabel>
          <Input
            type="text"
            defaultValue={user?.state}
            onChange={(e) => setUser({ ...user, state: e.target.value })}
          />
        </FormControl>

        <FormControl id="zipcode">
          <FormLabel>Code Postal</FormLabel>
          <Input
            type="text"
            defaultValue={user?.zipcode}
            onChange={(e) => setUser({ ...user, zipcode: e.target.value })}
          />
        </FormControl>
        <Center>
          <Button
            mt={4}
            colorScheme="blue"
            isLoading={isUpdating}
            type="submit"
          >
            Mettre à jour
          </Button>
        </Center>
      </form>
      <Button colorScheme="red" onClick={handleDelete}>
        Supprimer le compte
      </Button>
      {/* Modal de confirmation pour la suppression */}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le Compte
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer ce compte ? Cette action ne
              peut pas être annulée.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default ClientDetails;
