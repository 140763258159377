import {useGLTF } from '@react-three/drei'

function FoxRock() {
const foxRock = useGLTF ('./GLTF/fox_rock.glb')

return (
 
<primitive object={foxRock.scene}   scale={0.8} position-y={-1.5} position-x={-0.3} position-z={0.5} />

  )
  
}
export default FoxRock