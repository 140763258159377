import React, { useEffect } from "react";
import $ from "jquery";
import { useDispatch,useSelector } from "react-redux";
import { setRelayPoint } from "../slices_ReduxToolkit/orderSlice.js";


function MondialRelay() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    // Vérifiez que la bibliothèque jQuery, Leaflet, et Mondial Relay sont bien chargées
    if (window.jQuery && window.L && window.jQuery.fn.MR_ParcelShopPicker) {
      window.jQuery("#Zone_Widget").MR_ParcelShopPicker({
        Target: "#Target_Widget",
        TargetDisplay: "#TargetDisplay_Widget",
        TargetDisplayInfoPR: "#TargetDisplayInfoPR_Widget",
        Brand: "CC20U5J0",
        Country: "FR",
        AllowedCountries: "BE,FR,PT,ES,NL,LU",
        PostCode: "59000",
        ColLivMod: "24R",
        NbResults: "7",
        ShowResultsOnMap: true,
        DisplayMapInfo: true,
        // Activer l'affichage Responsive.
        Responsive: true,
        OnParcelShopSelected: function (data) {
          window.jQuery("#cb_ID").html(data.ID);
          window.jQuery("#cb_Nom").html(data.Nom);
          window
            .jQuery("#cb_Adresse")
            .html(data.Adresse1 + " " + data.Adresse2);
          window.jQuery("#cb_CP").html(data.CP);
          window.jQuery("#cb_Ville").html(data.Ville);
          window.jQuery("#cb_Pays").html(data.Pays);


          
          // Dispatchez une action pour mettre à jour le store Redux
          dispatch(
            setRelayPoint({
              Num: data.ID,
              Nom: data.Nom,
              Adresse: data.Adresse1 + " " + (data.Adresse2 || ""),
              CP:data.CP, 
              Ville:data.Ville,
              Pays: data.Pays, 
                          })
          );
        },
      });
    } else {
      console.error("La bibliothèque Mondial Relay n'est pas chargée.");
    }
  }, [dispatch]);

  

  return (
    <div>
      <div id="Zone_Widget"></div>
      <input type="hidden" id="ParcelShopCode" name="ParcelShopCode" />
    </div>
  );
}

export default MondialRelay;
