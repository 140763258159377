import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    userId: null,
    isAdmin: false, 
    country: null, 
    shippingCountry: null, 
  },
  reducers: {
    setLoggedIn: (state, action) => { 
      state.isLoggedIn = true;
      state.userId = action.payload.userId;
      state.isAdmin = action.payload.isAdmin; 
      state.country = action.payload.country; 
      state.shippingCountry = action.payload.shippingCountry; 
    },
    setLoggedOut: (state) => {
      state.isLoggedIn = false;
      state.userId = null;
      state.isAdmin = false; 
      state.country = null; 
      state.shippingCountry = null; 
    },
  },
});

export const { setLoggedIn, setLoggedOut } = userSlice.actions;
export const selectIsLoggedIn = state => state.user.isLoggedIn;

export default userSlice.reducer;