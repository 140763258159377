import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedOut } from "../slices_ReduxToolkit/userSlice.js";
import { useNavigate } from "react-router-dom";
import { Box, Link, useToast } from "@chakra-ui/react";
import { persistor } from "../store.js";
import { API_URLS } from "./apiConfig.js";


const LogoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const toast = useToast();
  const sessionTimeout = 60 * 60 * 1000; // 1 heure en millisecondes
  const logoutTimer = useRef(null);

  const logout = () => {
    fetch(API_URLS.LOGOUT, {
      method: "POST",
      credentials: "include"
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Échec de la déconnexion');
      }
      return response.json();
    })
    .then(data => {
      // Afficher un toast de succès
      toast({
        title: "Déconnexion réussie",
      status: "info",
      duration: 2000,
      position: "top",
      });
  
    // Supprimer les données du local storage à l'exception du panier
    persistor.purge();

    localStorage.removeItem("stepOneData");
    localStorage.removeItem("newAddress");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    localStorage.removeItem("address");
    localStorage.removeItem("additiveAddress");
    localStorage.removeItem("city");
    localStorage.removeItem("zipcode");
    
    
     // Mise à jour du state Redux pour la déconnexion
     dispatch(setLoggedOut());

     // Redirection
     setTimeout(() => navigate("/"), 2000);
   })
   .catch(error => {
     // Afficher un toast d'erreur
     toast({
       title: "Erreur de déconnexion",
       description: error.message,
       status: "error",
       duration: 5000,
       isClosable: true,
       position: "top"
     });
   });
 };
  useEffect(() => {
    // Réinitialiser le timer de déconnexion à chaque interaction
    const resetTimer = () => {
      clearTimeout(logoutTimer.current);
      logoutTimer.current = setTimeout(logout, sessionTimeout);
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);

    logoutTimer.current = setTimeout(logout, sessionTimeout);

    return () => {
      clearTimeout(logoutTimer.current);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("scroll", resetTimer);
    };
  }, [dispatch, isLoggedIn, navigate, toast]);

  useEffect(() => {
    // Vérifier l'état de la session lorsque le composant charge
    fetch(API_URLS.CHECK_SESSION, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.isLoggedIn) {
          dispatch(setLoggedOut());
        }
      })
      .catch((error) => {
        console.error("Failed to verify session:", error);
      });
  }, [dispatch]);

  return (
    <>
      {isLoggedIn && (
        <Box mb={4}>
          <Link onClick={logout} color="white" fontSize="xl" fontStyle="Roboto">
            Déconnexion
          </Link>
        </Box>
      )}
    </>
  );
};

export default LogoutButton;
