import React from 'react'
import { FaShoppingCart } from 'react-icons/fa';
import { Badge, Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

function IconCart() {
  const cartItems = useSelector((state) => state.cart.items);
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  return (
    <Flex
      zIndex={1}
      display='block'
      position='absolute'
      width={{
        base: '7%',
        sm:'6%',
        md: '3%',
        xl: '3%'
        }}
      marginLeft={{
        base: '85%',
        sm:'88%',
        md:'94%',
        lg: '94%',
        xl:'94%',
      }}
      marginTop={{
        base: '10%',
        sm:'8%',
        md: '2%',
        xl: '2%'
      }}
      color={'#f3f6f4'}>
      <Box>
        <FaShoppingCart size={{ base: '1.2em', md: '1.5em' }} />
      </Box>
      <Box ml={2} >
        <Badge colorScheme="green"><p>{totalItems}</p></Badge>
      </Box>
    </Flex>
  )
}

export default IconCart