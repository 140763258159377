import React, { useEffect } from "react";
import {
  Box,
  Link,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  useToast,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux"; 
import { setLoggedOut } from "../../slices_ReduxToolkit/userSlice.js"; 
import { Helmet } from "react-helmet";
import { API_URLS } from "../../Components/apiConfig.js";


const MonCompte = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast(); 
const dispatch = useDispatch();
 const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

 
 useEffect(() => {
  if (!isLoggedIn) {
    navigate("/Connexion", { state: { from: '/myAccount' } });
  }
}, [isLoggedIn, navigate]);

 const handleDeleteAccount = async () => {
   try {
     const response = await fetch(API_URLS.DELETE_USER, {
       method: "DELETE",
       credentials: 'include',
     });

     if (response.ok) {
       toast({
        title: "Compte supprimé.",
        description: "Votre compte a été supprimé avec succès.",
        status: "success",
        duration: 9000,
        isClosable: true,
       });

       // Déconnectez l'utilisateur après la suppression du compte
       dispatch(setLoggedOut());
       navigate("/");
     } else {
       toast({
        title: "Une erreur est survenue.",
        description: "Impossible de supprimer votre compte pour le moment.",
        status: "error",
        duration: 9000,
        isClosable: true,
       });
     }
   } catch (error) {
     toast({
      title: "Une erreur est survenue.",
      description: `Erreur : ${error.message}`, 
      status: "error",
      duration: 9000,
      isClosable: true,
     });
   }
   onClose();
 };

 
  return (
    <Flex flexDirection="column"  justifyContent="center" alignItems="center"  position="relative"
    zIndex="2">
      <Box
        rounded="lg"
        bg="white"
        boxShadow="lg"
        p="4"
        mt={{ base: "25%", md: "15%", lg: "15%", xl:"10%" }}
        w="full"
        maxW={{ base: "90%", md: "40%", lg: "50%", xl:"20%" }}
        mb="5%"
      >
     
      <Helmet>
        <title>Mon Compte - Imagine Factory</title>
        <meta name="description" content="Gérez vos informations personnelles, consultez vos commandes et plus encore sur votre compte NomDeVotreSite." />
      </Helmet>

      <Heading as="h1" size="lg" mb="5%" color="#293d36" textAlign="center">
        Mon compte
      </Heading>
      
      <Box display="flex" flexDirection="column" fontSize="2xl" textAlign="center">
        <Link href="/ClientInformations" color="#293d36" mb="2">
          Mes informations
        </Link>
        <Link href="/ClientOrders" color="#293d36" mt="4">
          Mes commandes 
        </Link>
        <Box textAlign="center">
          <Button onClick={onOpen} width="10%" mt="3%" background="none" color="#293d36">
            Supprimer mon compte
          </Button>
        </Box>
      </Box>
      {/* Boîte de dialogue pour confirmer la suppression du compte */}
      <AlertDialog isOpen={isOpen} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Supprimer mon compte</AlertDialogHeader>
          <AlertDialogBody>
            Etes vous sûr de vouloir supprimer votre compte? Ceci sera irréversible.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>Annuler</Button>
            <Button colorScheme="red" onClick={handleDeleteAccount}>
              Supprimer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
    </Flex>
  );
};

export default MonCompte;
