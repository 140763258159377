import React, { useState, useEffect } from 'react';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API_URLS } from "../apiConfig.js"

import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Center,
  Tr,
  Th,
  Td,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const ReviewsManager = () => {
  const [reviews, setReviews] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);

  useEffect(() => {
    if (!isLoggedIn || !isAdmin) {
      navigate('/');
    } else {
      loadReviews();
    }
  }, [isLoggedIn, isAdmin, navigate]);


  const loadReviews = async () => {
    try {
      const response = await fetch(API_URLS.GET_REVIEWS, {
        credentials: 'include',
      });
      const data = await response.json();
      setReviews(data);
    } catch (error) {
      console.error('Erreur lors du chargement des avis', error);
    }
  };

 // Marquer un avis comme vérifié ou non vérifié
 const toggleVerification = async (review) => {
  try {
    const url = API_URLS.TOGGLE_REVIEW_VERIFICATION(review._id);

    await fetch(url, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ isVerified: !review.isVerified }),
      credentials: 'include',
    });
    loadReviews(); // Recharger les avis
    toast({ title: `Avis ${review.isVerified ? 'non vérifié' : 'vérifié'}`, status: 'success' });
  } catch (error) {
    console.error('Erreur lors de la modification de la vérification', error);
  }
};

  // Supprimer un avis
  const deleteReview = async (reviewId) => {
    try {
      const url = API_URLS.DELETE_REVIEW(reviewId);
  
      await fetch(url, { method: 'DELETE', credentials: 'include' });
      loadReviews(); // Recharge les avis
      toast({ title: 'Avis supprimé', status: 'info' });
    } catch (error) {
      console.error('Erreur lors de la suppression', error);
    }
  };

 
  // Rendu du composant
  return (
    <Box mt="10%" position="relative"
    zIndex="2">
      <Center>
        <Heading>Gestion des Avis</Heading>
        </Center>
      <Table>
        <Thead>
          <Tr>
            <Th>Titre du Produit</Th>
            <Th>Commentaire</Th>
            <Th>Client</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
      {reviews.map(review => (
        <Tr key={review._id}>
          <Td>{review.productTitle}</Td>
          <Td>{review.comment}</Td>
          <Td>{review.firstName} {review.lastName}</Td>
          <Td>
            <IconButton
              icon={review.isVerified ? <LockIcon /> : <UnlockIcon />}
              onClick={() => toggleVerification(review)}
              aria-label={review.isVerified ? "Annuler la vérification" : "Vérifier l'avis"}
            />
            <IconButton
              ml="4%"
              icon={<DeleteIcon />}
              onClick={() => deleteReview(review._id)}
              aria-label="Supprimer l'avis"
            />
          </Td>
        </Tr>
      ))}
    </Tbody>
    </Table>
    </Box>
  );
};

export default ReviewsManager;