import React, { useState, useEffect } from 'react';
import {
  Box, Select, Button, FormControl, FormLabel, Input, Stack, Table, Thead, Tbody, Tr, Th, Td, useToast, Checkbox
} from "@chakra-ui/react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API_URLS } from "../apiConfig.js"




const PromoCodeForm = ({ selectedCode, onSubmit }) => {
 

  const [formData, setFormData] = useState({
    code: '',
    discountType: 'freeShipping',
    discountValue: '',
    validFrom: new Date().toISOString().slice(0, 10),
    validUntil: '',
    maxUses: 1,
    isActive: true,
  });

  // Mettre à jour le formulaire lorsque selectedCode change
  useEffect(() => {
    if (selectedCode) {
      setFormData({
        code: selectedCode.code,
        discountType: selectedCode.discountType,
        discountValue: selectedCode.discountValue.toString(),
        validFrom: selectedCode.validFrom.slice(0, 10),
        validUntil: selectedCode.validUntil.slice(0, 10),
        maxUses: selectedCode.maxUses,
        isActive: selectedCode.isActive,
      });
    }
  }, [selectedCode]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Box as="form" onSubmit={(e) => e.preventDefault()}>
    <FormControl id="code">
      <FormLabel>Code</FormLabel>
      <Input name="code" type="text" value={formData.code} onChange={handleChange} />
    </FormControl>
    <FormControl id="discountType">
    <FormLabel>Type of Discount</FormLabel>
    <Select name="discountType" value={formData.discountType} onChange={handleChange}>
    <option value="freeShipping">Free Shipping</option>
      <option value="percentage">Percentage</option>
      <option value="fixed">Fixed Amount</option>
    </Select>
  </FormControl>

  {formData.discountType === 'percentage' && (
<FormControl id="discountValue">
<FormLabel>Discount Percentage</FormLabel>
<Input
   name="discountValue" 
   type="number"
   value={formData.discountValue}
   onChange={handleChange}
   placeholder="Enter Discount Percentage"
/>
</FormControl>
)}

{formData.discountType === 'fixed' && (
<FormControl id="discountValue">
<FormLabel>Fixed Discount Amount</FormLabel>
<Input
  name="discountValue"
  type="number"
  value={formData.discountValue}
  onChange={handleChange}
  placeholder="Enter Fixed Discount Amount"
/>
</FormControl>
)}
    <FormControl id="validFrom">
      <FormLabel>Valid From</FormLabel>
      <Input name="validFrom" type="date" value={formData.validFrom} onChange={handleChange} />
    </FormControl>
    <FormControl id="validUntil">
      <FormLabel>Valid Until</FormLabel>
      <Input name="validUntil" type="date" value={formData.validUntil} onChange={handleChange} />
    </FormControl>
    <FormControl id="maxUses">
      <FormLabel>Max Uses</FormLabel>
      <Input name="maxUses" type="number" value={formData.maxUses} onChange={handleChange} />
    </FormControl>
    <FormControl id="isActive" display="flex" alignItems="center">
      <FormLabel mb="0">Is Active</FormLabel>
      <Checkbox name="isActive" type="checkbox" isChecked={formData.isActive} onChange={handleChange} />
    </FormControl>
    <Button mt={4} colorScheme="blue" onClick={handleSubmit}>
    {selectedCode ? 'Update Code' : 'Create Code'}
  </Button>
  </Box>
);
};

  const AdminPromotionCodes = () => {
  const [promoCodes, setPromoCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!isLoggedIn || !isAdmin) {
      navigate('/');
    } else {
      loadPromoCodes();
    }
  }, [isLoggedIn, isAdmin, navigate]);

  const loadPromoCodes = async () => {
    try {
      const response = await fetch(API_URLS.GET_ALL_PROMO_CODES, {
        credentials: 'include',
      });
      const data = await response.json();
      setPromoCodes(data);
    } catch (error) {
      toast({ title: "Error", description: "Failed to load promo codes", status: "error" });
    }
  };

  const handleCreateOrUpdate = async (codeData) => {
    try {
      const url = selectedCode ? API_URLS.UPDATE_PROMO_CODE(selectedCode._id) : API_URLS.CREATE_PROMO_CODE;
  
      const response = await fetch(url, {
        method: selectedCode ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(codeData),
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit promo code');
      }
  
      loadPromoCodes();
      toast({ title: "Success", description: "Promo code processed successfully", status: "success" });
      setSelectedCode(null);
    } catch (error) {
      toast({ title: "Error", description: error.message, status: "error" });
    }
  };

  const handleDelete = async (codeId) => {
    try {
      const url = API_URLS.DELETE_PROMO_CODE(codeId);
  
      const response = await fetch(url, { method: 'DELETE', credentials: 'include' });

      if (!response.ok) {
        throw new Error('Failed to delete promo code');
      }

      loadPromoCodes();
      toast({ title: "Success", description: "Promo code deleted successfully", status: "success" });
    } catch (error) {
      toast({ title: "Error", description: error.message, status: "error" });
    }
  };


  const handleSelectCode = (code) => {
    setSelectedCode(code);
  };

  return (
    <Stack spacing={8} mt="7%" ml="3%" mr="3%" position="relative"
    zIndex="2">
      <PromoCodeForm selectedCode={selectedCode} onSubmit={handleCreateOrUpdate} />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Code</Th>
            <Th>Discount</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {promoCodes.map(code => (
            <Tr key={code._id}>
              <Td>{code.code}</Td>
              <Td>
                {code.discountType === 'percentage' ? `${code.discountValue}%`
                  : code.discountType === 'fixed' ? `${code.discountValue} (Fixed Amount)`
                  : 'Free Shipping'}
              </Td>
              <Td>
                <Button colorScheme="blue" onClick={() => handleSelectCode(code)} mr="2%">Edit</Button>
                <Button colorScheme="red" onClick={() => handleDelete(code._id)}>Delete</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  );
};

export default AdminPromotionCodes;