import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    selectedCarrier: null,
    relayPoint: null,
    shippingRates: [], // Ajoutez l'état des taux d'expédition ici
    shippingCost: 0,    
    // autres états nécessaires
  },
  reducers: {
    setSelectedCarrier: (state, action) => {
      state.selectedCarrier = action.payload; 
    },
    setRelayPoint: (state, action) => {
      state.relayPoint = action.payload;
    },
    setShippingRates: (state, action) => { // Ajoutez un réducteur pour définir les taux d'expédition
      state.shippingRates = action.payload;
    },
    setShippingCost: (state, action) => { // Ajouter un réducteur pour les frais de livraison
      state.shippingCost = action.payload;
    },
    // autres réducteurs nécessaires
  },
});

export const { setSelectedCarrier, setRelayPoint, setShippingRates, setShippingCost } = orderSlice.actions;

export default orderSlice.reducer;
