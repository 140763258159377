import React from 'react';
import { Box, Avatar,Flex,Text } from '@chakra-ui/react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

export default function QuoteBox ({ quote, author, avatarSrc }) {
  

  return (
       <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bgColor="white"
      opacity="0.75"
      fontFamily="Roboto"
      ml= {{ base: "3%", md: "3%", lg: "0%", xl: "8%","2xl":"8%" }}
      mr={{ base: "3%", md: "3%", lg: "0%", xl: "8%","2xl":"8%" }}
      mb={{ base: "0%", md: "5%", lg: "0%", xl: "10%","2xl":"0%" }}
      pr="5%"
      
    >
      <Flex alignItems="start">
        <Avatar
          zIndex='2'
          mt='1%'
          ml='1%'
          size={{
            base:'md',
            sm:'md',
            md:'md',
            lg:'lg',
            xl:'xl',
            '2xl':'2xl'}} 
          src={avatarSrc || "./jpg/picture.jpg"} 
        />
        <Box flex="1" ml="4" mt="10">
          <Text
            fontWeight="bold"
            fontSize={{
              base:'90%',
              sm:'90%',
              md:'90%',
              xl:'120%',
              '2xl':'120%'}}
            zIndex='1'
          >
            <FaQuoteLeft />
            Passionnée par les bougies, j'ai souhaité développer une bougie artisanale qui diffuse son parfum dans toute la pièce grâce à des parfums sans cmr et sans phtalates, dans un seul et unique format XXL pour allier plaisir olfactif et durabilité.
            <br />Des ingrédients écoresponsables : de la cire de Coco (provenant du fruit) et de Colza (culture mellifère, pour nos petites abeilles) sans paraffine (dérivé de pétrole) et sans soja (déforestation et surproduction).
            Une mèche en bois ajourée spéciquement conçue pour une combustion parfaite et propre.
            La date de fabrication est notée à la main sur chaque bougie.
            A chaque saison découvrez de nouveaux parfums rigoureusement sélectionnés, des collections confidentielles qui me permettent de préserver la qualité de mes bougies et leur processus de fabrication.
            <FaQuoteRight />
          </Text>
          <Box fontSize="md" mt="3" m='2%'>
            Audrey - Artisan créateur Imagine Factory Candles
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}





