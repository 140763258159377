import React, { useState } from 'react';
import CountryList from './CountryList.js'
import { Box, Button, FormControl, FormLabel, Input, Stack, Flex, Heading } from '@chakra-ui/react';



const SubscribeNewsletter = () => {
  const [formData, setFormData] = useState({
    country: '',
    email: ' ',
    password: '',
    subscribeNewsletter: false
  });

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === 'subscribeNewsletter' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // handle form submission here
  };

  console.log
    (formData.subscribeNewsletter,
      formData.email, formData.password)

  return (
    <Flex
      flexDirection="column"
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >

      <Stack
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        fontFamily="Roboto"
      >
       
       <Heading color="#293D36" fontSize="2xl" mb="3%" width="70%" textAlign="center">Want exclusive deals? Join now!</Heading>
        <Box maxWidth="500px" mx="auto">
          <form onSubmit={handleSubmit} >
            <Stack
              spacing="4"
              p="1rem"
              backgroundColor="#F3F6F4"
              boxShadow="md"
              borderRadius="xl"
            >
             <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  value={formData.email} onChange={handleChange}
                  borderColor="#293D36"
                  placeholder="your-email@example.com"
                  _placeholder={{ color: 'gray.500' }}
                  type="email"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Country:</FormLabel>
                <CountryList value={formData.country} onChange={handleChange} />
              </FormControl>           
               <Button fontColor="#293d36" background="#98b29f" type="submit" isDisabled={!formData.acceptTerms} onClick={handleSubmit}>
                Submit
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex >
  );
};

export default SubscribeNewsletter;

