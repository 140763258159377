import React from 'react';
import { Box, Text, VStack, Flex, Button, useBreakpointValue, Icon } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { FaSadTear } from 'react-icons/fa';

function OrderFailure() {
  const navigate = useNavigate();
  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      fontFamily="Roboto"
      p={4}
      mt={{ base: "0%", md: "0%", xl:"0%" }}
    >
      <Icon as={FaSadTear} w={10} h={10} color="#293d36" />
      <Text fontSize="3xl" color="#293d36" mt={3} textAlign="center">
        Échec du Traitement de la Commande
      </Text>
      <Text fontSize="2xl" mt={4} mb={2} color="#293d36" textAlign="center">
        Nous sommes désolés, mais nous n'avons pas pu traiter votre commande.
      </Text>
      <Text color="#293d36" textAlign="center" mb={5}>
        Cela pourrait être dû à un problème avec la méthode de paiement ou un souci de notre côté. Veuillez réessayer plus tard ou contacter le support client pour obtenir de l'aide.
      </Text>
      <Button
        mt={3}
        backgroundColor="#98b29f"
        color="white"
        size={buttonSize}
        _hover={{ bg: "#769f7b" }}
        onClick={handleGoHome}
      >
        Retourner à l'Accueil
      </Button>
    </Flex>
  );
}

export default OrderFailure;
