import {useGLTF } from '@react-three/drei'

function FoxRock() {
const foxRock = useGLTF ('./GLTF/fox_rock.glb')

return (
 
<primitive object={foxRock.scene} scale={0.9} position-y={-2.6}  />

  )
  
}
export default FoxRock