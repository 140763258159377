import React, { useState, useEffect } from "react";
import { Flex, Box, Center, List, ListItem, Button, useToast, Heading, Text, Image, Select,Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { API_URLS } from "../apiConfig.js"



const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if ((!isLoggedIn || !isAdmin)) {
      // Rediriger les utilisateurs non administrateurs
      navigate("/"); // Redirige vers la page d'accueil ou une autre page appropriée
      
    } else {
      fetchOrders()
    }
  }, [isAdmin, navigate]);


  const fetchOrders = async () => {
    try {
      const response = await fetch(API_URLS.GET_ADMIN_ORDERS, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des commandes.');
      }

      const data = await response.json();
      setOrders(data);
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des commandes:", error);
      toast({
        title: "Erreur",
        description: "Impossible de récupérer les commandes.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOrderClick = async (orderId) => {
    try {
      const response = await fetch(API_URLS.GET_ORDER_DETAILS(orderId), {
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Problème lors de la récupération des détails de la commande');
      }
        
      const orderData = await response.json();
  
      // Vérifiez si la commande contient des articles
      if (orderData.items && orderData.items.length > 0) {
        // Récupérer les détails de chaque article de la commande
        const itemsWithDetails = await Promise.all(orderData.items.map(async (item) => {
          const itemResponse = await fetch(API_URLS.GET_PRODUCT_DETAIL(item.itemId), {
            credentials: 'include', });
          
          if (!itemResponse.ok) {
            throw new Error('Problème lors de la récupération des détails du produit');
          }
  
          const itemDetails = await itemResponse.json();
          // Fusionner les détails du produit avec les données de l'article de la commande
          return { ...item, ...itemDetails };
        }));
  
        // Mettre à jour les détails de la commande avec les données complètes des articles
        setSelectedOrder({ ...orderData, items: itemsWithDetails });
        setIsModalOpen(true); 
      } else {
        // Si pas d'articles, on sélectionne juste la commande sans articles détaillés
        setSelectedOrder(orderData);
        setIsModalOpen(true); 
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des détails de la commande:", error);
      toast({
        title: "Erreur",
        description: "Impossible de récupérer les détails de la commande.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const renderOrderDetails = (order) => {
    const STATIC_URL = process.env.REACT_APP_STATIC_URL || 'http://localhost:5000/uploads/';
    return (
      <Center>
      <Box p="4" borderWidth="1px" borderRadius="md" bg="gray.50" mt="4" width="50%">
      <Heading size="md" mb="2">Order details</Heading>
      <Text><strong>Date:</strong> {new Date(order.orderDate).toLocaleDateString()}</Text>
      <Text><strong>Status:</strong> {order.status}</Text>
      <Text><strong>Carrier:</strong> {order.carrier}</Text>
      <Text><strong>Total Amount:</strong> €{order.totalAmount ? order.totalAmount.toFixed(2) : '0.00'}</Text>
      <Text><strong>Discount:</strong> €{order.discount ? order.discount.toFixed(2) : '0.00'}</Text>
      <Text><strong>Shipping Cost:</strong> €{order.shippingCost ? order.shippingCost.toFixed(2) : '0.00'}</Text>

      {order.shippingAddress && (
        <Box mt="2">
          <Heading size="sm">Shipping Address:</Heading>
            <Text>{order.shippingAddress.firstName} {order.shippingAddress.lastName}</Text>
            <Text>{order.shippingAddress.address}, {order.shippingAddress.city}, {order.shippingAddress.zipcode}, {order.shippingAddress.country}</Text>
          </Box>
        )}
        {order.mondialRelayPoint && (
          <Box mt="2">
            <Heading size="sm">Mondial Relay Point:</Heading>
            <Text>{order.mondialRelayPoint.Nom}, {order.mondialRelayPoint.Adresse}</Text>
          </Box>
        )}
         {order.items && order.items.length > 0 && (
          <Box mt="4">
            <Heading size="sm" mb="2">Items Ordered:</Heading>
            {order.items.map((item) => (
              <Flex
                key={item.itemId}
                direction="row"
                align="center"
                p={{ base: "2", md: "4" }}
              >
                {/* Image de l'article */}
                <Image
                  rounded="lg"
                  width={{ base: "40px", md: "80px" }}  // Taille réduite
                  height={{ base: "40px", md: "80px" }}
                  fit="cover"
                  src={`${STATIC_URL}${item.mainImage}`}
                  alt={item.name}
                  draggable="false"
                  loading="lazy"
                />

                {/* Nom et quantité de l'article */}
                <Box ml="3">
                  <Text fontWeight="medium" fontSize="md">{item.name}</Text>
                  <Text fontSize="sm">Quantity: {item.quantityInCart}</Text>
                </Box>
              </Flex>
            ))}
          </Box>
        )}
      </Box>
      </Center>
    );
  };

  

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const url = API_URLS.UPDATE_ORDER_STATUS(orderId);
  
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ status: newStatus }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de la commande.');
      }

      // Mise à jour de l'état local des commandes
      setOrders(orders.map(order => order._id === orderId ? { ...order, status: newStatus } : order));
      toast({
        title: "Commande mise à jour",
        description: "Le statut de la commande a été mis à jour.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la commande:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour la commande.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending": return "red";
      case "shipped": return "blue";
      case "delivered": return "green";
      case "canceled": return "orange"; // Couleur pour les commandes annulées
      default: return "gray";
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const url = API_URLS.DELETE_ORDER(orderId);
  
      const response = await fetch(url, {
        method: "DELETE",
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors de la suppression de la commande.');
      }
  
      // Mise à jour de l'état local pour enlever la commande supprimée
      setOrders(orders.filter(order => order._id !== orderId));
      toast({
        title: "Commande supprimée",
        description: "La commande a été supprimée avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la suppression de la commande:", error);
      toast({
        title: "Erreur",
        description: "Impossible de supprimer la commande.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  

  return (
    <Box p="4" mt="8%" position="relative"
    zIndex="2">
      {/* ... */}
      <List spacing={4}>
      {orders.map((order) => (
          <ListItem key={order._id} >
            <Text onClick={() => handleOrderClick(order._id)} _hover={{ bg: "gray.100" }} p="4" borderWidth="1px" borderRadius="md" cursor="pointer">Order of {new Date(order.orderDate).toLocaleDateString()} - {order.shippingAddress.firstName} {order.shippingAddress.lastName}</Text>
            <Text color={getStatusColor(order.status)}>Status: {order.status}</Text>
            <Select value={order.status} onChange={(e) => handleStatusChange(order._id, e.target.value)}>
              <option value="pending">Pending</option>
              <option value="shipped">Shipped</option>
              <option value="delivered">Delivered</option>
              <option value="canceled">Canceled</option>
            </Select>
            <Button colorScheme="red" onClick={() => handleDeleteOrder(order._id)} mt="1%">Supprimer</Button>
          </ListItem>
          
        ))}
      </List>
       {/* Modal pour afficher les détails de la commande */}
       <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedOrder && renderOrderDetails(selectedOrder)}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Close
            </Button>
            {/* Autres boutons/actions si nécessaire */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};


export default AdminOrders;
