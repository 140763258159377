import React from "react";
import { Box, chakra, Flex } from "@chakra-ui/react";
import { FaUserAlt, FaShippingFast, FaCreditCard } from "react-icons/fa";

const CFaUserAlt = chakra(FaUserAlt);
const CFaShippingFast = chakra(FaShippingFast);
const CFaCreditCard = chakra(FaCreditCard);

export default function StepProgress({ currentStep }) {
  const stepIcons = [
    { icon: <CFaUserAlt boxSize={{ base: "24px", md: "36px" }}/>, label: "Step 1" },
    { icon: <CFaShippingFast boxSize={{ base: "24px", md: "36px" }}/>, label: "Step 2" },
    { icon: <CFaCreditCard boxSize={{ base: "24px", md: "36px" }} />, label: "Step 3" },
  ];

  return (
    <Box mb={{ base: "6%", lg: "0%" }} width={{ base: "80%",md:"50%", lg: "25%", xl:"25%" }}>
    <chakra.div
      bg="gray.300"
      height="8px"
      borderRadius="md"
      position="relative"
    >
      <chakra.div
        bg="#293d36"
        height="100%"
        borderRadius="md"
        width={`${((currentStep - 1) / 2) * 100}%`}
      />
      <Flex justifyContent="space-between" mt={1} px={4}>
        {stepIcons.map((step, index) => (
          <Box
            key={index}
            as="span"
            color={currentStep === index + 1 ? "#293d36" : "gray.500"}
          >
            {step.icon}
          </Box>
        ))}
      </Flex>
    </chakra.div>
  </Box>
  
  );
}
