import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { API_URLS } from "../apiConfig.js";

import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Select,
  Checkbox,
  Image,
  VStack,
  HStack,
  useToast,
  Heading,
  Tooltip,
  Icon,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { InfoIcon, DeleteIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const ProductForm = ({
  product,
  handleSave,
  handleInputChange,
  deleteImage,
  deleteVideo,
  handleFileChange,
}) => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const STATIC_URL =
    process.env.REACT_APP_STATIC_URL || "http://localhost:5000/uploads/";

  const availableBenefits = [
    "focus",
    "new",
    "emoti",
    "cocooning",
    "relax",
    "boost",
    "romantic",
    "meditation",
    "invigorating",
    "festive",
  ];

  const handleBenefitsChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    handleInputChange({ target: { name: "benefits", value: selectedOptions } });
  };

  useEffect(() => {
    // Vérifier si l'utilisateur est connecté et est un administrateur
    if (!isLoggedIn || !isAdmin) {
      navigate("/");
    }
  }, [isAdmin, isLoggedIn, navigate]);

  const categoryValue =
    typeof product.category === "string" ? product.category : "";
  const [newSize, setNewSize] = useState({
    contenance: "",
    price: "",
    quantity: "",
  });
  const toast = useToast();

  const [sizesState, setSizesState] = useState(
    product.sizes.map((size) => ({
      ...size,
      isUpdating: false,
    }))
  );

  const handleSizeInputChange = (index, name, value) => {
    const newSizesState = [...sizesState];
    newSizesState[index][name] = value;
    setSizesState(newSizesState);
  };

  const updateSize = async (index) => {
    const sizeToUpdate = sizesState[index];
    const itemId = product._id;

    try {
      const response = await fetch(API_URLS.UPDATE_SIZE(itemId), {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sizeIndex: index,
          updatedSize: sizeToUpdate,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Succès",
          description: "Taille mise à jour avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Vous pouvez ici mettre à jour l'état du produit si nécessaire
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      toast({
        title: "Erreur",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNewSizeChange = (e) => {
    const { name, value } = e.target;
    setNewSize({ ...newSize, [name]: value });
  };
  const removeSize = async (index) => {
    // Validation de l'index
    if (index < 0 || index >= product.sizes.length) {
      toast({
        title: "Erreur",
        description: "Index de taille invalide.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const itemId = product._id;

    try {
      const response = await fetch(API_URLS.REMOVE_SIZE(itemId), {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sizeIndex: index,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Succès",
          description: "Taille supprimée avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Mettre à jour l'état local du produit en supprimant la taille
        const updatedSizes = [...product.sizes];
        updatedSizes.splice(index, 1);

        handleInputChange({ target: { name: "sizes", value: updatedSizes } });
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      toast({
        title: "Erreur",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const addNewSize = async () => {
    const { contenance, price, quantity } = newSize;

    // Validation des données avant l'envoi
    if (!contenance || !price || !quantity) {
      toast({
        title: "Erreur",
        description: "Veuillez remplir tous les champs.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    // Récupérer l'ID du produit
    const itemId = product._id;

    try {
      const response = await fetch(API_URLS.ADD_SIZE(itemId), {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contenance: contenance,
          price: price,
          quantity: quantity,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Succès",
          description: "Nouvelle taille ajoutée avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        setSizesState((prevSizes) => [...prevSizes, newSize]);
        // Réinitialiser le formulaire de nouvelle taille
        setNewSize({ contenance: "", price: "", quantity: "" });
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      toast({
        title: "Erreur",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleSave();
      toast({
        title: "Succès",
        description: "Le produit a été enregistré avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/Admin/ProductsList");
    } catch (error) {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de l'enregistrement.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCategoryChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    handleInputChange({ target: { name: "category", value: selectedOptions } });
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit}
      p={6}
      boxShadow="xl"
      bg="white"
      borderRadius="md"
      position="relative"
      zIndex="2"
    >
      <Heading mb={6}>Détails du produit</Heading>

      <VStack spacing={5} align="start">
        {/* Titre & Sous-titre */}
        <FormControl>
          <FormLabel>Titre:</FormLabel>
          <Input
            placeholder="Entrez le titre du produit"
            type="text"
            name="title"
            value={product.title}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Sous-titre:</FormLabel>
          <Input
            placeholder="Entrez le sous-titre du produit"
            type="text"
            name="underTitle"
            value={product.underTitle}
            onChange={handleInputChange}
          />
        </FormControl>
        <Text fontWeight="bold">Gestion des tailles, prix et quantité :</Text>
        <VStack spacing={4}>
          {sizesState.map((size, index) => (
            <HStack key={index} spacing={4} align="end">
              <FormControl>
                <FormLabel>Contenance :</FormLabel>
                <Input
                  type="text"
                  value={size.contenance}
                  onChange={(e) =>
                    handleSizeInputChange(index, "contenance", e.target.value)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Prix :</FormLabel>
                <Input
                  type="number"
                  value={size.price}
                  onChange={(e) =>
                    handleSizeInputChange(index, "price", e.target.value)
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Quantité :</FormLabel>
                <Input
                  type="number"
                  value={size.quantity}
                  onChange={(e) =>
                    handleSizeInputChange(index, "quantity", e.target.value)
                  }
                />
              </FormControl>
              <Button
                colorScheme="green"
                onClick={() => updateSize(index)}
                isLoading={size.isUpdating}
                w="full"
              >
                Mettre à jour
              </Button>
              <IconButton
                icon={<DeleteIcon />}
                onClick={() => removeSize(index)}
                aria-label="Supprimer"
                size="md"
              />
            </HStack>
          ))}

          <Text mt={4} fontWeight="bold">
            Ajouter une nouvelle taille, prix et quantité :
          </Text>
          <HStack spacing={4}>
            <Input
              type="text"
              placeholder="Contenance"
              name="contenance"
              value={newSize.contenance}
              onChange={handleNewSizeChange}
            />
            <Input
              type="number"
              placeholder="Prix"
              name="price"
              value={newSize.price}
              onChange={handleNewSizeChange}
            />
            <Input
              type="number"
              placeholder="Quantity"
              name="quantity"
              value={newSize.quantity}
              onChange={handleNewSizeChange}
            />
            <Button onClick={addNewSize}>Ajouter</Button>
          </HStack>
        </VStack>

        {/*Remise */}
        <HStack spacing={4} width="100%">
          <FormControl flex={1}>
            <Tooltip
              label="Entrez la remise en pourcentage"
              aria-label="Une astuce"
            >
              <FormLabel>
                Remise: <Icon as={InfoIcon} ml={1} />
              </FormLabel>
            </Tooltip>
            <Input
              placeholder="0%"
              type="number"
              name="discount"
              value={product.discount}
              onChange={handleInputChange}
            />
          </FormControl>
        </HStack>
        {/* Catégorie */}
        <FormControl>
          <FormLabel>Catégorie:</FormLabel>
          <Select
            name="category"
            multiple
            value={product.category}
            onChange={handleCategoryChange}
            size="lg"
            height="auto"
            maxHeight="200px"
          >
            <option value="Winter">Winter</option>
            <option value="Summer">Summer</option>
            <option value="Fresh">Fresh</option>
            <option value="Christmas">Christmas</option>
            <option value="Woody">Boisé</option>
            <option value="Spicy">Epicé</option>
            <option value="Green">Vert</option>
            <option value="Fruity">Fruity</option>
            <option value="Floral">Floral</option>
            <option value="Gourmand">Gourmand</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Bénéfices:</FormLabel>
          <Select
            name="benefits"
            multiple
            value={product.benefits || []}
            onChange={handleBenefitsChange}
            size="lg"
            height="auto"
            maxHeight="200px" // Ajustez selon vos besoins
          >
            {availableBenefits.map((benefit) => (
              <option key={benefit} value={benefit}>
                {benefit}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* Description & Information */}
        <FormControl>
          <FormLabel>Description:</FormLabel>
          <Textarea
            placeholder="Entrez une description détaillée du produit"
            name="description"
            value={product.description}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Information:</FormLabel>
          <Textarea
            placeholder="Entrez des informations supplémentaires"
            name="information"
            value={product.information}
            onChange={handleInputChange}
          />
        </FormControl>

        {/* Images */}
        <Heading size="md" mb={4}>
          Images
        </Heading>
        <VStack spacing={4} align="start">
          {product.images.map((image, index) => (
            <HStack key={index} spacing={4}>
              <Image
                boxSize="100px"
                src={`${STATIC_URL}${image}`}
                alt={`Image ${index + 1}`}
              />
              <Button onClick={() => deleteImage(image)}>Supprimer</Button>
            </HStack>
          ))}
          <FormControl>
            <FormLabel>Télécharger de nouvelles images produit:</FormLabel>
            <Input
              type="file"
              name="newImages"
              multiple
              onChange={handleFileChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Télécharger une nouvelle image principale:</FormLabel>
            <Input
              type="file"
              name="newMainImage"
              onChange={handleFileChange}
            />
          </FormControl>
        </VStack>

        {/* Vidéos */}
        <Heading size="md" mb={4} mt={6}>
          Vidéos
        </Heading>
        <VStack spacing={4} align="start">
          {product.videos.map((video, index) => (
            <HStack key={index} spacing={4}>
              <video width="100" height="100" src={`${STATIC_URL}${video}`} />
              <Button onClick={() => deleteVideo(video)}>Supprimer</Button>
            </HStack>
          ))}
          <FormControl>
            <FormLabel>Télécharger une nouvelle vidéo du produit:</FormLabel>
            <Input
              type="file"
              name="newVideos"
              multiple
              onChange={handleFileChange}
            />
          </FormControl>
        </VStack>

        {/* Visibilité */}
        <FormControl display="flex" alignItems="center" mt={4}>
          <FormLabel mb="0">Visible:</FormLabel>
          <Checkbox
            name="isVisible"
            isChecked={product.isVisible}
            onChange={handleInputChange}
          />
        </FormControl>
      </VStack>
      <Center>
        <Button mt={4} type="submit" colorScheme="blue">
          Enregistrer
        </Button>
      </Center>
    </Box>
  );
};

export default ProductForm;
