import React from "react";
import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  FormErrorMessage,
  Center,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";
import Select from "react-select";
import countryList from "react-select-country-list";
import StepProgress from "./StepProgress.js";
import { API_URLS } from "../../Components/apiConfig.js";


export default function StepOne({ nextStep }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [additiveAddress, setAdditiveAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [firstNameShipping, setFirstNameShipping] = useState("");
  const [lastNameShipping, setLastNameShipping] = useState("");
  const [addressShipping, setAddressShipping] = useState("");
  const [additiveAddressShipping, setAdditiveAddressShipping] = useState("");
  const [cityShipping, setCityShipping] = useState("");
  const [stateShipping, setStateShipping] = useState("");
  const [zipcodeShipping, setZipcodeShipping] = useState("");
  const [countryShipping, setCountryShipping] = useState("");
  const [phone, setPhone] = useState("");
  const [checkboxCgv, setCheckboxCgv] = useState(false);
  const [checkboxNewsletter, setCheckboxNewsletter] = useState(false);
  const [checkboxShipToAnotherAddress, setCheckboxShipToAnotherAddress] =
    useState(false);

  const [passwordError, setPasswordError] = useState("");
  const isErrorPassword = password !== confirmPassword;
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    // Stocker l'URL de redirection
    localStorage.setItem('redirectAfterLogin', '/checkAddress');
    navigate("/Connexion");
  };

  useEffect(() => {
    async function checkSession() {
      try {
        const response = await fetch(API_URLS.CHECK_SESSION, {
          credentials: "include"
          }
        );

        const data = await response.json();

        if (data.isLoggedIn) {
          // Si l'utilisateur est déjà connecté, c'est un utilisateur en cours d'inscription.
          // Pas besoin de redirection.
        }
      } catch (error) {
        console.error("Error checking session:", error);
      }
    }

    checkSession();
  }, []);

  useEffect(() => {
    const savedData = localStorage.getItem("stepOneData");
    if (savedData) {
      const data = JSON.parse(savedData);
      setFirstName(data?.firstName || "");
      setLastName(data?.lastName || "");
      setEmail(data?.email || "");
      setAddress(data.address || "");
      setAdditiveAddress(data.additiveAddress || "");
      setCity(data.city || "");
      setZipcode(data.zipcode || "");
      setState(data.state || "");
      setCountry(
        data.country ? { label: data.country, value: data.country } : ""
      );
      setFirstNameShipping(data.firstNameShipping || "");
      setLastNameShipping(data.lastNameShipping || "");
      setAddressShipping(data.addressShipping || "");
      setAdditiveAddressShipping(data.additiveAddressShipping || "");
      setCityShipping(data.cityShipping || "");
      setStateShipping(data.stateShipping || "");
      setZipcodeShipping(data.zipcodeShipping || "");
      setCountryShipping(
        data.countryShipping
          ? { label: data.countryShipping, value: data.countryShipping }
          : ""
      );
      setPhone(data.phone || "");
      setCheckboxCgv(data.checkboxCgv || false);
      setCheckboxNewsletter(data.checkboxNewsletter || false);
      setCheckboxShipToAnotherAddress(
        data.checkboxShipToAnotherAddress || false
      );
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "stepOneData",
      JSON.stringify({
        firstName,
        lastName,
        email,
        address,
        additiveAddress,
        city,
        zipcode,
        state,
        country: country?.label,
        firstNameShipping,
        lastNameShipping,
        addressShipping,
        additiveAddressShipping,
        cityShipping,
        stateShipping,
        zipcodeShipping,
        countryShipping: countryShipping?.label,
        phone,
        checkboxCgv,
        checkboxNewsletter,
        checkboxShipToAnotherAddress,
      })
    );
  }, [
    firstName,
    lastName,
    email,
    address,
    additiveAddress,
    city,
    zipcode,
    state,
    country,
    firstNameShipping,
    lastNameShipping,
    addressShipping,
    additiveAddressShipping,
    cityShipping,
    stateShipping,
    zipcodeShipping,
    countryShipping,
    phone,
    checkboxCgv,
    checkboxNewsletter,
    checkboxShipToAnotherAddress,
  ]);

  const validatePassword = (password) => {
    // Vérifiez si le mot de passe contient au moins 8 caractères, un chiffre et un caractère spécial
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;

    if (!passwordRegex.test(password)) {
      return false; // Retournez false pour indiquer que le mot de passe n'est pas valide
    }
    return true; // Retournez true si le mot de passe est valide
  };

  const checkPassword = (password) => {
    if (!validatePassword(password)) {
      setPasswordError(
        "Le mot de passe doit contenir au moins 8 caractères, un chiffre et un caractère spécial."
      );
    }
    if (validatePassword(password)) {
      setPasswordError("");
    }

    setPassword(password);
  };

  const handleSubmit = async () => {
    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !confirmPassword ||
      !address ||
      !city ||
      !zipcode ||
      !phone ||
      !country ||
      !checkboxCgv
    ) {
      alert("Vous devez remplir les champs obligatoires.");
      return;
    }

    if (!validatePassword(password)) {
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError("Le mot de passe et la confirmation ne correspondent pas.");
      return;
    }

    const userData = {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      address,
      additiveAddress,
      state,
      city,
      zipcode,
      phone,
      country: country.label,
      checkboxCgv,
      checkboxNewsletter,
      firstNameShipping,
      lastNameShipping,
      addressShipping,
      additiveAddressShipping,
      cityShipping,
      stateShipping,
      zipcodeShipping,
      countryShipping,
    };

    // Stockage des données sans le mot de passe dans le local storage
    localStorage.setItem(
      "stepOneData",
      JSON.stringify({
        ...userData,
        password: undefined,
        confirmPassword: undefined,
      })
    );

    try {
      const sessionCheckResponse = await fetch(API_URLS.CHECK_SESSION, {
        credentials: "include"
        }
      );
      const sessionData = await sessionCheckResponse.json();

      if (sessionData.isLoggedIn) {
        // Si l'utilisateur a une session ouverte, mettez à jour ses données
        const updateResponse = await fetch(API_URLS.UPDATE_USER_PROFILE,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
            credentials: "include",
          }
        );

        if (updateResponse.ok) {
          navigate("/delivery");
        } else {
          const updateErrorData = await updateResponse.json();
          setPasswordError(
            updateErrorData.error ||
              "Une erreur s'est produite lors de la mise à jour."
          );
        }
        return;
      }

      // Si la session n'est pas ouverte, vérifie l'email
      const emailCheckResponse = await fetch(API_URLS.CHECK_EMAIL(email),
        {
          credentials: "include", // Ajoutez cette ligne
        }
      );
      if (emailCheckResponse.status === 409) {
        alert("Vous avez déjà un compte, veuillez vous connecter.");
        window.location.href = "/Connexion";
        return;
      } else if (emailCheckResponse.ok) {
        // Enregistrez le nouvel utilisateur
        const registerResponse = await fetch(API_URLS.REGISTER_USER, 
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
            credentials: "include", // Ajoutez cette ligne
          }
        );

        if (registerResponse.ok) {
          navigate("/delivery");
        } else {
          const registerErrorData = await registerResponse.json();
          setPasswordError(
            registerErrorData.error ||
              "Une erreur s'est produite lors de l'inscription."
          );
        }
      } else {
        const emailErrorData = await emailCheckResponse.json();
        setPasswordError(
          emailErrorData.error ||
            "Une erreur s'est produite lors de la vérification de l'e-mail."
        );
      }
    } catch (error) {
      console.error("Erreur lors de la communication avec l'API:", error);
      setPasswordError("Une erreur s'est produite.");
    }
  };

  return (
    <Flex direction="column" alignItems="center" mt={{ base: "30%", md: "15%",lg: "15%" , xl:"10%"}} mb="5%"  position="relative"
    zIndex="2">
     
      <StepProgress currentStep={1} />
      
      <Box rounded="lg" bg="white" boxShadow="lg" p="4" mt={{ base: "6%", md: "1%",lg: "3%",xl:"3%" }} w="full" maxW={{ base: "xs", md: "sm",lg: "sm" }}>
        <Center>
          <Button
            mb="3%"
            backgroundColor="#98b29f"
            color="#293d36"
            onClick={handleLoginRedirect}
          >
            J'ai déjà un compte
          </Button>
        </Center>
        <FormControl isRequired>
          <FormLabel>Prénom:</FormLabel>
          <Input
            placeholder="Prénom"
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            focusBorderColor="#293d36"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel mt="2%">Nom:</FormLabel>
          <Input
            placeholder="Nom"
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            focusBorderColor="#293d36"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel mt="2%">Email:</FormLabel>
          <Input
            placeholder="Email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            focusBorderColor="#293d36"
          />
        </FormControl>
        <FormControl isRequired isInvalid={passwordError}>
          <FormLabel mt="2%">Mot de passe:</FormLabel>
          <InputGroup size="md">
            <Input
              placeholder="Mot de passe"
              type={show ? "text" : "password"}
              id="password"
              onChange={(e) => checkPassword(e.target.value)}
              value={password}
              focusBorderColor="#293d36"
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            <Text
              maxW="280px"
              style={{ wordWrap: "break-word" }}
              textAlign="center"
            >
              {passwordError}
            </Text>
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={isErrorPassword}>
          <FormLabel mt="2%">Confirmer le mot de passe:</FormLabel>
          <InputGroup size="md">
            <Input
              placeholder="Confirmer le mot de passe"
              type={show ? "text" : "password"}
              id="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              focusBorderColor="#293d36"
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          {!isErrorPassword ? null : (
            <FormErrorMessage>Les mots de passe ne correspondent pas</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired>
          <FormLabel mt="2%">Adresse:</FormLabel>
          <Input
            placeholder="Adresse"
            type="text"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            focusBorderColor="#293d36"
          />
        </FormControl>
        <FormControl>
          <FormLabel mt="2%">Complément d'adresse:</FormLabel>
          <Input
            placeholder="Complément d'adresse"
            type="text"
            onChange={(e) => setAdditiveAddress(e.target.value)}
            value={additiveAddress}
            focusBorderColor="#293d36"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel mt="2%">Ville:</FormLabel>
          <Input
            placeholder="Ville"
            type="text"
            autoComplete="address-level2"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            focusBorderColor="#293d36"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel mt="2%">Code postal:</FormLabel>
          <Input
            placeholder="Code postal"
            type="text"
            autoComplete="postal-code"
            onChange={(e) => setZipcode(e.target.value)}
            value={zipcode}
            focusBorderColor="#293d36"
          />
        </FormControl>
        <FormControl>
          <FormLabel mt="2%">Région:</FormLabel>
          <Input
            placeholder="Région"
            autoComplete="state"
            onChange={(e) => setState(e.target.value)}
            value={state}
            focusBorderColor="#293d36"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel mt="2%">Pays:</FormLabel>
          <Select
            options={options}
            value={country}
            onChange={(e) => setCountry(e)}
          />
        </FormControl>
        <FormControl>
          <Checkbox
            isChecked={checkboxShipToAnotherAddress}
            onChange={(e) => setCheckboxShipToAnotherAddress(e.target.checked)}
            mt="2%"
            fontWeight="bold"
            color="#293d36"
          >
           Votre commande est un cadeau? Envoyer à une adresse différente
          </Checkbox>
        </FormControl>
        {checkboxShipToAnotherAddress && (
          <>
            <FormControl isRequired>
              <FormLabel mt="2%">Prénom (Shipping):</FormLabel>
              <Input
                type="text"
                onChange={(e) => setFirstNameShipping(e.target.value)}
                value={firstNameShipping}
                focusBorderColor="#293d36"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel mt="2%">Nom (Shipping):</FormLabel>
              <Input
                type="text"
                onChange={(e) => setLastNameShipping(e.target.value)}
                value={lastNameShipping}
                focusBorderColor="#293d36"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel mt="2%">Adresse (Shipping):</FormLabel>
              <Input
                type="text"
                onChange={(e) => setAddressShipping(e.target.value)}
                value={addressShipping}
                focusBorderColor="#293d36"
              />
            </FormControl>
            <FormControl>
              <FormLabel mt="2%">Complément d'adresse (Shipping):</FormLabel>
              <Input
                type="text"
                onChange={(e) => setAdditiveAddressShipping(e.target.value)}
                value={additiveAddressShipping}
                focusBorderColor="#293d36"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel mt="2%">Ville (Shipping):</FormLabel>
              <Input
                type="text"
                onChange={(e) => setCityShipping(e.target.value)}
                value={cityShipping}
                focusBorderColor="#293d36"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel mt="2%">Code postal (Shipping):</FormLabel>
              <Input
                type="text"
                onChange={(e) => setZipcodeShipping(e.target.value)}
                value={zipcodeShipping}
                focusBorderColor="#293d36"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel mt="2%">Région (Shipping):</FormLabel>
              <Input
                type="text"
                onChange={(e) => setStateShipping(e.target.value)}
                value={stateShipping}
                focusBorderColor="#293d36"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel mt="2%">Pays (Shipping):</FormLabel>
              <Select
                options={options}
                value={countryShipping}
                onChange={(e) => setCountryShipping(e)}
              />
            </FormControl>
          </>
        )}
        <FormControl isRequired>
          <FormLabel mt="2%">Téléphone:</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <PhoneIcon color="gray.300" />
            </InputLeftElement>
            <Input
              type="number"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              focusBorderColor="#293d36"
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <Checkbox
            mt="5%"
            isChecked={checkboxCgv}
            onChange={(e) => setCheckboxCgv(e.target.checked)}
          >
            Accepter les termes et conditions de vente
          </Checkbox>
        </FormControl>
        <FormControl>
          <Checkbox
            isChecked={checkboxNewsletter}
            onChange={(e) => setCheckboxNewsletter(e.target.checked)}
          >
            S'inscrire à la newsletter
          </Checkbox>
        </FormControl>
          <Center>
        <Button
          bg="#98b29f"
          color="#293d36"
          borderColor="#293d36"
          mt="4%"
          mb="2%"
          onClick={handleSubmit}
        >
          Suivant
        </Button>
      </Center>
      </Box>
    </Flex>
  );
}
