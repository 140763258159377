import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Text, List, ListItem, VStack, Center, Flex, Image,Button } from "@chakra-ui/react";
import { FaHome, FaMapMarkerAlt, FaShippingFast, FaTag } from "react-icons/fa";import { clearCart, clearPromotion } from "../../../slices_ReduxToolkit/cartSlice.js";
import { useNavigate } from "react-router-dom";
import { setLoggedIn } from "../../../slices_ReduxToolkit/userSlice.js";
import { persistor } from '../../../store.js';
import { API_URLS } from "../../../Components/apiConfig.js";




// Custom Hook pour la vérification de session
const useSessionCheck = (navigate, dispatch) => {
  useEffect(() => {
    fetch(API_URLS.CHECK_SESSION, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          dispatch(setLoggedIn({ ...data }));
        } else {
          navigate("/Connexion");
        }
      })
      .catch(() => navigate("/Connexion"));
  }, [dispatch, navigate]);
};

// Custom Hook pour les informations de livraison
const useShippingInfo = () => {
  const [shippingData, setShippingData] = useState({});

  useEffect(() => {
    const fetchShippingInfo = async () => {
      try {
        const response = await fetch(API_URLS.GET_SHIPPING_INFO, { credentials: "include" });
        
        if (!response.ok) throw new Error("Failed to fetch shipping info");
        setShippingData((await response.json()).shippingDetails);
      } catch (error) {
        console.error("Error fetching shipping info:", error);
      }
    };
    fetchShippingInfo();
  }, []);

  return shippingData;
};


function OrderSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector((state) => state.order);
  const promotion = useSelector((state) => state.cart.promotion);
  const cart = useSelector((state) => state.cart);
  const { userId } = useSelector((state) => state.user);
  const shippingCost = useSelector((state) => state.order.shippingCost);
  const relayPoint = useSelector((state) => state.order.relayPoint);
  const selectedCarrier = useSelector((state) => state.order.selectedCarrier);
  const cartItems = useSelector((state) => state.cart.items);


  const handleGoHome = () => navigate('/');

  // Détermination de l'adresse de livraison ou du point relais
  useSessionCheck(navigate, dispatch);
  const shippingData = useShippingInfo();

  const shippingAddress = shippingData.shippingAddress
    ? `${shippingData.shippingFirstName} ${shippingData.shippingLastName}, ${shippingData.shippingAddress}, ${shippingData.shippingCity}, ${shippingData.shippingZipcode}, ${shippingData.shippingCountry}`
    : "Fallback address here..."; // Assurez-vous que ceci est défini dans le corps du composant


    // Calcul du montant total avec la promotion
  const totalAmount = cart.items.reduce(
    (total, item) => total + item.price * item.quantity, 0
  );

  let discount = 0;
  if (promotion) {
    switch (promotion.type) {
      case 'percentage':
        discount = totalAmount * (promotion.value / 100);
        break;
      case 'fixed':
        discount = promotion.value;
        break;
      case 'freeShipping':
        discount = order.selectedCarrier ? order.selectedCarrier.cost : 0;
        break;
      default:
        discount = 0;
        break;
    }
  }

// Calcul du montant final avec arrondi
const finalTotal = parseFloat((totalAmount + shippingCost - discount).toFixed(2));

  useEffect(() => {
    // Logique de création de commande en MongoDB...
    const createOrderInMongo = async () => {
      const stripeSessionId = localStorage.getItem("stripeSessionId");
      if (!stripeSessionId) {
        console.error("Stripe session ID not found");
        return;
      }

      const paymentCheckResponse = await fetch(`${API_URLS.CHECK_PAYMENT_STATUS}/${stripeSessionId}`, { credentials: "include" });
      const paymentCheckData = await paymentCheckResponse.json();

      const filteredCartItems = cartItems.map((item) => {
        // Trouver la taille sélectionnée en fonction de la contenance
        const selectedSize = item.sizes.find(
          (size) => size.contenance === item.selectedContenance
        );

        return {
          itemId: item._id,
          name: item.name,
          price: selectedSize ? selectedSize.price : 0, // Prix pour la taille sélectionnée
          quantityInCart: item.quantity, // Quantité d'articles mise dans le panier par le client
          selectedContenance: selectedSize ? selectedSize.contenance : null,
          stockQuantity: selectedSize ? selectedSize.quantity : 0, // Quantité en stock pour la taille sélectionnée
          selectedSizeId: item.selectedSize, // ID de la taille sélectionnée
          // Autres propriétés si nécessaire
        };
      });
      // Récupérer les informations de l'utilisateur
      const userResponse = await fetch(API_URLS.GET_USER_ADDRESS, {
        credentials: "include",
      });
      const userDetails = await userResponse.json();

      const orderData = {
        customerId: userId,
        items: filteredCartItems,
        shippingCost,
        carrier: selectedCarrier ? selectedCarrier.name : null,
        ...(selectedCarrier.name === "MondialRelay" && { mondialRelayPoint: relayPoint }),
        shippingAddress: {
          firstName: userDetails.shippingFirstName || userDetails.firstName,
          lastName: userDetails.shippingLastName || userDetails.lastName,
          address: userDetails.shippingAddress || userDetails.address,
          additiveAddress: userDetails.shippingAdditiveAddress || userDetails.additiveAddress,
          city: userDetails.shippingCity || userDetails.city,
          state: userDetails.shippingState || userDetails.state,
          zipcode: userDetails.shippingZipcode || userDetails.zipcode,
          country: userDetails.shippingCountry || userDetails.country,
          phone: userDetails.shippingPhone || userDetails.phone,
          email: userDetails.email, // Assurez-vous que l'email est toujours inclus
        },
        orderDate: new Date(), // Ajout de la date de la commande
        totalAmount: finalTotal, // Ajout du total de la commande
        discount: discount // Ajout du montant du discount
      };

      try {
        const response = await fetch(API_URLS.CREATE_ORDER, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(orderData),
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Échec de la création de la commande");
        } else {
          const orderResponseData = await response.json();
        }
      } catch (error) {
        console.error("Erreur lors de la création de la commande:", error);
        navigate("/failed"); // Rediriger vers la page d'échec
            }
    };

    if (cartItems.length > 0) {
      createOrderInMongo();
    }

    return () => {
      dispatch(clearCart());
      dispatch(clearPromotion());
      persistor.purge();
      localStorage.removeItem("cart");
      localStorage.removeItem("promotion");
      localStorage.removeItem("shippingDetails");
      localStorage.removeItem("stepOneData");
      localStorage.removeItem("newAddress");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("email");
      localStorage.removeItem("address");
      localStorage.removeItem("additiveAddress");
      localStorage.removeItem("city");
      localStorage.removeItem("zipcode");
    };
  }, [dispatch]);

  
  return (
    <Flex direction="column" alignItems="center" mt={{ base: "30%", md: "15%", xl:"10%" }} mb="5%">
      <Box rounded="lg" bg="white" boxShadow="lg" p="4" mt="2%" w="full" maxW="xs">
        <VStack spacing={4}>
          <Center height="30%">
            <VStack spacing={4} align="center" p="5" maxWidth="500px">
              <Text fontSize="2xl" color="#293d36" textAlign="center">Votre commande a été confirmée !</Text>
              <Box textAlign="center">
                <Text fontSize="xl" mt="4%" mb="6%" color="#293d36">Détails de la Commande</Text>
                <List spacing={3} w="full" mb="2%">
  {cart.items.map((item) => (
    <ListItem key={item._id} display="flex" alignItems="center" justifyContent="space-between">
      <Flex alignItems="center" flex="1">
        <Image
          rounded="lg"
          width={{ base: "50px", md: "80px" }}
          height={{ base: "50px", md: "80px" }}
          fit="cover"
          src={item.mainImage}
          alt={item.name}
          draggable="false"
          loading="lazy"
          mr={4}
        />
        <Box>
          <Text fontWeight="bold" color="#293D36">{item.name}</Text>
          <Text fontSize="sm" color="#293D36">{`Quantité : ${item.quantity}`}</Text>
        </Box>
      </Flex>
      <Text color="#293D36" ml={4}>{`${(item.price * item.quantity).toFixed(2)}€`}</Text>
    </ListItem>
  ))}
</List>

       {/* Réduction, si applicable */}
       {discount > 0 && (
                  <Flex align="center" justify="space-between" w="full" mb="2%">
                    <Box mr={2} color="#293D36"><FaTag /></Box>
                    <Text fontWeight="medium">{`- ${discount.toFixed(2)}€`}</Text>
                  </Flex>
                )}

                {/* Frais de livraison */}
                <Flex align="center" justify="space-between" w="full" mb="2%">
                  <Box mr={2} color="#293D36"><FaShippingFast /></Box>
                  <Text fontWeight="medium">{`${shippingCost.toFixed(2)}€`}</Text>
                </Flex>

                {/* Total final */}
                <Flex align="center" justify="space-between" w="full" mb="4%">
                  <Text fontWeight="bold" color="#293D36">Total:</Text>
                  <Text fontWeight="bold" fontSize="xl" color="#293D36">{`${finalTotal.toFixed(2)}€`}</Text>
                </Flex>

                 {/* Adresse de livraison ou Point Mondial Relay */}
                 {selectedCarrier && selectedCarrier.name === "MondialRelay" && relayPoint ? (
                  <Flex align="center" justify="space-between" w="full" mb="2%">
                    <Box mr={2} color="#293D36"><FaMapMarkerAlt /></Box>
                    <Text fontWeight="medium">{`Point Relay: ${relayPoint.Nom}, ${relayPoint.Adresse}`}</Text>
                  </Flex>
                ) : (
                  <Flex align="center" mb="2%">
                    <Box mr={2}>
                      <FaHome color="#293D36" />
                    </Box>
                    <Text color="#293D36">Adresse de Livraison : {shippingAddress}</Text>
                  </Flex>
                )}

                <Button mt={5} color="#293d36" background="#98b29f" onClick={handleGoHome}>Retour à l'Accueil</Button>
              </Box>
            </VStack>
          </Center>
        </VStack>
      </Box>
    </Flex>
  );
}

export default OrderSuccess;