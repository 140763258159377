import React from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Image,
  Text,
  Select,
  CloseButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  updateQuantity,
  removeItem,
} from "../../slices_ReduxToolkit/cartSlice.js";
import { PriceTag } from "./PriceTag.js";
import { CartOrderSummary } from "./CartOrderSummary.js";
import { Helmet } from 'react-helmet';


const CartItem = ({
  _id,
  name,
  quantity,
  mainImage,
  currency,
  price,
  sizes,
}) => {
  const dispatch = useDispatch();
  const onChangeQuantity = (newQuantity) =>
    dispatch(updateQuantity({ _id, quantity: newQuantity }));
  const onClickDelete = () => dispatch(removeItem(_id));

  return (
    <Flex
      direction="row"
      justify="space-between"
      align="center"
      width="full"
      p={{ base: "2", md: "4" }}
    >
      <Helmet>
        <title>Panier | Imagine Factory</title>
        <meta name="description" content="Consultez les articles dans votre panier et passez à la caisse pour finaliser votre achat." />
      </Helmet>
      {/* Product Image */}
      <Image
        rounded="lg"
        width={{ base: "60px", md: "120px" }}
        height={{ base: "60px", md: "120px" }}
        fit="cover"
        src={mainImage}
        alt={name}
        draggable="false"
        loading="lazy"
      />

      {/* Product Name */}
      <Text
        fontWeight="medium"
        fontSize={{ base: "sm", md: "md" }}
        flexShrink={0}
        mx={{ base: "2", md: "4" }}
      >
        {name}
      </Text>

      {/* Quantity Selector */}
      <Select
        maxW={{ base: "50px", md: "64px" }}
        value={quantity}
        onChange={(e) => onChangeQuantity(+e.currentTarget.value)}
        focusBorderColor={useColorModeValue("#293d36", "#293d36")}
      >
        {[...Array(10).keys()].map((i) => (
          <option key={i + 1} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </Select>

      {/* Price Tag */}
      <PriceTag
        price={price * quantity}
        currency={currency}
        flexShrink={0}
        mx={{ base: "2", md: "4" }}
      />

      {/* Delete Button */}
      <CloseButton
        aria-label={`Supprimer ${name} du panier`}
        onClick={onClickDelete}
      />
    </Flex>
  );
};

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.items);
  const totalItems = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <Box mt={{ base: "25%", lg: "8%" }} mx={{ base: "2%", lg: "2%" }}>
      <Stack
        direction={{ base: "column", lg: "row" }}
        align={{ lg: "flex-start" }}
        spacing={{ base: "8", md: "16" }}
      >
        <Stack spacing={{ base: "8", md: "10" }} flex="2">
          <Heading fontSize="2xl" color="#293d36">
            {totalItems > 0
              ? `Votre panier (${totalItems} item${totalItems > 1 ? "s" : ""})`
              : "Votre panier est vide"}
          </Heading>
          <Stack spacing="6">
            {cartItems.map((item, index) => (
              <CartItem key={item._id + "_" + index} {...item} />
            ))}
          </Stack>
        </Stack>
        <Flex direction="column" align="center" flex="1">
          <CartOrderSummary />
          <Link
            to="/"
            style={{
              marginTop: "2%",
              textDecoration: "underline",
              fontWeight: "semibold",
              color: "#293d36",
            }}
          >
            Continuer le shopping
          </Link>
        </Flex>
      </Stack>
    </Box>
  );
};

export default Cart;
