import React from "react";
import { Text } from "@chakra-ui/react";

const StarRating = ({ rating }) => {
  const totalStars = 5;
  let stars = [];
  for (let i = 1; i <= totalStars; i++) {
    if (i <= rating) {
      stars.push("⭐"); // Étoile pleine
    } else if (i - rating < 1) {
      stars.push("⭐️½"); // Demi-étoile
    } else {
      stars.push("☆"); // Étoile vide
    }
  }
  return (
    <Text fontSize={{base:"sm", md:"md"}}>
      {stars}
    </Text>
  );
};

export default StarRating;
