import React, { useState, useEffect, Suspense } from "react";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Text,
  Center,
  Image,
  Button,
  Select,
  Flex,
  VStack,
  useToast,
  HStack,
  useDisclosure,
  Collapse,
  List,
  ListItem,
  Heading,
  Tooltip,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../slices_ReduxToolkit/cartSlice";
import Loader from "../Loader.js";
import StarRating from "./RatingStars.js";
import { Divider } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FiAlertCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { API_URLS } from "../apiConfig.js";

export default function ArticlePage() {
  const [showFullInformation, setShowFullInformation] = useState(false);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { id: itemId } = useParams();
  const dispatch = useDispatch();
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [mainImageUrl, setMainImageUrl] = useState("");
  const [additionalImages, setAdditionalImages] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [productRating, setProductRating] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toast = useToast();
  const cartItems = useSelector((state) => state.cart.items);
  const [comments, setComments] = useState([]);
  const [productTitle, setProductTitle] = useState("");
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const navigate = useNavigate();
  const AvisClients = React.lazy(() => import("./comments.js"));
  const { isLoggedIn, userId } = useSelector((state) => state.user);
  const { isOpen, onToggle } = useDisclosure();
  const STATIC_URL =
    process.env.REACT_APP_STATIC_URL || "http://localhost:5000/uploads/";

  useEffect(() => {}, [isLoggedIn, userId]);

  const fetchProductDetails = async () => {
    try {
      const response = await fetch(API_URLS.GET_PRODUCT_DETAIL(itemId));
      const data = await response.json();
      if (!response.ok) throw new Error("Failed to fetch product details");

      if (!data.isVisible) {
        navigate("/"); // Rediriger vers la page d'accueil si l'article n'est pas visible
        return;
      }

      setProduct(data);
      setProductTitle(data.title);
      setMainImageUrl(`${STATIC_URL}${data.mainImage}`);
      setAdditionalImages(data.images.map((img) => `${STATIC_URL}${img}`));

      setProductRating(data.rating);
      if (data.sizes && data.sizes.length > 0) {
        setSelectedSize(data.sizes[0]);
        setSelectedPrice(data.sizes[0].price);
      }

      const fetchComments = async (productTitle) => {
        try {
          const response = await fetch(
            API_URLS.GET_REVIEWS_FOR_PRODUCT(productTitle)
          );
          const data = await response.json();
          if (!response.ok) throw new Error("Failed to fetch comments");
          setComments(data);
        } catch (error) {
          console.error("Error fetching comments:", error);
        }
      };

      const fetchAverageRating = async (productTitle) => {
        try {
          // Assurez-vous que le titre est correctement encodé
          const response = await fetch(
            API_URLS.GET_AVERAGE_RATING(productTitle)
          );
          const ratingData = await response.json();

          if (response.ok) {
            setAverageRating(ratingData.averageRating);
            setTotalReviews(ratingData.totalReviews);
          } else {
            throw new Error("Failed to fetch average rating");
          }
        } catch (error) {
          console.error("Error fetching average rating:", error);
        }
      };

      // Appel des fonctions pour récupérer les commentaires et la note moyenne
      await fetchComments(data.title);
      await fetchAverageRating(data.title);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [itemId]);

  const navigateToReviews = () => {
    const reviewsSection = document.getElementById("avis-clients");
    if (reviewsSection) {
      reviewsSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const toggleInformation = () => {
    setShowFullInformation(!showFullInformation);
  };

  const benefitsDefinitions = {
    cocooning:
      "Cocooning :Pour amateurs de Chocolat chaud, chaussettes douillettes et feu de cheminée. Savourez votre instant cocooning! 🍵🔥✨",
    relax:
      "Relax:Comme une caresse sur l'âme.Un havre de paix.Favorise le repos et la relaxation.🕯️☮️😌🌸 ",
    focus:
      "Focus: Eveille votre génie intérieur. Stimule la concentration et la créativité. 🖌️📚🧠✨",
    emoti:
      "Emoti:Réchauffe l'esprit et apaise le cœur, comme une étreinte d'amour et de réconfort. 😊🌞🩷",
    invigorating:
      "Invigorating:Plongez dans un océan de fraîcheur!Imaginez une vague d'embruns vivifiants qui vous enveloppe! 🌊✨💦",
    boost:
      "Boost: Comme un espresso pour votre âme!Réveillez votre espace avec des notes énergisantes!☕⚡",
    meditation:
      "Meditate: Votre séance de méditation avec une touche parfumée pour atteindre le nirvana.🕉️✨",
    festive: "Festive: La fête dans un pot!Parfaite pour vos célébrations!🎉🎈",
    new: "New:Nouvelle création! 🌟🎇✨",
    romantic:
      "Romantic: Pour des soirées à deux.Amour, lumière tamisée et magie dans l'air!🌹🥂 ",

    // autres bénéfices....
  };

  const benefitsIcons = {
    emoti: "/svg/emoti_icone.svg",
    relax: "/svg/relax_icone.svg",
    diffusion: "/svg/diffusion_icone.svg",
    new: "/Icones/new.svg",
    cocooning: "/svg/cocooning_icone1.svg",
    boost: "/svg/boost_icone.svg",
    romantic: "/svg/romantic_icone.svg",
    invigorating: "/svg/invigorating_icone.svg",
    meditation: "/svg/meditate_icone.svg",
    festive: "/svg/festive_icone.svg",
    focus: "/svg/focus_icone.svg",

    // autres catégories...
  };

  const increaseQuantity = () => {
    if (quantity < 10) {
      // Supposons que la quantité maximale soit 10
      setQuantity(quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      // Supposons que la quantité minimale soit 1
      setQuantity(quantity - 1);
    }
  };

  const changeMainImage = (newImageUrl, index) => {
    // Échanger l'URL de l'image principale avec l'image cliquée
    setAdditionalImages((prevImages) =>
      prevImages.map((img, idx) => (idx === index ? mainImageUrl : img))
    );
    setMainImageUrl(newImageUrl);
  };

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error || !product) {
    return toast({
      title: "Oups !",
      description:
        "Un problème est survenu lors de la récupération des données du produit. Veuillez réessayer plus tard.",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  }

  const imageUrl = `${STATIC_URL}${product.mainImage}`;

  const handleAddToCart = () => {
    if (!selectedSize) {
      console.error("No size selected");
      return;
    }

    const cartQuantity = cartItems
      .filter(
        (item) =>
          item._id === product._id && item.selectedSize === selectedSize._id
      )
      .reduce((sum, item) => sum + item.quantity, 0);

    if (cartQuantity + quantity > selectedSize.quantity) {
      // Afficher la notification toast
      toast({
        title: "Oups, c'est trop !",
        description:
          "Vous tentez de commander plus que ce que nous avons en stock. Veuillez ajuster votre quantité.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    dispatch(
      addItem({
        _id: product._id,
        name: product.title,
        price: selectedPrice,
        quantity,
        selectedSize: selectedSize._id,
        selectedContenance: selectedSize.contenance,
        mainImage: imageUrl,
        sizes: product.sizes,
      })
    );

    // Afficher le toast de confirmation
    toast({
      position: "bottom",
      duration: 5000,
      isClosable: true,

      render: () => (
        <Box
          p="3"
          bg="#98b29f"
          borderRadius="8px"
          border="2px solid #293d36"
          maxWidth="100%"
          width={["90%", "70%", "60%"]}
          display="flex"
          alignItems="center"
          fontFamily="Roboto"
          color="#293d36"
        >
          <Image
            src={imageUrl}
            width="50px"
            height="50px"
            mr="3"
            borderRadius="8px"
          />
          <Box>
            <Text fontWeight="bold" color="#293d36" mb="2%">
              {`${product.title} ajouté(e) au panier !`}
            </Text>

            <Text mb="2%">Votre voyage olfactif et magique commence !</Text>
          </Box>
        </Box>
      ),
    });
  };

  const handleSizeChange = (event) => {
    const newSize = product.sizes.find(
      (size) => size._id === event.target.value
    );
    setSelectedSize(newSize);
    setSelectedPrice(newSize.price); // Mise à jour du prix en fonction de la taille sélectionnée
  };

  const isOutOfStock = selectedSize && selectedSize.quantity === 0;

  const renderBenefitsIcons = () => {
    return product.benefits.map((benefit, index) => (
      <Tooltip label={benefitsDefinitions[benefit]} key={index} hasArrow>
        <Box display="inline-block" mx="1">
          <Image src={benefitsIcons[benefit]} boxSize="60px" alt={benefit} />
        </Box>
      </Tooltip>
    ));
  };

  const contentUrl = `${STATIC_URL}${product.mainImage}`;

  return (
    <Container
      mt={{ base: "30%", md: "18%", lg: "9%" }}
      p={{ base: "5%", md: "1%" }}
      fontFamily="Roboto"
      color="#293D36"
      fontSize={{ base: "md", md: "xl" }}
      backgroundColor="#f3f6f4"
      borderRadius="md"
      maxWidth="full"
      width={{ base: "95%", md: "90%", lg: "70%", xl: "60%", "2xl": "50%" }}
      position="relative"
      zIndex="2"
    >
      <Helmet>
        {/* Les balises meta classiques pour les moteurs de recherche */}
        <title>Bougie parfumée {product.underTitle}</title>
        <meta
          name="description"
          content={`Découvrez notre bougie '${
            product.title
          }' - ${product.description.substring(0, 150)}...`}
        />
        <meta
          name="keywords"
          content="bougie, artisanale, XXL, parfumée, naturelle, décoration, cadeau, éco-responsable, sans paraffine, cire coco, cire colza, meilleure bougie, ecofriendly"
        />

        {/* Les balises Open Graph pour les réseaux sociaux */}
        <meta
          property="og:title"
          content={`Bougie parfumée ${product.title}`}
        />
        <meta property="og:description" content={product.description} />
        <meta property="og:image" content={contentUrl} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="product" />
        {/* Et d'autres balises OG si nécessaire */}
      </Helmet>

      <Link
        to="/#carousel"
        style={{
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FiArrowLeft style={{ marginRight: "8px" }} />
        <Text color="#293d36" fontSize="lg" fontWeight="bold">
          Retour au Catalogue
        </Text>
      </Link>

      <Flex direction={{ base: "column", md: "row" }} gap="6" mt="2%">
        {/* Colonne de gauche pour les images */}
        <Box width={{ base: "100%", md: "50%" }} position="relative">
          <Image
            src={mainImageUrl}
            alt="Main Image"
            maxWidth="100%"
            maxHeight={{ base: "auto", md: "70%", lg: "45%" }}
            boxSize="100%"
            mb="4"
            objectFit="contain"
            borderRadius="lg"
            cursor="pointer"
          />
          {product.benefits && product.benefits.length > 0 && (
            <HStack position="absolute" top="10px" left="10px">
              {renderBenefitsIcons()}
            </HStack>
          )}

          {/* Images supplémentaires */}
          <Grid templateColumns="repeat(3, 1fr)" gap={2}>
            {additionalImages.map((img, index) => (
              <GridItem key={index} colSpan={1}>
                <Image
                  src={img}
                  alt={`Additional Image ${index + 1}`}
                  boxSize="100%"
                  objectFit="cover"
                  cursor="pointer"
                  borderRadius="lg"
                  onClick={() => changeMainImage(img, index)}
                />
              </GridItem>
            ))}
          </Grid>
        </Box>

        {/* Colonne de droite pour les informations du produit */}
        <VStack width={{ md: "50%" }} spacing="3" align="start">
          <Flex alignItems="center" justifyContent="left" width="100%">
            <Text
              fontSize={{ base: "lg", md: "2xl" }}
              fontWeight="bold"
              mr="10%"
              width={{ base: "50%", md: "50%", xl: "50%", "2xl": "60%" }}
            >
              {product.title}
            </Text>
            <Box
              cursor="pointer"
              onClick={navigateToReviews}
              display="flex"
              alignItems="center"
              ml={{ base: "2", md: "2", lg: "2", xl: "0" }}
            >
              <StarRating rating={averageRating} />
              <Text fontSize="sm" ml="2">{`(${totalReviews} Avis)`}</Text>
            </Box>
          </Flex>
          <Box width="100%" mt="0">
            <Divider borderColor="#293d36" borderWidth="1px" my="0" />
          </Box>

          <Flex alignItems="center" width="100%" mt="4%" position="relative">
            <Image src={"/Icones/Fragrance.svg"} boxSize="100px" ml="1%" />{" "}
            {/* Conteneur pour centrer le texte */}
            <Box flex="1" mb="14%">
              <Text
                fontSize={{ base: "lg", md: "lg", lg: "xl" }}
                textAlign={{
                  base: "center",
                  md: "left",
                  xl: "center",
                  "2xl": "center",
                }}
                mr={{ base: "15%", lg: "15%" }}
                mt={{ lg: "3%" }}
                fontWeight="bold"
              >
                {product.underTitle}
              </Text>
            </Box>
          </Flex>

          {/* Description Section */}
          <VStack align="start" width="100%" spacing={3} mt="3%">
            <Heading fontSize="xl" color="#293D36">
              Description
            </Heading>
            <Text
              fontSize={{ base: "sm", md: "md", lg: "md" }}
              noOfLines={showFullDescription ? null : 3} // Show only 3 lines initially
              textAlign="left"
            >
              {product.description}
            </Text>
            <Box>
              <Button
                variant="link"
                onClick={toggleDescription}
                fontSize={{ base: "sm", md: "md", lg: "md" }}
              >
                {showFullDescription ? "Voir moins" : "Voir plus"}
              </Button>
            </Box>
          </VStack>

          {/* Information Section */}
          <VStack align="start" width="100%" spacing="3" mt="3%">
            <Heading fontSize="xl" color="#293D36">
              Information
            </Heading>
            <Text
              fontSize={{ base: "sm", md: "md", lg: "md" }}
              noOfLines={showFullInformation ? null : 3} // Show only 3 lines initially
              textAlign="left"
            >
              {product.information}
            </Text>
            <Box>
              <Button
                variant="link"
                onClick={toggleInformation}
                fontSize={{ base: "sm", md: "md", lg: "md" }}
              >
                {showFullInformation ? "Voir moins" : "Voir plus"}
              </Button>
            </Box>
          </VStack>

          {/* Section Consignes de Sécurité */}
          <Box mt="1">
            <Button
              variant="ghost"
              onClick={onToggle}
              fontSize="lg"
              color="#293d36"
              style={{ whiteSpace: "normal", width: "auto" }}
              leftIcon={isOpen ? <FiAlertCircle /> : <FiAlertCircle />}
            >
              {isOpen
                ? "Masquer les consignes de sécurité "
                : "Afficher les consignes de sécurité "}
            </Button>
            <Collapse in={isOpen} animateOpacity>
              <Box
                p="4"
                color="black"
                mt="4"
                bg="gray.100"
                rounded="md"
                shadow="md"
              >
                {/* Ici, mettez le contenu de vos consignes de sécurité */}
                <Text fontSize="lg" fontWeight="bold" color="#293d36" mb="2">
                  Conseils d'utilisation :
                </Text>
                <List spacing={2} fontSize="md" color="#293d36" mb="2">
                  <ListItem>
                    Ne jamais laisser une bougie allumée sans surveillance.
                  </ListItem>
                  <ListItem>
                    Placez votre bougie à l'abri des courants d'air.
                  </ListItem>
                  <ListItem>
                    Gardez vos bougies hors de portée des enfants, des rideaux
                    et des animaux domestiques.
                  </ListItem>
                  <ListItem>Si de la fumée apparaît, coupez la mèche.</ListItem>
                  <ListItem>
                    Protégez la surface sur laquelle votre bougie est posée (en
                    utilisant une assiette ou un plateau, par exemple).
                  </ListItem>
                  <ListItem>
                    Ne brûlez pas votre bougie jusqu'au fond , laissez toujours
                    au moins un demi-centimètre de cire non fondue.
                  </ListItem>
                  <ListItem>
                    Conservez votre bougie dans un endroit sec et tempéré, entre
                    15 et 25°C.
                  </ListItem>
                  <ListItem>Si de la fumée apparaît, coupez la mèche.</ListItem>
                  <ListItem>
                    Ne touchez pas et ne déplacez pas votre bougie lorsqu'elle
                    est allumée ou tant que la cire est encore liquide.
                  </ListItem>
                  <ListItem>
                    Assurez-vous que la bougie est placée sur une surface stable
                    pour éviter les risques de chute.
                  </ListItem>
                  <ListItem>
                    Évitez d'utiliser des bougies dans des espaces confinés et
                    peu ventilés.
                  </ListItem>
                  <ListItem>
                    En cas d'urgence, éteignez la bougie immédiatement et aérez
                    la pièce.
                  </ListItem>
                  <ListItem>
                    N'allumez pas de bougies près de matériaux inflammables.
                  </ListItem>
                  <ListItem>
                    Respectez toujours les réglementations locales concernant
                    l'utilisation de bougies dans les espaces publics et privés.
                  </ListItem>
                </List>
              </Box>
            </Collapse>
          </Box>

          {selectedSize && (
            <Flex mt={{ base: "2%", md: "15%", lg: "5%" }} ml="3%">
              <Image src={"/Icones/size.svg"} boxSize="30px" mr="10%" />
              <Select
                id="size"
                value={selectedSize ? selectedSize._id : ""}
                onChange={handleSizeChange}
                width="150px"
                borderWidth="3px"
                borderRadius="md"
                borderColor="#98b29f"
                sx={{
                  "&:focus": {
                    borderColor: "#98b29f",
                    boxShadow: `0 0 0 1px #98b29f`,
                  },
                }}
              >
                {product &&
                  product.sizes.map((size) => (
                    <option key={size._id} value={size._id}>
                      {size.contenance} ml
                    </option>
                  ))}
              </Select>
            </Flex>
          )}
          {/* Affichage du prix avec l'icône */}

          <Flex mr="2" ml="3%">
            <Image src={"/Icones/price.svg"} boxSize="30px" mr="7" />
            <Text fontSize="lg" fontWeight="bold">
              {selectedPrice.toFixed(2)} €
            </Text>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="70%"
            ml="3%"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="70%"
            >
              <Button
                onClick={decreaseQuantity}
                size="md"
                mr="2"
                bgColor="#98b29f"
              >
                -
              </Button>
              <Text fontSize="md">{quantity}</Text>
              <Button
                onClick={increaseQuantity}
                size="md"
                ml="2"
                bgColor="#98b29f"
              >
                +
              </Button>
            </Flex>
            <Text mt="2%" fontSize="sm">
              {isOutOfStock ? "En rupture de stock" : "En stock"}
            </Text>
          </Flex>
          {/* Bouton d'ajout au panier */}
          <Button
            mt="5%"
            width="80%"
            colorScheme="green"
            background="#98b29f"
            _hover={{ bg: "#769f7b" }}
            fontSize={{ base: "md", md: "xl" }}
            onClick={handleAddToCart}
            isDisabled={isOutOfStock} // Disable button if out of stock
            ml="3%"
            mb="10%"
          >
            Ajouter au panier
          </Button>
        </VStack>
      </Flex>

      <Suspense fallback={<Loader />}>
        {/* Avis clients en dessous */}
        <Center id="avis-clients">
          <AvisClients
            productId={itemId}
            existingComments={comments}
            productTitle={productTitle}
            isLoggedIn={isLoggedIn}
            userId={userId}
          />
        </Center>
      </Suspense>
    </Container>
  );
}
