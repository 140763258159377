import { useAnimations, useGLTF } from '@react-three/drei'
import { useEffect } from 'react'



    export default function Cat() {
 
    const cat = useGLTF ('./GLTF/FoxOptimised.glb')
    
    const animations= useAnimations (cat.animations, cat.scene)
    
    useEffect (()=> {
   
   const action= animations.actions.Idle
   action.play()

    })
    

  return (
 
    <primitive object={cat.scene} position-y={-1.2} position-x={1} scale={3.4} />

  )
  
}



