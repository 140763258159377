import React, { useState, useEffect } from "react";
import MondialRelay from "../../Components/MondialRelay.js";
import { setSelectedCarrier, setRelayPoint } from "../../slices_ReduxToolkit/orderSlice.js";
import { Button, Center, Text, Box, Alert, AlertIcon,Flex, Radio, RadioGroup, } from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import StepProgress from "./StepProgress.js"
import { useNavigate } from "react-router-dom";
import { setLoggedIn } from "../../slices_ReduxToolkit/userSlice.js"
import { setShippingCost } from "../../slices_ReduxToolkit/orderSlice.js";
import { Helmet } from 'react-helmet';
import { API_URLS } from "../../Components/apiConfig.js";




export default function StepTwo() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const selectedCarrier = useSelector((state) => state.order.selectedCarrier);
  const [rates, setRates] = useState([]);
  const navigate = useNavigate();


  const shippingCountry = user.shippingCountry || user.country;
  const carriers = ["DHL", "Colissimo", "MondialRelay"];
  const cartItems = useSelector((state) => state.cart.items);
  const euCountries = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
  ];
  const relayPoint = useSelector((state) => state.order.relayPoint);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Vérifier l'état de la session lorsque le composant charge sinon le composant ne s'affichera pas
    fetch(API_URLS.CHECK_SESSION, {
      credentials: "include",
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.isLoggedIn) {
        dispatch(
          setLoggedIn({
            userId: data.userId,
            isAdmin: data.isAdmin,
            country: data.country,
            shippingCountry: data.shippingCountry,
          })
        );
      } else {
        // Si l'utilisateur n'est pas connecté, rediriger vers la page de connexion
        navigate('/Connexion');
      }
    })
    .catch((error) => {
      console.error("Failed to verify session:", error);
      // Gérer également la redirection en cas d'erreur
      navigate('/Connexion');
    });
  }, [dispatch, navigate]);


  const calculateTotalWeight = (cartItems) => {
    let totalWeight = 0;
    cartItems.forEach((item) => {
      if (item.sizes && Array.isArray(item.sizes)) {
        const size = item.sizes.find((s) => s._id === item.selectedSize);
        if (size) {
          const weightInKg = parseFloat(size.contenance) * 0.001;
          totalWeight += weightInKg * item.quantity;
        }
      } else {
        console.error("Sizes array not found or it's not an array", item);
      }
    });
    return totalWeight;
  };

  const totalWeight = calculateTotalWeight(cartItems);

  const fetchRates = async () => {
    try {
      const response = await fetch(
        API_URLS.GET_SHIPPING_RATES
      );
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des tarifs");
      }
      const fetchedRates = await response.json();
      setRates(fetchedRates);
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  useEffect(() => {
    fetchRates();
  }, []);

  const calculateShippingCost = (carrier, destination, totalWeight, rates) => {
    let shippingCost = null;

    const applicableRates = rates.filter(
      (rate) =>
        rate.carrier === carrier && rate.destinations.includes(destination)
    );

    if (applicableRates.length > 0) {
      applicableRates.forEach((rate) => {
        const foundRate = rate.rates.find(
          (r) => totalWeight >= r.min && totalWeight <= r.max
        );
        if (foundRate) {
          shippingCost = foundRate.rate;
        }
      });
    }

    // Si aucun tarif n'a été trouvé ou si le pays de destination n'est pas couvert
    if (shippingCost === null) {
      const defaultRates = rates.find(
        (rate) => rate.carrier === `${carrier}DefaultRates`
      );
      if (defaultRates) {
        const defaultRate = defaultRates.rates.find(
          (r) => totalWeight >= r.min && totalWeight <= r.max
        );
        if (defaultRate) {
          shippingCost = defaultRate.rate;
        }
      }
    }

    
    if (carrier === "MondialRelay" && destination !== "France") {
      shippingCost = null; 
    }

    return shippingCost;
  };

  const shippingCosts = carriers.map((carrier) => ({
    carrier,
    cost: calculateShippingCost(carrier, shippingCountry, totalWeight, rates),
  }));

  

  const handleCarrierChange = (carrierName) => {
    const carrier = shippingCosts.find(c => c.carrier === carrierName);
    if (carrier) {
      dispatch(setSelectedCarrier({ 
        name: carrier.carrier, 
        cost: carrier.cost 
      }));

        // Mise à jour de Redux avec le coût d'expédition
    dispatch(setShippingCost(carrier.cost));

      
  
      // Réinitialise l'état du point relais si le transporteur n'est pas Mondial Relay
      if (carrierName !== "MondialRelay") {
        dispatch(setRelayPoint(null));
      }
    }
  };
  

  const handleSubmit = () => {
    // Vérifie si un transporteur a été sélectionné
    if (selectedCarrier) {
      // Si le transporteur sélectionné est MondialRelay, vérifie aussi si un point relais a été sélectionné
      if (selectedCarrier.name === "MondialRelay" && !relayPoint) {
        setErrorMessage("Sélectionnez un point relais");
      } else {
               // Si un transporteur est sélectionné et (s'il s'agit de MondialRelay, un point relais est aussi sélectionné), passez à l'étape suivante
        navigate("/payment");

      }
    } else {
      // Si aucun transporteur n'est sélectionné, affiche un message d'erreur
      setErrorMessage("Sélectionnez un mode de livraison");
    }
  };

 const handlePrevious = () => {
    navigate(-1);
  };

  

  return (
    
      <Flex direction="column" alignItems="center" mt={{ base: "30%", md: "15%",lg: "15%", xl:"10%" }} mb="5%"  position="relative"
      zIndex="2">
        <Helmet>
      <title>Choix de la Livraison - Imagine Factory</title>
      <meta name="description" content="Sélectionnez votre mode de livraison pour continuer le processus de commande." />
    </Helmet>
        <StepProgress currentStep={2} />
        <Box rounded="lg" bg="white" boxShadow="lg" p="4" mt={{ base: "6%", md: "1%",lg: "4%",xl:"3%" }} w="full" maxW={{ base: "xs", md: "sm",lg: "sm", xl:"xl" }}>
        <RadioGroup 
          onChange={handleCarrierChange} 
          value={selectedCarrier ? selectedCarrier.name : ""}
        >
          {shippingCosts.map(({ carrier, cost }) => (
            <Box key={carrier} mb="4">
              <Radio value={carrier}
              colorScheme="green"
              isDisabled={cost === null}>
                {"  "} Livraison en {carrier} - {cost ? `${cost.toFixed(2)} €` : "Non disponible"}
              </Radio>
            </Box>
          ))}
        </RadioGroup>
          {errorMessage && (
            <Alert status="error">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          {/* Note informative concernant les frais de douane pour les clients hors Europe */}
          {shippingCountry && !euCountries.includes(shippingCountry) && (
            <Center my="5">
              <Box maxWidth="auto" textAlign="center">
                <Text fontSize="sm" color="red">
                  Please note, for destinations outside of Europe, customs duties and charges may apply. These charges are the responsibility of the recipient and must be settled by the recipient upon receipt.
                </Text>
              </Box>
            </Center>
          )}
  
          {selectedCarrier && selectedCarrier.name === "MondialRelay" && (
            <MondialRelay />
          )}
  
          <Center mt="2%">
            <Button leftIcon={<FaArrowLeft />} onClick={handlePrevious} mr="2%" color="#293d36" background="#98b29f">
            </Button>
            <Button onClick={handleSubmit} color="#293d36" background="#98b29f">Suivant</Button>
          </Center>
        </Box>
      </Flex>
     )};