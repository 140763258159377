import React from 'react';
import { motion } from 'framer-motion';
import { useBreakpointValue } from '@chakra-ui/react';

const RotatingIcon = () => {
    const marginLeft = useBreakpointValue({ base: '66%', sm: '70%', md: '75%', lg: '75%', xl: '30%', "2xl":"28%" });
    const marginTop = useBreakpointValue({ base: '27%', sm: '25%', md: '10%', lg: '10%', xl: '9%', "2xl":"5%" });
    const size = useBreakpointValue({ base: '125px', sm: '160px', md: '160px', lg: '160px', xl: '170px', "2xl":"250px" });


    return (
        <motion.div
        style={{
          position: 'absolute', 
          zIndex: 10, 
          width: size, 
          marginLeft: marginLeft, 
          marginTop: marginTop,
        }}
      >
        <motion.img
          src="./svg/icone_feuille.svg"
          animate={{ rotate: 360 }}
          transition={{ duration: 30, repeat: Infinity, ease: "linear" }}
          style={{ width: "100%", height: "100%", display: 'block' }}
        />
      </motion.div>
    );
};

export default RotatingIcon;
