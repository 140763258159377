import React, {useEffect} from 'react'
import { Box} from "@chakra-ui/react";

function GoogleTranslateDropdown() {

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "fr",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <>
    <Box
     id="google_translate_element"
     position="absolute"
     zIndex="1"
     mt="2%"
     ml={{lg:"80%",xl:"80%","2xl":"83%"}}
     display={{ base: 'none', md: 'none', lg:'block' }}
     >

     </Box>
  </>
  )
}

export default GoogleTranslateDropdown