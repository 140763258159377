import {useGLTF, useAnimations } from '@react-three/drei'
import { useEffect } from 'react'


function Hummingbird() {
const hummingbird = useGLTF ('./GLTF/hummingbird.glb')

const animations= useAnimations (hummingbird.animations,hummingbird.scene)

useEffect (()=> {
    const action= animations.actions.BirdFlyAll
    action.play()
 })


return (

 
<primitive object={hummingbird.scene} rotation-y={9} position-y={0.8} position-z={3} position-x={-0.8} scale={1.3}/>
  )
  
}
export default Hummingbird