import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_URLS } from "../apiConfig.js";

import {
  Box,
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Select,
} from "@chakra-ui/react";
import ProductDetails from "./ProductDetails";

const ProductsList = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const navigate = useNavigate();
  const STATIC_URL =
    process.env.REACT_APP_STATIC_URL || "http://localhost:5000/uploads/";
  const [defaultPrice650ml, setDefaultPrice650ml] = useState(null);

  useEffect(() => {
    // Vérifier si l'utilisateur est connecté et est un administrateur
    if (!isLoggedIn || !isAdmin) {
      navigate("/");
    } else {
      fetchProducts();
    }
  }, [isAdmin, isLoggedIn, navigate]);

  const fetchProducts = async () => {
    // Votre logique de récupération des produits
    try {
      const response = await fetch(API_URLS.GET_PRODUCT_LIST);
      const data = await response.json();
      setProducts(data);
      setLoading(false);
      setDataFetched(true);

      // Trouver le produit avec une contenance de 660 ml (au lieu de 650 ml)
      const product660ml = data.find(
        (product) =>
          product.sizes &&
          product.sizes.some((size) => size.contenance === "660")
      );
      if (product660ml) {
        // Supposons que vous avez un produit avec une contenance de 660ml
        // Trouver le premier produit qui a cette contenance pour définir le prix par défaut
        const size660ml = product660ml.sizes.find(
          (size) => size.contenance === "660"
        );
        if (size660ml) {
          setDefaultPrice650ml(size660ml.price);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des produits :", error);
      setLoading(false);
      setError(true);
      setDataFetched(true);
    }
  };

  useEffect(() => {
    // Update the filtered products whenever searchTerm or categoryFilter changes
    const filtered = products.filter(
      (product) =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.category.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (categoryFilter !== "all") {
      setFilteredProducts(
        filtered.filter((product) => product.category === categoryFilter)
      );
    } else {
      setFilteredProducts(filtered);
    }
  }, [products, searchTerm, categoryFilter]);

  const handleDelete = (productId) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce produit ?"
    );
    if (confirmed) {
      // Faire une requête pour supprimer le produit.
      fetch(API_URLS.DELETE_PRODUCT(productId), {
        method: "DELETE",
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok) {
            // Si la réponse n'est pas ok, rejeter la promesse pour sauter au prochain catch
            return Promise.reject("Failed to delete product");
          }
          // Retourner une promesse résolue pour continuer la chaîne .then
          return Promise.resolve();
        })
        .then(() => {
          // Si la suppression a réussi, mettre à jour l'état local sans le produit supprimé.
          const updatedProducts = products.filter(
            (product) => product._id !== productId
          );
          setProducts(updatedProducts);
          setFilteredProducts(updatedProducts);
        })
        .catch((error) => {
          // Gérer les erreurs de fetch ou de suppression ici.
          console.error("Erreur lors de la suppression du produit :", error);
        });
    }
  };

  return (
    <Box p="4" m="5%" mt="10%">
      <Heading as="h1" size="lg" mb="4" color="white" textAlign="center">
        Liste des produits
      </Heading>
      <Box mb="4">
        <Input
          type="text"
          placeholder="Rechercher par titre ou catégorie"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb="2%"
        />
        <Select
          ml="4"
          w="200px"
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
        >
          <option value="all">Toutes les catégories</option>
          <option value="Emoti+">Emoti+</option>
          <option value="Winter">Winter</option>
          <option value="Summer">Summer</option>
          <option value="Relax+">Relax+</option>
          <option value="Clear+">Clear+</option>
          <option value="Christmas">Christmas</option>
          <option value="New">New</option>
        </Select>
      </Box>
      <Table>
        <Thead>
          <Tr>
            <Th>Photo</Th>
            <Th>Titre</Th>
            <Th>Prix</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dataFetched && loading ? (
            <Tr>
              <Td colSpan={4}>Loading....</Td>
            </Tr>
          ) : null}
          {dataFetched && error ? (
            <Tr>
              <Td colSpan={4}>Oops, impossible de récupérer les données</Td>
            </Tr>
          ) : null}
          {filteredProducts.map((product) => (
            <Tr key={product._id}>
              <Td>
                <Image
                  src={`${STATIC_URL}${product.mainImage}`}
                  boxSize="50px"
                  objectFit="cover"
                />
              </Td>
              <Td>{product.title}</Td>
              <Td>
                {product.sizes && product.sizes.length > 0
                  ? product.sizes.find((size) => size.contenance === "660")
                    ? product.sizes.find((size) => size.contenance === "660")
                        .price // Affiche le prix du produit de 660ml s'il existe
                    : product.sizes[0].price // Sinon, affiche le prix de la première taille disponible
                  : "No size yet"}
              </Td>
              <Td>
                <Link to={`/Admin/product/${product._id}`}>
                  <Button size="sm" background="#293d36" color="white">
                    Détails
                  </Button>
                </Link>
                <Button
                  size="sm"
                  background="#293d36"
                  color="white"
                  m="2%"
                  onClick={() => handleDelete(product._id)}
                >
                  Supprimer
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {selectedProduct && <ProductDetails product={selectedProduct} />}
    </Box>
  );
};

export default ProductsList;
