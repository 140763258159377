import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Box, Center } from '@chakra-ui/react';
import { Text } from '@react-three/drei';
import { NewtonsCradle } from '@uiball/loaders'

const LoadingPage = () => {
    return (
       
<Center
width="100%"
height="100vh"
>
        <NewtonsCradle 
         size={80}
         speed={1.4} 
         color="#293d36" 
        />
        </Center>
    )}

const Loader = () => {
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

    return (
        <div className="divLoader">
            {isLoading ? (
                <LoadingPage />
            ) : (
                <Suspense fallback={<LoadingPage />}>
                    {/* Your main content goes here */}
                </Suspense>
            )}
        </div>
    );
};

export default Loader;
