import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Link, Center } from "@chakra-ui/react";

const AdminHome = () => {

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || !isAdmin) {
      navigate('/'); // Rediriger vers la page d'accueil si l'utilisateur n'est pas admin ou non connecté
    }
  }, [isLoggedIn, isAdmin, navigate]);

  

  return (
    <Box p="4" mt="10%">
      <Center as="h1" size="xl" mb="5%" mt="5%" color="white" textAlign="center" fontSize="4xl"  >
        Welcome Admin!
      </Center>
      <Box display="flex" flexDirection="column" ml="2%" fontSize="2xl">
      <Link href="/Admin/ProductsList" color="#293d36" mb="2">
        Voir les articles
      </Link>
      <Link href="/Admin/ClientsList" color="#293d36" mb="2">
        Consulter les clients
      </Link>
      <Link href="/Admin/OrdersList" color="#293d36" mb="2">
        Consulter les commandes
      </Link>
      <Link href="/Admin/carrier" color="#293d36" mb="2">
        Consulter et créer frais de port
      </Link>
      <Link href="/Admin/promotionCode" color="#293d36" mb="2">
        Consulter et créer promotions codes
      </Link>
      <Link href="/Admin/AddProduct" color="#293d36" mb="4">
        Créer une fiche produit
      </Link>
      <Link href="/Admin/NewAdminUser" color="#293d36" mb="4">
        Créer un nouvel Admin
      </Link>
      <Link href="/Admin/reviews" color="#293d36" mb="4">
        Gérer les avis
      </Link>
      </Box>
      </Box>
  );
};

export default AdminHome;
