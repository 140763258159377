import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; // Importez les hooks nécessaires
import { setLoggedIn } from '../../slices_ReduxToolkit/userSlice.js';
import { useToast } from "@chakra-ui/react";
import { Helmet } from 'react-helmet';
import { API_URLS } from "../apiConfig.js";




import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { Link as RouteLink } from 'react-router-dom';
import { FaUserAlt, FaLock } from "react-icons/fa";
import { selectIsLoggedIn } from "../../slices_ReduxToolkit/userSlice.js";

function Connexion() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const CFaUserAlt = chakra(FaUserAlt);
  const CFaLock = chakra(FaLock);

  const handleShowClick = () => setShowPassword(!showPassword);
  const toast = useToast();

   // Utilisez useDispatch pour accéder au dispatch Redux
   const dispatch = useDispatch();
  
   // Utilisez useSelector pour accéder à l'état de Redux
   const isLoggedIn = useSelector(selectIsLoggedIn);
   

  const login = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(API_URLS.LOGIN_USER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: email, password }),
        credentials: 'include'
      });
  
      if (!response.ok) {
        let errorMessage = "";
        let errorTitle = "Erreur";
      
        if (response.status === 401) {
          errorMessage = "Email ou mot de passe invalide. Veuillez réessayer.";
        } else if (response.status === 500) {
          errorMessage = "Oups, un problème est survenu sur le serveur. Veuillez réessayer plus tard.";
        } else if (response.status === 404) {
          errorMessage = "Email ou mot de passe invalide. Veuillez réessayer.";
        } else if (response.status === 403) {
          errorMessage = "Email ou mot de passe invalide. Veuillez réessayer.";
        } else {
          errorMessage = "Une erreur est survenue. Veuillez réessayer.";
        }
      
        toast({
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      const data = await response.json();
     
  
      if (data.error) {
        toast({
          title: "Erreur",
          description: data.error,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    
      dispatch(setLoggedIn({ userId: data.userId,
        isAdmin: data.isAdmin}));
  
      toast({
        title: "Connexion réussie",
        description: "Vous êtes maintenant connecté(e).",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      // Récupérer l'URL de redirection ou utiliser une valeur par défaut
      const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';
      navigate(redirectUrl);
      localStorage.removeItem('redirectAfterLogin');
  
    } catch (error) {
      toast({
        title: "Erreur",
        description: "Un problème est survenu. Veuillez réessayer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      alignItems="center"
      mt={{base:"30%",md:"20%",xl:"10%"}}
    
    >
       <Helmet>
        <title>Connexion - Imagine Factory</title>
        <meta name="description" content="Connectez-vous pour accéder à votre compte et découvrir mes bougies parfumées." />
      </Helmet>
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
        position="absolute"
      >
        <Avatar bg="#293d36" />
        <Heading color="#293d36">Bienvenue</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form>
            <Stack
              spacing={4}
              p="1rem"
              boxShadow="md"
              borderRadius="xl"
              backgroundColor="#f3f6f4"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    borderColor="#293d36"
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input type="email" placeholder="Adresse mail" value={email} onChange={(e) => setEmail(e.target.value)} borderColor="#293d36" />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    borderColor="#293d36"
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText textAlign="right">
                  <Link as={RouteLink} to='/Forgotpassword' color="gray.700" href="#">Mot de passe oublié?</Link>
                </FormHelperText>
              </FormControl>
              <Button
                background="#98b29f"
                type="submit"
                variant="solid"
                width="full"
                onClick={login}
                color="#293d36"
                fontSize='120%'
              >
                Login
              </Button>
              {error && (
                <Text color="red" fontSize="sm" textAlign="center">
                  {error}
                </Text>
              )}
            </Stack>
          </form>
          <Text textAlign="center" mt="1%">Nouveau?{" "}
            <Link as={RouteLink} to='/checkout' color="gray.700" href="#">
              Créez un compte
            </Link></Text>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Connexion;