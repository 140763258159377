// import Island1 from './Island1.js'
import Cat from './Cat.js'
import FoxRock from './Fox_Rock'
import Hummingbird from './Hummingbird.js'
import { Canvas } from '@react-three/fiber'




export default function ExperienceMobile() {


  return (
    <>
        <Canvas>
        <ambientLight intensity={3} />
        <directionalLight color={0xFFFFFF} position={[6, 0, 5]} intensity={4} />
          <Hummingbird />
          <FoxRock />
          {/* <Island1 /> */}
          <Cat />
        </Canvas>
    </>
  )
}

