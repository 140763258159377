import React from 'react';
import { Canvas } from '@react-three/fiber';
import Experiencexl from './experience_xl/Experiencexl.js'
import ExperienceTablet from './experience_tablet/ExperienceTablet.js'
import ExperienceMobile from './experience_mobile/ExperienceMobile.js'
import ExperienceXXL from './experience_xxl/ExperienceXXL.js'


const MobileCanvas = () => {
  return (
    <Canvas>
      <ExperienceMobile/>
    </Canvas>
  );
};

const TabletCanvas = () => {
  return (
    <Canvas>
      <ExperienceTablet/>
    </Canvas>
  );
};

const DesktopCanvas = () => {
  return (
    <Canvas>
      <Experiencexl/>
    </Canvas>
  );
};

const DesktopXXLCanvas = () => {
  return (
    <Canvas>
      <ExperienceXXL/>
    </Canvas>
  );
};

export default function Experience ()  {
  const isMobile = window.matchMedia('(max-width: 767px)').matches;
  const isTablet = window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches;
  const isDesktop = window.matchMedia('(min-width: 1025px)and (max-width: 1280px)').matches;
  const isDesktopXXL = window.matchMedia('(min-width: 1281px)').matches;
  return (
    <>
      {isMobile && <ExperienceMobile />}
      {isTablet && <ExperienceTablet />}
      {isDesktop && <Experiencexl />}
      {isDesktopXXL && <ExperienceXXL/>}
    </>
  );
};

