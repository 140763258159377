import React, { useEffect } from "react";
import {
  Box,
  Image,
  Text,
  Button,
  Flex,
  useToast,
  Grid,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Loader from "../Loader.js";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../slices_ReduxToolkit/cartSlice.js";
import StarRating from "../article_page/RatingStars.js";
import { FaShoppingCart } from "react-icons/fa";
import { fetchArticles } from "../../slices_ReduxToolkit/articleSlice.js";
import EffetsMagiquesBanner from "./Flameus_intro.js";

const ArticleCard = React.memo(function ArticleCard({ article }) {
  const STATIC_URL =
    process.env.REACT_APP_STATIC_URL || "http://localhost:5000/uploads/";
  const dispatch = useDispatch();
  const imageUrl = `${STATIC_URL}${article.mainImage}`;
  const cartItems = useSelector((state) => state.cart.items);
  const toast = useToast();

  // Accéder au prix dans le premier élément de `sizes`
  const price =
    article.sizes && article.sizes.length > 0 ? article.sizes[0].price : "N/A";

  const handleAddToCart = () => {
    const selectedSize =
      article.sizes.find((size) => size.contenance === "650") ||
      article.sizes[0];
    const cartQuantity = cartItems
      .filter(
        (item) =>
          item._id === article._id && item.selectedSize === selectedSize._id
      )
      .reduce((sum, item) => sum + item.quantity, 0);

    const isStockAvailable = selectedSize.quantity > cartQuantity;

    if (isStockAvailable) {
      const item = {
        _id: article._id,
        name: article.title,
        price: selectedSize.price,
        mainImage: imageUrl,
        quantity: 1,
        selectedSize: selectedSize._id, // ID de la taille sélectionnée
        selectedContenance: selectedSize.contenance, // Contenance de la taille sélectionnée
        sizes: article.sizes, // Ajoutez cette ligne
      };
      dispatch(addItem(item));
      toast({
        position: "bottom",
        duration: 5000,
        isClosable: true,

        render: () => (
          <Box
            p="3"
            borderRadius="8px"
            border="2px solid #293d36"
            maxWidth="100%"
            width={["90%", "70%", "60%"]}
            display="flex"
            alignItems="center"
            fontFamily="Roboto"
            color="#293d36"
            background="white"
          >
            <Image
              src={imageUrl}
              width="50px"
              height="50px"
              mr="3"
              borderRadius="8px"
            />
            <Box>
              <Text fontWeight="bold" color="#293d36" mb="2%">
                {`${article.title} a été ajouté(e) au panier !`}
              </Text>

              <Text mb="2%">Votre voyage olfactif et magique commence !</Text>
              <Link
                to="/cart"
                style={{ textDecoration: "underline", color: "#293d36" }}
              >
                Aller au panier
              </Link>
            </Box>
          </Box>
        ),
      });
    } else {
      toast({
        title: "Oups, c'est trop !",
        description:
          "Vous tentez de commander plus que ce que nous avons en stock. Veuillez ajuster votre quantité.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const renderBenefitsIcons = () => {
    return article.benefits.map((benefit, index) => (
      <Tooltip
        label={benefitsDefinitions[benefit]}
        key={index}
        fontSize="md"
        hasArrow
      >
        <Image
          src={benefitsIcons[benefit]}
          boxSize={{ base: "30%", md: "35%", xl: "30%", "2xl": "25%" }}
          objectFit="contain"
          alt={benefit}
        />
      </Tooltip>
    ));
  };

  const benefitsDefinitions = {
    cocooning:
      "Cocooning :Pour amateurs de Chocolat chaud, chaussettes douillettes et feu de cheminée. Savourez votre instant cocooning! 🍵🔥✨",
    relax:
      "Relax:Comme une caresse sur l'âme.Un havre de paix.Favorise le repos et la relaxation.🕯️☮️😌🌸 ",
    focus:
      "Focus: Eveille votre génie intérieur. Stimule la concentration et la créativité. 🖌️📚🧠✨",
    emoti:
      "Emoti:Réchauffe l'esprit et apaise le cœur, comme une étreinte d'amour et de réconfort. 😊🌞🩷",
    invigorating:
      "Invigorating:Plongez dans un océan de fraîcheur!Imaginez une vague d'embruns vivifiants qui vous enveloppe! 🌊✨💦",
    boost:
      "Boost: Comme un espresso pour votre âme!Réveillez votre espace avec des notes énergisantes!☕⚡",
    meditation:
      "Meditate: Votre séance de méditation avec une touche parfumée pour atteindre le nirvana.🕉️✨",
    festive:
      "Festive: La fête dans un pot!Parfaite pour vos célébrations!🎉🎈",
    new: "New:Nouvelle création! 🌟🎇✨",
    romantic:
      "Romantic: Pour des soirées à deux.Amour, lumière tamisée et magie dans l'air!🌹🥂 ",

    // autres bénéfices...
  };

  const benefitsIcons = {
    emoti: "/svg/emoti_icone.svg",
    relax: "/svg/relax_icone.svg",
    new: "/Icones/new.svg",
    cocooning: "/svg/cocooning_icone1.svg",
    boost: "/svg/boost_icone.svg",
    romantic: "/svg/romantic_icone.svg",
    invigorating: "/svg/invigorating_icone.svg",
    meditation: "/svg/meditate_icone.svg",
    festive: "/svg/festive_icone.svg",
    focus: "/svg/focus_icone.svg",

    // autres bénéfices...
  };

  return (
    <Box
      borderRadius="lg"
      overflow="hidden"
      shadow="md"
      bgColor="#dce5df"
      width="full"
      align="center"
      p="4"
      d="flex"
      flexDirection="column"
      h="100%"
      position="relative"
    >
      <Link to={`/article/${article._id}`}>
        {" "}
        <Box
          height={{ base: "150px", md: "200px" }}
          width="100%"
          mb="4"
          overflow="hidden"
        >
          <Image
            src={`${STATIC_URL}${article.mainImage}`}
            alt={article.title}
            width="100%"
            height="100%"
            objectFit="contain"
            borderRadius="xl"
          />
          {article.benefits && article.benefits.length > 0 && (
            <HStack position="absolute" top="10px" left="10px">
              {renderBenefitsIcons()}
            </HStack>
          )}
        </Box>
        <Box mt="2" flex="1" maxH="100px" overflow="hidden">
          <Text
            noOfLines="2"
            fontSize={{ base: "md", md: "lg" }}
            color="293d36"
          >
            {" "}
            Bougie parfumée
          </Text>
        </Box>
        <Box>
          <Text>{article.title}</Text>
          <Text noOfLines={1} color="293d36">
            {article.underTitle}{" "}
          </Text>
        </Box>
      </Link>
      <Text mt={2} fontWeight="bold" color="293d36">
        {price}€
      </Text>

      {/* Affichage de la note moyenne et du nombre total d'avis */}
      <Flex justifyContent="center" mt={2}>
        <StarRating rating={article.averageRating} />
        <Text fontSize={{ base: "xs", md: "sm" }} color="293d36">
          ({article.totalReviews} Avis)
        </Text>
      </Flex>

      <Flex m="4" gap="10%" justifyContent="center">
        <Button
          variant="solid"
          background="#98b29f"
          onClick={handleAddToCart}
          leftIcon={<FaShoppingCart />}
          aria-label="Ajouter au panier"
          width="60%"
        />
        <Link to={`/article/${article._id}`}>
          {" "}
          <Button variant="solid" background="#98b29f">
            Voir
          </Button>
        </Link>
      </Flex>
    </Box>
  );
});

export default function ArticleCarousel() {
  const dispatch = useDispatch();
  const toast = useToast();
  const articles = useSelector((state) => state.articles.items);
  const selectedCategory = useSelector(
    (state) => state.articles.selectedCategory
  );
  const loading = useSelector((state) => state.articles.loading);

  useEffect(() => {
    dispatch(fetchArticles());
  }, [dispatch]);

  let filteredArticles = articles;

  // Logique modifiée pour gérer les catégories vides
  if (selectedCategory) {
    filteredArticles = articles.filter((article) =>
      article.category.includes(selectedCategory)
    );

    if (filteredArticles.length === 0) {
      toast({
        title: "Aucun article trouvé !",
        description: `Nous n'avons actuellement aucune bougie dans la catégorie "${selectedCategory}". Mais découvrez vite nos autres destinations olfactives!`,
        status: "info",
        duration: 5000,
        isClosable: true,
      });

      // Réinitialisation pour afficher tous les articles
      filteredArticles = articles;
    }
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
    <Box mt="2%">
    <EffetsMagiquesBanner />
    </Box>
    <Grid
      mt={{ md: "0%", lg: "0%", xl: "3%", "2xl": "3%" }}
      ml={{ base: "0%", md: "3%", lg: "0%", xl: "8%", "2xl": "8%" }}
      mr={{ base: "0%", md: "3%", lg: "0%", xl: "8%", "2xl": "8%" }}
      py="4"
      px={{ base: "4", md: "6", lg: "8" }}
      templateColumns={{
        base: "repeat(2, 1fr)",
        md: "repeat(3, 1fr)",
        lg: "repeat(4, 1fr)",
        xl: "repeat(4, 1fr)",
        "2xl": "repeat(5, 1fr)",
      }}
      gap="6"
      autoRows="1fr"
    >
      {filteredArticles.map((article) => (
        <ArticleCard key={article._id} article={article} />
      ))}
    </Grid>
    </>
  );
}
