import React, { useEffect } from "react";
import { Routes, Route,Link, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home.js";
import Menu from "./Components/Menu/Menu.js";
import IconCart from "./Components/ShoppingCart/Icon.js";
import SubscribeNewsletter from "./Components/SubscriptionNewsletter/SubscribeNewsletter.js";
import Cart from "./Components/ShoppingCart/Cart.js";
import ArticlePage from "./Components/article_page/ArticlePage.js";
import NotFound from "./Components/NotFound.js";
// import MousePointer from "./Components/MousePointer.js";
import AddProduct from "./Components/admin/AddProduct.js";
import ProductsList from "./Components/admin/ProductsList.js";
import ProductDetails from "./Components/admin/ProductDetails.js";
import NewAdminUser from "./Components/admin/NewAdminUser.js";
import ClientsList from "./Components/admin/ClientsList.js";
import AdminHome from "./Components/admin/AdminHome.js";
import MonCompte from "./Pages/MonCompte/MonCompte.js";
import ClientInformations from "./Pages/MonCompte/ClientInformations.js";
import ClientOrders from "./Pages/MonCompte/ClientOrders.js";
import OrderSuccess from "./Pages/Checkout/order_conf/OrderSuccess.js";
import OrderFailed from "./Pages/Checkout/order_conf/OrderFailed.js";
import Connexion from "./Components/Connexion/Connexion.js";
import ForgotPassword from "./Components/forgotPassword.js";
import DeliveryAddress from "./Pages/Checkout/checkAddress.js";
import StepOne from "./Pages/Checkout/StepOne.js";
import StepTwo from "./Pages/Checkout/StepTwo.js";
import StepThree from "./Pages/Checkout/StepThree.js";
import { useDispatch } from "react-redux";
import { initializeCart } from "./slices_ReduxToolkit/cartSlice.js";
import { setLoggedIn } from "./slices_ReduxToolkit/userSlice.js";
import ClientDetails from "./Components/admin/ClientDetails.js";
import CarrierPriceManagement from "./Components/admin/CarrierPriceManagement.js";
import { Provider } from 'react-redux'; 
import { store } from "./store.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ReviewsManager from "./Components/admin/manageReviews.js";
import ResetPassword from "./Components/resetPassword.js";
import AdminPromotionCodes  from "./Components/admin/managePromotionCode.js"
import AdminOrders from "./Components/admin/manageOrders.js";
import { Helmet } from 'react-helmet';
import GoogleTranslateDropdown from "./Components/Traduction_menu.js"
import {Box, Image} from "@chakra-ui/react";
import { API_URLS } from "./Components/apiConfig.js";



export default function App() {

 
  const dispatch = useDispatch();
  const stripePromise = loadStripe ('process.env.REACT_APP_STRIPE_PUBLIC_KEY');
  const location = useLocation();
  
  useEffect(() => {
    // Initialise le panier à partir du localStorage
    dispatch(initializeCart());
  }, [dispatch]);

  useEffect(() => {
    if (window._paq) {
      window._paq.push(['setCustomUrl', window.location.pathname + window.location.search]);
      window._paq.push(['trackPageView']);
    }
  }, [location]);

  useEffect(() => {
    // Vérifier l'état de la session lorsque l'application charge
    fetch(API_URLS.CHECK_SESSION, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          dispatch(
            setLoggedIn({
              userId: data.userId,
              isAdmin: data.isAdmin,
              country: data.country,
              shippingCountry: data.shippingCountry,
            })
          );
        }
      })
      .catch((error) => {
        console.error("Failed to verify session:", error);
      });
  }, [dispatch]);

  return (
    <Provider store={store}> 
    <Elements stripe={stripePromise}>
    <>
    <Helmet>
        <title>Bougies parfumées Imagine Factory - Explorez ma collection de bougies créatives et eco-friendly! </title>
        <meta name="description" content="Une gamme unique de bougies créatives au format XXL." />
        <meta name="keywords" content="bougies,bougies naturelles, bougies créatives, bougies XXL, décoration, cadeaux, candles, scented candles, cire coco, cire colza, cire naturelle, mêche bois, mêche crépitante" />
        {/* Autres métadonnées globales */}
      </Helmet>
      <Menu />
      <Link to="/cart">
        <IconCart />
      </Link>
      <GoogleTranslateDropdown/>


      <Link to="/">
            <Box
            zIndex="1"
              position="absolute"
              id="logoImagineFactory"
              width={{ base: "30%", md: "15%",lg:"10%", xl: "10%" }}
              ml={{ base: "35%", md: "45%",lg:"45%", xl: "45%" }}
              mt={{ base: "2%",  md: "1%",lg:"0", xl: "0" }}
            >
              <Image src="/svg/logo_sans_fond.svg" alt="logo" />
            </Box>
          </Link>


      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/newsletter" element={<SubscribeNewsletter />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/article/:id" element={<ArticlePage />} />
        <Route path="/Connexion" element={<Connexion />} />
        <Route path="/Forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />


        <Route path="/checkAddress" element={<DeliveryAddress />} />
        <Route path="/subscription" element={<StepOne />} />
        <Route path="/delivery" element={<StepTwo />} />
        <Route path="/payment" element={<StepThree />} />
        <Route path="/success" element={<OrderSuccess />} />
        <Route path="/failed" element={<OrderFailed />} />



        
        <Route path="/myAccount" element={<MonCompte />} />
        <Route path="/ClientOrders" element={<ClientOrders />} />
        <Route path="/ClientInformations" element={<ClientInformations />} />
        <Route path="/checkout-success" element={<OrderSuccess />} />

        
        <Route path="/Admin/ProductsList" element={<ProductsList />} />
        <Route path="/Admin/AddProduct" element={<AddProduct />} />
        <Route path="/Admin/NewAdminUser" element={<NewAdminUser />} />
        <Route path="/Admin/ClientsList" element={<ClientsList />} />
        <Route path="/Admin/AdminHome" element={<AdminHome />} />
        <Route path="/Admin/OrdersList" element={<AdminOrders />} />
        <Route path="/Admin/product/:id" element={<ProductDetails />} />
        <Route path="/Admin/users/:userId" element={<ClientDetails />} />
        <Route path="/Admin/carrier" element={<CarrierPriceManagement />} />
        <Route path="/Admin/reviews" element={<ReviewsManager />} />
        <Route path="/Admin/promotionCode" element={<AdminPromotionCodes />} />



        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
    </Elements>
    </Provider>
  );
}
