import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Flex,
  Heading,
  Center,
  useToast,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Helmet } from 'react-helmet';
import { API_URLS } from "./apiConfig.js";



const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('token');
  const toast = useToast();

  const validatePassword = (password) => {
    return /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validatePassword(password)) {
      toast({
        title: 'Mot de passe invalide',
      description: 'Le mot de passe doit contenir au moins 8 caractères, un chiffre et un caractère spécial',
      status: 'error',
      duration: 5000,
      isClosable: true
      });
      return;
    }
  
    if (password !== confirmPassword) {
      toast({
        title: 'Mots de passe non identiques',
        description: 'Les mots de passe ne correspondent pas',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }
    
   
    try {
        
      const response = await fetch(API_URLS.RESET_PASSWORD, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: resetToken, password }),
      });
  
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to reset password');
          }
    
  
      toast({
        title: 'Réinitialisation réussie',
        description: 'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
  
      // Redirection ou autre action après la réussite
    
    } catch (error) {
      toast({
        title: 'Erreur',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom'
      });
    }
  };
  return (
    <Flex flexDirection="column" width="100%" height="100vh" alignItems="center" justifyContent="center">
       <Helmet>
        <title>Réinitialisation du Mot de Passe | Imagine Factory</title>
        <meta name="description" content="Entrez votre nouveau mot de passe pour réinitialiser votre compte." />
      </Helmet>
      <Stack flexDir="column" justifyContent="center" alignItems="center" spacing={4} p="1rem" backgroundColor="#f3f6f4" boxShadow="md" borderRadius="xl" maxWidth="500px">
        <Heading color="#293d26" fontSize={'xl'}>Réinitialisez votre mot de passe</Heading>
        <Box mx="auto">
          <form onSubmit={handleSubmit}>
            <FormControl id="password" isRequired>
              <FormLabel>Nouveau mot de passe</FormLabel>
              <InputGroup>
                <Input type={showPassword ? 'text' : 'password'} placeholder="New Password" onChange={(e) => setPassword(e.target.value)} />
                <InputRightElement onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="confirmPassword" isRequired mt={4}>
              <FormLabel>Confirmez le nouveau mot de passe</FormLabel>
              <InputGroup>
                <Input type={showPassword ? 'text' : 'password'} placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                <InputRightElement onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Center>
              <Button type="submit" mt="4" backgroundColor="#98b29f" color="#293d36">
                Mettre à jour
              </Button>
            </Center>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default ResetPassword;
