import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLoggedIn } from "../../slices_ReduxToolkit/userSlice.js"
import { Helmet } from 'react-helmet';

import { useNavigate } from "react-router-dom";
import {
  Center, Button, FormControl, Input,
  Radio, RadioGroup, Stack, Text,
  Alert, AlertIcon, Box,Flex,
} from "@chakra-ui/react";
import Loader from "../../Components/Loader.js"
import { API_URLS } from "../../Components/apiConfig.js";


function DeliveryAddress() {
  // Déclarations des états et autres
  const userId = useSelector((state) => state.user.userId);
  const [selectedOption, setSelectedOption] = useState("previous");
  const [previousAddress, setPreviousAddress] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [newAddress, setNewAddress] = useState({
    shippingFirstName: "", shippingLastName: "", shippingCountry: "",
    shippingAddress: "", shippingAdditiveAddress: "", shippingCity: "",
    shippingState: "", shippingZipcode: "", shippingPhone: ""
  });
// Utilisez useSelector pour accéder à l'état de Redux
const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const updateAddressField = (field, value) => {
    setNewAddress((prevAddress) => ({ ...prevAddress, [field]: value }));
  };
    
  useEffect(() => {
    // Vérifier l'état de la session lorsque le composant charge sinon le composant ne s'affichera pas
    fetch(API_URLS.CHECK_SESSION, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isLoggedIn) {
          dispatch(
            setLoggedIn({
              userId: data.userId,
              isAdmin: data.isAdmin,
              country: data.country,
              shippingCountry: data.shippingCountry,
            }));
          } else {
            navigate("/subscription"); // Redirection si l'utilisateur n'est pas connecté
          }
        })
        .catch((error) => {
          console.error("Failed to verify session:", error);
          navigate("/subscription"); // Redirection en cas d'erreur de vérification de session
        });
      }, [dispatch, navigate]);

  // Obtient l'adresse précédente de l'utilisateur
   useEffect(() => {
    setIsLoading(true); // Activer le loader
    fetch(API_URLS.GET_USER_ADDRESS, { method: "GET", credentials: "include" })
      .then((response) => {
        if (response.status === 401) {
          // Gérer la redirection ou la mise à jour de l'état ici
          navigate("/Connexion");
          throw new Error("Unauthorized");
        }
        if (!response.ok) throw new Error("Failed to fetch address.");
        return response.json();
      })
      .then((data) => {
        setPreviousAddress(data);
        setIsLoading(false); // Désactiver le loader une fois les données chargées
      })
      .catch((error) => {
        setError("An error occurred while fetching the address.");
        setIsLoading(false); // Désactiver le loader en cas d'erreur
      });
    }, [userId, navigate]);

    
   // Récupérer l'adresse du localStorage s'il y en a une.
   useEffect(() => {
    const storedAddress = localStorage.getItem('newAddress');
    if (storedAddress) {
      setNewAddress(prevAddress => ({
        ...prevAddress,
        ...JSON.parse(storedAddress)
      }));
    }
  }, []);

   // Fonction pour vérifier si le champ est vide ou contient seulement des espaces
   const isFieldEmpty = (field) => !field || !field.trim();
  
  // Vérifie si un des champs obligatoires est vide
  const isAnyRequiredFieldEmpty = () => {
    const { shippingFirstName, shippingLastName, shippingCountry, shippingAddress, shippingCity, shippingPhone } = newAddress;
    return (
      [shippingFirstName, shippingLastName, shippingCountry, shippingAddress, shippingCity, shippingPhone].some(isFieldEmpty)
    );
  };

 
  // Fonction pour gérer le clic sur "Next"
  const handleNextClick = () => {
    if (!isLoggedIn) {
      navigate("/subscription"); // Redirection si l'utilisateur n'est pas connecté
      return;
    }
    setError(null);
  
    if (selectedOption === "new" && isAnyRequiredFieldEmpty()) {
      setError("Please fill out all required fields.");
      return;
    }
  
    if (selectedOption === "new") {
      // Stockage de l'adresse dans le localStorage
      localStorage.setItem('newAddress', JSON.stringify(newAddress));
      updateAddressAndMoveNext();
    } else {
      // Si l'option sélectionnée n'est pas 'new', on stocke l'adresse précédente dans le localStorage
      localStorage.setItem('newAddress', JSON.stringify(previousAddress));
      navigate("/delivery");
    }
  };
  
  // Met à jour l'adresse et passe à l'étape suivante
  const updateAddressAndMoveNext = () => {
    fetch(API_URLS.UPDATE_USER_SHIPPING_ADDRESS(userId), {
      method: "PUT",
      credentials: "include",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(newAddress),
    })
    .then((response) => {
      if (!response.ok) throw new Error("Failed to update address.");
      return response.json();
    })
    .then((data) => {
      navigate("/delivery");
    })
    .catch((error) => console.error("Error updating address:", error));
  };
  

  // Fonction de rendu d'adresse (pour éviter la répétition dans le JSX)
  const renderAddress = (address) => (
    <Box ml="2%">
      <Text>{`${address.firstName} ${address.lastName}`}</Text>
      <Text>{address.address}</Text>
      {address.additiveAddress && <Text>{address.additiveAddress}</Text>}
      <Text>{`${address.zipcode} ${address.city}`}</Text>
      <Text>{address.country}</Text>
      <Text>{address.email}</Text>
      <Text>{address.phone}</Text>
    </Box>
  );


  return (
    <Flex direction="column" alignItems="center" mt={{ base: "30%",md:"15%",xl:"10% "}} mb="5%"  position="relative"
    zIndex="2"> 
     <Helmet>
        <title>Vérification d'informations | Imagine Factory</title>
        <meta name="description" content="Confirmez vos informations personnelles pour poursuivre avec votre commande." />
      </Helmet>     
      <Box rounded="lg" bg="white" boxShadow="lg" p="4" mt="2%">
       <Center mt="5%" flexDir="column" w="full" alignItems="center">
        <RadioGroup onChange={setSelectedOption} value={selectedOption} colorScheme="green">
        <Stack direction="column">
          <Radio value="previous" colorScheme="green">
            <Text mb="3%" color="#293d36" fontSize="lg" fontWeight="bold">Utiliser ces informations :</Text>
            {isLoading && <Loader />}
            {error && (
  <Alert status="error" mt="4">
    <AlertIcon />
    {error}
  </Alert>
)}
          </Radio>
          {previousAddress ? (
            <>
              {renderAddress(previousAddress)}
            </>
          ) : (
            <Text>Pas d'adresse disponible</Text>
          )}

          <Text mt="20px" color="#293d36" fontSize="lg" fontWeight="bold">OU</Text>
          <Radio value="new" >
          <Text mb="3%" color="#293d36" fontSize="lg" fontWeight="bold">Votre commande est un cadeau? Livrer à une autre adresse </Text>
          </Radio>
          {selectedOption === "new" && (
            <FormControl mt="10px" >
              <Stack direction="column" spacing={3}>
                <Input
                  placeholder="Prénom"
                  value={newAddress.shippingFirstName}
                  onChange={(e) => updateAddressField('shippingFirstName', e.target.value)}
                  isInvalid={
                    selectedOption === "new" &&
                    isFieldEmpty(newAddress.shippingFirstName)
                  }
                  focusBorderColor="#293d36"
                />
                {selectedOption === "new" &&
                  isFieldEmpty(newAddress.shippingFirstName) && (
                    <Text color="red.500" fontSize="sm">
                      Ce champ est requis
                    </Text>
                  )}
                <Input
                  placeholder="Nom"
                  focusBorderColor="#293d36"
                  value={newAddress.shippingLastName}
                  onChange={(e) =>
                    setNewAddress({
                      ...newAddress,
                      shippingLastName: e.target.value,
                    })
                  }
                  isInvalid={
                    selectedOption === "new" &&
                    isFieldEmpty(newAddress.shippingLastName)
                  }
                />
                {selectedOption === "new" &&
                  isFieldEmpty(newAddress.shippingLastName) && (
                    <Text color="red.500" fontSize="sm">
                      Ce champ est requis
                    </Text>
                  )}
                <Input
                  placeholder="Adresse"
                  focusBorderColor="#293d36"
                  value={newAddress.shippingAddress}
                  onChange={(e) =>
                    setNewAddress({
                      ...newAddress,
                      shippingAddress: e.target.value,
                    })
                  }
                  isInvalid={
                    selectedOption === "new" &&
                    isFieldEmpty(newAddress.shippingAddress)
                  }
                />
                {selectedOption === "new" &&
                  isFieldEmpty(newAddress.shippingAddress) && (
                    <Text color="red.500" fontSize="sm">
                      Ce champ est requis
                    </Text>
                  )}
                <Input
                  placeholder="Complément d'adresse"
                  focusBorderColor="#293d36"
                  value={newAddress.shippingAdditiveAddress}
                  onChange={(e) =>
                    setNewAddress({
                      ...newAddress,
                      shippingAdditiveAddress: e.target.value,
                    })
                  }
                />
                <Input
                  placeholder="Ville"
                  focusBorderColor="#293d36"
                  value={newAddress.shippingCity}
                  onChange={(e) =>
                    setNewAddress({
                      ...newAddress,
                      shippingCity: e.target.value,
                    })
                  }
                  isInvalid={
                    selectedOption === "new" &&
                    isFieldEmpty(newAddress.shippingCity)
                  }
                />
                {selectedOption === "new" &&
                  isFieldEmpty(newAddress.shippingCity) && (
                    <Text color="red.500" fontSize="sm">
                      Ce champ est requis
                    </Text>
                  )}
                <Input
                  placeholder="Région"
                  focusBorderColor="#293d36"
                  value={newAddress.shippingState}
                  onChange={(e) =>
                    setNewAddress({
                      ...newAddress,
                      shippingState: e.target.value,
                    })
                  }
                />
                <Input
                  placeholder="Code postal"
                  focusBorderColor="#293d36"
                  value={newAddress.shippingZipcode}
                  onChange={(e) =>
                    setNewAddress({
                      ...newAddress,
                      shippingZipcode: e.target.value,
                    })
                  }
                  isInvalid={
                    selectedOption === "new" &&
                    isFieldEmpty(newAddress.shippingZipcode)
                  }
                />
                {selectedOption === "new" &&
                  isFieldEmpty(newAddress.shippingZipcode) && (
                    <Text color="red.500" fontSize="sm">
                      Ce champ est requis
                    </Text>
                  )}
                <Input
                  placeholder="Pays"
                  focusBorderColor="#293d36"
                  value={newAddress.shippingCountry}
                  onChange={(e) =>
                    setNewAddress({
                      ...newAddress,
                      shippingCountry: e.target.value,
                    })
                  }
                  isInvalid={
                    selectedOption === "new" &&
                    isFieldEmpty(newAddress.shippingCountry)
                  }
                />
                {selectedOption === "new" &&
                  isFieldEmpty(newAddress.shippingCountry) && (
                    <Text color="red.500" fontSize="sm">
                      Ce champ est requis
                    </Text>
                  )}
                <Input
                  placeholder="Téléphone"
                  focusBorderColor="#293d36"
                  value={newAddress.shippingPhone}
                  onChange={(e) =>
                    setNewAddress({
                      ...newAddress,
                      shippingPhone: e.target.value,
                    })
                  }
                  isInvalid={
                    selectedOption === "new" &&
                    isFieldEmpty(newAddress.shippingPhone)
                  }
                />
                {selectedOption === "new" &&
                  isFieldEmpty(newAddress.shippingPhone) && (
                    <Text color="red.500" fontSize="sm">
                      Ce champ est requis
                    </Text>
                  )}
              </Stack>
            </FormControl>
          )}
          {error && (
            <Alert status="error" mt="4">
              <AlertIcon />
              {error}
            </Alert>
          )}
        </Stack>
      </RadioGroup>
      <Button 
          bg="#98b29f"
          color="#293d36"
          borderColor="#293d36"
          mt="6%"
          mb="2%"
          ml="3%" 
          onClick={handleNextClick}>
        Suivant
      </Button>
    </Center>
    </Box>
    </Flex>
  );
}

export default DeliveryAddress;
