import React, { useEffect, useRef, useState } from "react";
import { Box, Text, Image, Tooltip } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setSelectedCategory } from "../../slices_ReduxToolkit/articleSlice.js";

function MobileWelcomeComponent({ onCategorySelect, carouselRef }) {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef();
  const [isScrollingEnabled, setIsScrollingEnabled] = useState(true);

  const handleCategorySelect = (category) => {
    dispatch(setSelectedCategory(category));
  
    // désactive l'auto-défilement pour permettre au défilement manuel de prendre le dessus
    setIsScrollingEnabled(false);
    setTimeout(() => {
      if (carouselRef && carouselRef.current) {
        carouselRef.current.scrollIntoView({ behavior: "smooth" });
      }
      // Réactive l'auto-défilement après un délai pour permettre au défilement manuel de se terminer
      setTimeout(() => setIsScrollingEnabled(true), 1000); // Augmentez le délai si nécessaire
    }, 100); // délai pour UI prête
  
    if (onCategorySelect) {
      onCategorySelect(category);
    }
  };

  useEffect(() => {
    const handleTouchStart = () => setIsScrollingEnabled(false);
    const handleTouchEnd = () => setIsScrollingEnabled(true);

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("touchstart", handleTouchStart);
      scrollContainer.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("touchstart", handleTouchStart);
        scrollContainer.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isScrollingEnabled) {
      interval = setInterval(() => {
        if (scrollContainerRef.current) {
          let newPosition = scrollContainerRef.current.scrollLeft + 1;
          const contentWidth = scrollContainerRef.current.scrollWidth / 2;
          if (newPosition >= contentWidth) {
            scrollContainerRef.current.scrollLeft = 0;
          } else {
            scrollContainerRef.current.scrollLeft = newPosition;
          }
        }
      }, 50);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isScrollingEnabled]);

  return (
    <Box display={{ base: "block", lg: "none" }}>
      <Box>
        <Text
          mb="15%"
          mt="-20%"
          textAlign="center"
          fontFamily="Roboto"
          fontWeight="bold"
          fontSize="md"
          color="#293d36"
        >
          ✨Choisissez votre destination olfactive🚀
        </Text>
      </Box>
      <Box
        overflowX="scroll"
        whiteSpace="nowrap"
        ref={scrollContainerRef}
        mt="-15%"
        backgroundColor="#293d36"
      >
        <MobileCategoryIcon
          src="./svg/icone_clear_map.svg"
          label="Fresh"
          tooltipLabel="Neutralise les mauvaises odeurs, good smell & good vibes only! 🕯️🌬️🌸✨"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <MobileCategoryIcon
          src="./svg/icone_fruity_map.svg"
          label="Fruity"
          tooltipLabel="Comme un panier de fruits tout droit rapporté du marché, bougie ultra veggie! 🍓🍋🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <MobileCategoryIcon
          src="./svg/icone_sweet_map.svg"
          label="Gourmand"
          tooltipLabel="Bougie parfumée pour les grands gourmands, même au régime. Succombez à leurs parfums sans prendre un gramme ! 🧁🍪🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <MobileCategoryIcon
          src="./svg/icone_fleurs_map.svg"
          label="Floral"
          tooltipLabel="Éclosion de senteurs, entourez-vous de la délicate essence des fleurs, un véritable jardin de fragrances envoûtantes! 🌸🌹🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <MobileCategoryIcon
          src="./svg/icone_summer_map.svg"
          label="Summer"
          tooltipLabel="Mojito en main 🍹, tongues aux pieds 👣 : Mode vacances enclenché! 🌞🌊🏖️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <MobileCategoryIcon
          src="./svg/woody_icone_map.svg"
          label="Woody"
          tooltipLabel="S'immerger dans une forêt ancienne, où chaque bouffée est une étreinte de la nature,fragrances boisées. 🌲🍂🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <MobileCategoryIcon
          src="./svg/green_icone_map.svg"
          label="Green"
          tooltipLabel="Une bouffée d'air frais, un réveil dans le jardin d'Éden. 🌿🍃🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <MobileCategoryIcon
          src="./svg/spicy_icone_map.svg"
          label="Spicy"
          tooltipLabel="Chaleur et caractère dans chaque flamme, un voyage épicé aux quatre coins du monde. 🌶️🔥🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <MobileCategoryIcon
          src="./svg/icone_toutMobile.svg"
          label="Voir tout"
          tooltipLabel="Découvrez l'ensemble des bougies parfumées!🍃✨"
          left={{ xl: "80%", "2xl": "85%" }}
          top={{ xl: "10%", "2xl": "60%" }}
          onCategorySelect={() => handleCategorySelect(null)} // Réinitialise la sélection
        />

        <MobileCategoryIcon
          src="./svg/icone_clear_map.svg"
          label="Fresh"
          tooltipLabel="Neutralise les mauvaises odeurs, good smell & good vibes only! 🕯️🌬️🌸✨"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <MobileCategoryIcon
          src="./svg/icone_fruity_map.svg"
          label="Fruity"
          tooltipLabel="Comme un panier de fruits tout droit rapporté du marché, bougie ultra veggie! 🍓🍋🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <MobileCategoryIcon
          src="./svg/icone_sweet_map.svg"
          label="Gourmand"
          tooltipLabel="Bougie parfumée pour les grands gourmands, même au régime. Succombez à leurs parfums sans prendre un gramme ! 🧁🍪🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <MobileCategoryIcon
          src="./svg/icone_fleurs_map.svg"
          label="Floral"
          tooltipLabel="Éclosion de senteurs, entourez-vous de la délicate essence des fleurs, un véritable jardin de fragrances envoûtantes! 🌸🌹🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <MobileCategoryIcon
          src="./svg/icone_summer_map.svg"
          label="Summer"
          tooltipLabel="Mojito en main 🍹, tongues aux pieds 👣 : Mode vacances enclenché! 🌞🌊🏖️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <MobileCategoryIcon
          src="./svg/woody_icone_map.svg"
          label="Woody"
          tooltipLabel="S'immerger dans une forêt ancienne, où chaque bouffée est une étreinte de la nature,fragrances boisées. 🌲🍂🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <MobileCategoryIcon
          src="./svg/green_icone_map.svg"
          label="Green"
          tooltipLabel="Une bouffée d'air frais, un réveil dans le jardin d'Éden. 🌿🍃🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <MobileCategoryIcon
          src="./svg/spicy_icone_map.svg"
          label="Spicy"
          tooltipLabel="Chaleur et caractère dans chaque flamme, un voyage épicé aux quatre coins du monde. 🌶️🔥🕯️"
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <MobileCategoryIcon
          src="./svg/icone_toutMobile.svg"
          label="Voir tout"
          tooltipLabel="Découvrez l'ensemble des bougies parfumées!🍃✨"
          left={{ xl: "80%", "2xl": "85%" }}
          top={{ xl: "10%", "2xl": "60%" }}
          onCategorySelect={() => handleCategorySelect(null)} // Réinitialise la sélection
        />
      </Box>
    </Box>
  );
}

function MobileCategoryIcon({ src, label, onCategorySelect, tooltipLabel }) {
  return (
    <Tooltip
      label={tooltipLabel}
      aria-label={`Tooltip pour ${label}`}
      placement="top"
      hasArrow
      fontSize="md"
    >
      <Box
        display="inline-block"
        cursor="pointer"
        textAlign="center"
        onClick={() => onCategorySelect(label)}
        m="2"
      >
        <Image
          src={src}
          boxSize="40px"
          _hover={{ transform: "scale(1.2)" }}
          m="auto"
          display="block"
        />
        <Text
          mt="2"
          fontSize="xs"
          fontWeight="bold"
          borderRadius="xl"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          p="2"
        >
          {label}
        </Text>
      </Box>
    </Tooltip>
  );
}

export default MobileWelcomeComponent;
