import {useGLTF } from '@react-three/drei'

function FoxRock() {
const foxRock = useGLTF ('./GLTF/fox_rock.glb')

return (
 
<primitive object={foxRock.scene} scale={1} position-y={-2.1} position-z={0.5} />

  )
  
}
export default FoxRock