import { Link } from "react-router-dom";
import { Box, HStack, Text,Center } from "@chakra-ui/react";
import { IoIosHome } from 'react-icons/io';
import { Helmet } from 'react-helmet';



function NotFound() {
    return (
        <Box
            width='100%'
            height='100vh'
            mt='20%'
            fontFamily={'Roboto'}
            color='#f3f6f4'
        >
             <Helmet>
                <title>Page Non Trouvée | Imagine Factory</title>
                <meta name="description" content="La page que vous recherchez semble introuvable. Essayez de retourner à la page d'accueil." />
            </Helmet>

            <Box textAlign='center'>
            <Text fontSize='8xl'>Oups ! Vous semblez être perdu(e).</Text>
            <Text fontSize='4xl'>Voici quelques liens utiles :</Text>
            </Box>
            <Center>
            <HStack mt='3%' ml='15%' as='u'>
                                <IoIosHome size='10%' />
                <Text as={Link}to='/' color= '#f3f6f4' fontSize='3xl'  > Home </Text>
            </HStack>
            </Center>
        </Box>
    )
}

export default NotFound