import React from "react";
import { Image, Text, Box, Tooltip } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setSelectedCategory } from "../../slices_ReduxToolkit/articleSlice.js";

function WelcomeComponent({ carouselRef, onCategorySelect }) {
  const dispatch = useDispatch();

  const handleCategorySelect = (category) => {
    dispatch(setSelectedCategory(category));
    console.log(`Category selected: ${category}`);
  
  
    setTimeout(() => {
      if (carouselRef.current) {
        console.log("Scrolling to carousel");
        carouselRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Un délai léger pour s'assurer que toutes les mises à jour de l'UI sont terminées
  
    
    if (onCategorySelect) {
      onCategorySelect(category);
    }
  };
  

  return (
    <Box
      display={{ base: "none", lg: "block" }}
      borderRadius="xl"
      ml={{ xl: "66%", "2xl": "66%" }}
      mt={{ xl: "-60%", "2xl": "-40%" }}
      width={{ xl: "33%", "2xl": "28%" }}
      height={{ xl: "auto", "2xl": "auto" }}
      zIndex="2"
      position={{ lg: "absolute" }}
    >
      <Box
        backgroundImage="url('./jpg/mapMonde.jpg')"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center center"
        p={2}
        borderRadius="xl"
        height={{ xl: "300px", "2xl": "400px" }}
        border="4px"
        borderColor="#293d36"
        overflow="hidden"
      >
        <Text
          borderRadius="xl"
          color="black"
          fontSize={{ lg: "xl", xl: "xl" }}
          textAlign="center"
          mt="1px"
          backgroundColor="rgba(255, 255, 255, 0.8)"
        >
          {" "}
          Choisissez votre destination olfactive ✨
        </Text>

        {/* Icônes de catégories */}

        <CategoryIcon
          src="./svg/icone_clear_map.svg"
          label="Fresh"
          tooltipLabel="Neutralise les mauvaises odeurs, good smell & good vibes only! 🕯️🌬️🌸✨"
          left={{ lg: "40%", xl: "40%" }}
          top={{ lg: "20%", xl: "15%" }}
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <CategoryIcon
          src="./svg/icone_fruity_map.svg"
          label="Fruity"
          tooltipLabel="Comme un panier de fruits tout droit rapporté du marché, bougie ultra veggie! 🍓🍋🕯️"
          left={{ xl: "23%", "2xl": "25%" }}
          top={{ xl: "22%", "2xl": "30%" }}
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <CategoryIcon
          src="./svg/icone_sweet_map.svg"
          label="Gourmand"
          tooltipLabel="Bougie parfumée pour les grands gourmands, même au régime. Succombez à leurs parfums sans prendre un gramme ! 🧁🍪🕯️"
          left={{ lg: "60%", xl: "68%", "2xl": "60%" }}
          top={{ lg: "15%", xl: "15%" }}
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <CategoryIcon
          src="./svg/icone_fleurs_map.svg"
          label="Floral"
          tooltipLabel="Éclosion de senteurs, entourez-vous de la délicate essence des fleurs, un véritable jardin de fragrances envoûtantes! 🌸🌹🕯️"
          left={{ lg: "50%", xl: "52%", "2xl": "50%" }}
          top={{ lg: "40%", xl: "31%", "2xl": "40%" }}
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <CategoryIcon
          src="./svg/icone_summer_map.svg"
          label="Summer"
          tooltipLabel="Mojito en main 🍹, tongues aux pieds 👣 : Mode vacances enclenché! 🌞🌊🏖️"
          left={{ lg: "70%", xl: "80%" }}
          top={{ lg: "40%", xl: "45%", "2xl": "30%" }}
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <CategoryIcon
          src="./svg/woody_icone_map.svg"
          label="Woody"
          tooltipLabel="S'immerger dans une forêt ancienne, où chaque bouffée est une étreinte de la nature,fragrances boisées. 🌲🍂🕯️"
          left={{ xl: "10%", "2xl": "10%" }}
          top={{ xl: "50%", "2xl": "55%" }}
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <CategoryIcon
          src="./svg/green_icone_map.svg"
          label="Green"
          tooltipLabel="Une bouffée d'air frais, un réveil dans le jardin d'Éden. 🌿🍃🕯️"
          left={{ xl: "30%", "2xl": "35%" }}
          top={{ xl: "60%", "2xl": "65%" }}
          onCategorySelect={(category) => handleCategorySelect(category)}
        />

        <CategoryIcon
          src="./svg/spicy_icone_map.svg"
          label="Spicy"
          tooltipLabel="Chaleur et caractère dans chaque flamme, un voyage épicé aux quatre coins du monde. 🌶️🔥🕯️"
          left={{ xl: "60%", "2xl": "65%" }}
          top={{ xl: "60%", "2xl": "65%" }}
          onCategorySelect={(category) => handleCategorySelect(category)}
        />
        <CategoryIcon
          src="./svg/icone_tout.svg"
          label="Voir tout"
          tooltipLabel="Découvrez l'ensemble des bougies parfumées!🍃✨"
          left={{ xl: "5%", "2xl": "85%" }}
          top={{ xl: "15%", "2xl": "60%" }}
          onCategorySelect={() => handleCategorySelect(null) }  // Réinitialise la sélection
        />
      </Box>
    </Box>
  );
}

function CategoryIcon({
  src,
  label,
  left,
  top,
  onCategorySelect,
  tooltipLabel,
}) {
  return (
    <Tooltip
      label={tooltipLabel}
      aria-label={`Tooltip pour ${label}`}
      placement="top"
      hasArrow
      fontSize="lg"
    >
      <Box
        position="absolute"
        left={left}
        top={top}
        cursor="pointer"
        textAlign="center"
        onClick={() => onCategorySelect(label)}
      >
        <Image
          src={src}
          boxSize={{ lg: "50px", xl: "50px", "2xl": "60px" }}
          _hover={{ transform: "scale(1.5)" }}
        />
        <Text
          mt="2"
          fontSize={{ md: "sm" }}
          fontWeight="bold"
          borderRadius="xl"
          backgroundColor="rgba(255, 255, 255, 0.8)"
          p="2"
        >
          {label}
        </Text>
      </Box>
    </Tooltip>
  );
}

export default WelcomeComponent;
