import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    initializeCart: (state) => {
      const savedCart = JSON.parse(localStorage.getItem('cart'));
      if (savedCart) {
        state.items = savedCart;
      }
    },
    
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItemIndex = state.items.findIndex(
        item => item._id === newItem._id && item.selectedSize === newItem.selectedSize
      );
      if (existingItemIndex >= 0) {
        state.items[existingItemIndex].quantity += newItem.quantity;
      } else {
        state.items.push(newItem);
      }
    },
        
    removeItem: (state, action) => {
      const _id = action.payload;
      state.items = state.items.filter(item => item._id !== _id);
    },
    updateQuantity: (state, action) => {
      const { _id, quantity } = action.payload; 
      const itemIndex = state.items.findIndex(item => item._id === _id);
      if (itemIndex !== -1) {
        state.items[itemIndex].quantity = quantity;
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
    applyPromotion: (state, action) => {
      const { type, value } = action.payload;
      state.promotion = { type, value };
    },

    clearPromotion: (state) => {
      state.promotion = null; // Réinitialisez la promotion à null
    },
    
  },
});

export const { initializeCart, addItem, removeItem, updateQuantity,clearCart,applyPromotion,clearPromotion } = cartSlice.actions;

const getCartItems = state => state.cart.items;
const getPromotion = state => state.cart.promotion;


export const selectCartTotal = createSelector(
  [getCartItems, getPromotion],
  (cartItems, promotion) => {
    const subtotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    const taxPortion = subtotal * 0.20; // Supposons que la taxe est de 20%
    let discount = 0;
    let isFreeShipping = false;

    if (promotion) {
      switch(promotion.type) {
        case 'percentage':
          discount = subtotal * (promotion.value / 100);
          break;
        case 'fixed':
          discount = promotion.value;
          break;
        case 'freeShipping':
          isFreeShipping = true;
          break;
        default:
          // Pas de changement pour le total si le type de promotion est inconnu
          break;
      }
    }

    const total = subtotal - discount;
    return { subtotal, tax: taxPortion, discount, total, isFreeShipping };
  }
);


export default cartSlice.reducer;