import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Stack, Flex, useToast, Avatar, Heading, Center } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { API_URLS } from "./apiConfig.js";



const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_URLS.FORGOT_PASSWORD, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      // Gérer la réponse (afficher un message à l'utilisateur, etc.)
      if (response.ok) {
          toast({
            title: 'Email envoyé',
            description: 'Un email de réinitialisation de mot de passe a été envoyé. Veuillez vérifier votre email et cliquer sur le lien pour réinitialiser votre mot de passe.',
            status: 'success',
            duration: 5000,
            isClosable: true
        });
      } else {
        throw new Error(data.message || 'Échec de l\'envoi de l\'email de réinitialisation du mot de passe');
      }
    } catch (error) {
      toast({
        title: 'Erreur',
        description: error.message || 'Un problème est survenu lors de la demande de réinitialisation du mot de passe.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Flex
      flexDirection="column"
      width="100%"
      height="100vh"
      alignItems="center"
      mt={[ '40%', '30%', '20%', '10%' ]}

    >

      <Helmet>
        <title>Réinitialisation du Mot de Passe | Imagine Factory</title>
        <meta name="description" content="Réinitialisez votre mot de passe facilement. Entrez votre email et nous vous enverrons un lien pour créer un nouveau mot de passe." />
      </Helmet>

      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        spacing={4}
        p="1rem"
        backgroundColor="#f3f6f4"
        boxShadow="md"
        borderRadius="xl"
      >
        <Avatar bg="#293D26" />
        <Heading color="#293d26" fontSize={'xl'}>Mot de passe oublié?</Heading>
        <Box maxWidth="500px" mx="auto">
          <form onSubmit={handleSubmit}>
            <FormControl id="email" isRequired>
              <FormLabel>Email:</FormLabel>
              <Input type="email" name="email" value={email} onChange={handleChange} borderColor={'#293d36'}/>
            </FormControl>
            <Center>
            <Button type="submit" mt="4" backgroundColor="#98b29f" color="#293d36">
              Réinitialiser
            </Button>
            </Center>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default ForgotPassword;
