import React, { useState , useEffect} from "react";
import { useSelector } from "react-redux"; 
import { useNavigate } from "react-router-dom";
import { API_URLS } from "../apiConfig.js"


import {
  Box,
  Button,
  Input,
  Text,
  VStack,
  useToast
} from "@chakra-ui/react";

const NewAdminUser = () => {
  // Utiliser useSelector pour accéder à l'état Redux
  const isAdmin = useSelector(state => state.user.isAdmin);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    // Rediriger si l'utilisateur n'est pas connecté ou s'il n'est pas un administrateur
    if (!isLoggedIn || !isAdmin) {
      navigate('/');
    }
  }, [isLoggedIn, isAdmin, navigate]);

  const promoteUserToAdmin = async () => {
    setIsLoading(true);
    try {
      const url = API_URLS.PROMOTE_USER_TO_ADMIN(userId);
  
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',

        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      
      toast({
        title: "Success",
        description: data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAdmin) {
    return (
      <Box textAlign="center" mt="100px">
        <Text fontSize="2xl">You do not have permission to view this page.</Text>
      </Box>
    );
  }

  return (
    <VStack spacing={4} width="400px" margin="auto" mt="100px" position="relative"
    zIndex="2">
      <Text fontSize="2xl" mb="8">Promote User to Admin</Text>
      <Input
        placeholder="Enter user ID"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />
      <Button 
        colorScheme="blue" 
        onClick={promoteUserToAdmin}
        isLoading={isLoading}
      >
        Promote to Admin
      </Button>
    </VStack>
  );
};

export default NewAdminUser;
