import { useGLTF} from '@react-three/drei'

export default function Island1() {
  

    const model= useGLTF ('./GLTF/island-optimized-v2.glb')
    
    

  return (
 
    <primitive object={model.scene} scale={0.65} position-x={-5.6} position-y={0.1} rotation-y={0.9}/>

  )
  
}


