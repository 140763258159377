import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from "react-router-dom";
import { API_URLS } from "../apiConfig.js"

import {
  Box,
  Heading,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Center,
  Spinner,
  useColorModeValue,
  Text,
  VStack,
} from "@chakra-ui/react";

const ClientsList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || !isAdmin) {
      navigate('/');
    } else {
      // Votre logique de chargement des utilisateurs ici
      fetchUsers();
    }
  }, [isLoggedIn, isAdmin, navigate]);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const usersResponse = await fetch(API_URLS.GET_ALL_USERS, {
        credentials: "include",
      });
      const usersData = await usersResponse.json();
      if (!usersResponse.ok) throw new Error("Network response was not ok");
  
      // Chargez les détails supplémentaires pour chaque utilisateur
      const usersWithDetails = await Promise.all(usersData.map(async (user) => {
        const totalOrders = await fetchUserOrders(user._id);
        const totalReviews = await fetchUserReviews(user._id);
        return { ...user, totalOrders, totalReviews };
      }));
  
      setUsers(usersWithDetails);
    } catch (error) {
      setError("Erreur lors de la récupération des utilisateurs: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchUserOrders = async (userId) => {
    try {
      const url = API_URLS.getTotalOrdersByUserUrl(userId);
      const response = await fetch(url, {
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to load total orders');
      const data = await response.json();
      return data.totalOrders;
    } catch (error) {
      console.error(error.message);
      return 'Erreur';
    }
  };
  
  const fetchUserReviews = async (userId) => {
    try {
      const url = API_URLS.getTotalReviewsByUserUrl(userId);
      const response = await fetch(url, {
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to load total reviews');
      const data = await response.json();
      return data.totalReviews;
    } catch (error) {
      console.error(error.message);
      return 'Erreur';
    }
  };




  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        user.firstName.toLowerCase().includes(searchTerm) ||
        user.lastName.toLowerCase().includes(searchTerm) ||
        user.address.toLowerCase().includes(searchTerm) ||
        user.country.toLowerCase().includes(searchTerm)
    );
  }, [searchTerm, users]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const bg = useColorModeValue("white", "gray.800");

  return (
    <VStack spacing={6} align="stretch" p="4" mt="10%" position="relative"
    zIndex="2">
      <Center>
        <Heading as="h1" size="xl" color="#293d36">
          Liste des utilisateurs
        </Heading>
      </Center>
      <Input
        type="text"
        placeholder="Rechercher un client..."
        value={searchTerm}
        onChange={handleSearch}
        mb="4"
        size="lg"
        bg={bg}
      />
      {isLoading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : error ? (
        <Text color="red.500">{error}</Text>
      ) : (
        <Box overflowX="auto">
          <Table
            variant="simple"
            colorScheme="teal"
            size="md"
            bg={bg}
            boxShadow="lg"
            borderRadius="md"
          >
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Prénom</Th>
                <Th>Adresse</Th>
                <Th>Pays</Th>
                <Th>Nombre de commandes</Th>
                <Th>Reviews</Th>
                <Th>Détails</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredUsers.map((user) => (
                <Tr key={user._id}>
                  <Td>{user.lastName}</Td>
                  <Td>{user.firstName}</Td>
                  <Td>{user.address}</Td>
                  <Td>{user.country}</Td>
                  <Td>{user.totalOrders || 'Chargement...'}</Td>
                  <Td>{user.totalReviews || 'Chargement...'}</Td>
                  <Td>
                    <RouterLink to={`/Admin/users/${user._id}`}>
                      <Link color="blue.500" as="span">
                        {" "}
                    
                        Voir les détails
                      </Link>
                    </RouterLink>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </VStack>
  );
};

export default ClientsList;
