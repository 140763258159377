import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  HStack,
  Text,
  Heading,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { API_URLS } from "../apiConfig.js"

export default function CarrierPriceManagement() {
  const [shippingRates, setShippingRates] = useState([]);
  const [newDestination, setNewDestination] = useState("");
  const [selectedRate, setSelectedRate] = useState({
    carrier: "MondialRelay",
    destinations: [],
    rates: [{ min: 0, max: 0, rate: 0 }],
  });
 
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const navigate = useNavigate();
  const toast = useToast();

  const checkAuthAndRedirect = () => {
    if (!isLoggedIn || !isAdmin) {
      navigate('/');
      return false; // Indique une redirection
    }
    return true; // Indique que l'utilisateur est authentifié et peut continuer
  };

  const fetchRegularShippingRates = async () => {
    if (!checkAuthAndRedirect()) return; 
    try {
      const response = await fetch(
        API_URLS.READ_SHIPPING_RATES
      );
      if (!response.ok) {
        throw new Error(
          "Erreur lors de la récupération des tarifs de livraison"
        );
      }
      const rates = await response.json();


      // Filtrer pour inclure les tarifs réguliers, y compris ceux de Colissimo et DHL sans tarifs par défaut significatifs
      const filteredRates = rates.filter((rate) => {
        // Vérifier si le tarif par défaut pour Colissimo ou DHL est vide ou a des valeurs à 0
        const isDefaultRateEmpty = (defaultRate) => {
          return (
            !defaultRate ||
            defaultRate.every(
              (rate) => rate.min === 0 && rate.max === 0 && rate.rate === 0
            )
          );
        };

        if (rate.defaultRates) {
          const colissimoDefaultEmpty = isDefaultRateEmpty(
            rate.defaultRates.Colissimo
          );
          const dhlDefaultEmpty = isDefaultRateEmpty(rate.defaultRates.DHL);
          return colissimoDefaultEmpty && dhlDefaultEmpty;
        }

        return true; // Inclure les tarifs qui n'ont pas de defaultRates
      });

      setShippingRates(filteredRates);

      toast({
        title: "Succès",
        description: "Tarifs de livraison récupérés avec succès",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Échec",
        description: `Erreur lors de la récupération des tarifs: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  
  const addShippingRate = async (rateData) => {
    try {
      const response = await fetch(
        API_URLS.ADD_SHIPPING_RATES,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rateData),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de l'ajout du tarif de livraison");
      }

      // Afficher un toast de succès
      toast({
        title: "Tarif ajouté avec succès.",
        description: "Un nouveau tarif de livraison a été ajouté.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Actualiser la liste des tarifs si nécessaire
      fetchRegularShippingRates();
    } catch (error) {
      toast({
        title: "Échec de l'ajout.",
        description: `Erreur: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateShippingRate = async (rateId, rateData) => {
    try {
      // Générer l'URL en utilisant la fonction getUpdateShippingRatesUrl
      const url = API_URLS.getUpdateShippingRatesUrl(rateId);
  
      // Utiliser l'URL générée dans l'appel fetch
      const response = await fetch(url, {
        method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(rateData),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour du tarif de livraison");
      }

      // Mise à jour réussie
      toast({
        title: "Mise à jour réussie.",
        description: "Le tarif de livraison a été mis à jour avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Rafraîchir la liste des tarifs ici
      fetchRegularShippingRates();
    } catch (error) {
      toast({
        title: "Échec de la mise à jour.",
        description: `Erreur: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const deleteShippingRate = async (rateId) => {
    try {
      const url = API_URLS.getDeleteShippingRatesUrl(rateId);
    
      const response = await fetch(url, {
        method: "DELETE",
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression du tarif de livraison");
      }

      // Afficher un toast de succès
      toast({
        title: "Tarif supprimé.",
        description: "Le tarif de livraison a été supprimé avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Actualiser la liste des tarifs
      fetchRegularShippingRates();
    } catch (error) {
      toast({
        title: "Échec de la suppression.",
        description: `Erreur: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddDestination = () => {
    if (newDestination.trim() !== "") {
      setSelectedRate({
        ...selectedRate,
        destinations: [...selectedRate.destinations, newDestination.trim()],
      });
      setNewDestination(""); // Réinitialisez après l'ajout
    }
  };

  const handleRemoveDestination = (index) => {
    // Supprimez la destination du tableau
    const updatedDestinations = selectedRate.destinations.filter(
      (_, idx) => idx !== index
    );
    setSelectedRate({ ...selectedRate, destinations: updatedDestinations });
  };

  const handleChange = (e) => {
    setSelectedRate({ ...selectedRate, [e.target.name]: e.target.value });
  };

  

  const handleSaveRate = () => {
    const formattedRates = selectedRate.rates.map((rate) => ({
      ...rate,
      min: parseFloat(rate.min),
      max: parseFloat(rate.max),
      rate: parseFloat(rate.rate),
    }));

    const dataToSend = {
      ...selectedRate,
      rates: formattedRates,
      destinations: selectedRate.destinations
    };

    if (selectedRate._id) {
      updateShippingRate(selectedRate._id, dataToSend);
    } else {
      addShippingRate(dataToSend);
    }
  };

  const handleSelectRate = (rate) => {
    setSelectedRate(rate);
     };

  const handleRateChange = (e, index) => {
    const newRates = [...selectedRate.rates];
    const [field, rateIndex] = e.target.name.split("-");
    newRates[rateIndex][field] = e.target.value;
    setSelectedRate({ ...selectedRate, rates: newRates });
  };

  const handleAddRate = () => {
    setSelectedRate({
      ...selectedRate,
      rates: [...selectedRate.rates, { min: 0, max: 0, rate: 0 }],
    });
  };

  // const handleRemoveRate = (index) => {
  //   const newRates = [...selectedRate.rates];
  //   newRates.splice(index, 1);
  //   setSelectedRate({ ...selectedRate, rates: newRates });
  // };
  
  useEffect(() => {
   
    fetchRegularShippingRates();
  }, []);

  // Formulaire et affichage des tarifs existants
  return (
    <Box p={4} mt="10%">
      <VStack spacing={4}>
        {/* Section de formulaire pour l'ajout/modification */}
        <Box borderWidth="1px" p={4} borderRadius="md" w="100%">
          <Heading as="h3" size="lg" mb={4}>Gestion des Tarifs de Livraison</Heading>
          <FormControl isRequired>
            <FormLabel>Transporteur</FormLabel>
            <Select name="carrier" value={selectedRate.carrier} onChange={handleChange}>
              <option value="MondialRelay">Mondial Relay</option>
              <option value="Colissimo">Colissimo</option>
              <option value="DHL">DHL</option>
              <option value="ColissimoDefaultRates">ColissimoDefaultRates</option>
              <option value="DHLDefaultRates">DHLDefaultRates</option>


            </Select>
          </FormControl>
  
          <FormControl isRequired mt={4}>
            <FormLabel>Destinations</FormLabel>
            {selectedRate.destinations.map((destination, index) => (
              <HStack key={index}>
                <Text>{destination}</Text>
                <Button colorScheme="red" onClick={() => handleRemoveDestination(index)}>Supprimer</Button>
              </HStack>
            ))}
            <Input placeholder="Ajouter une nouvelle destination" value={newDestination} onChange={(e) => setNewDestination(e.target.value)} />
            <Button mt={2} colorScheme="green" onClick={handleAddDestination}>Ajouter Destination</Button>
          </FormControl>
  
          {selectedRate.rates.map((rate, index) => (
            <Box key={index}>
              <HStack mt={2}>
                <FormControl>
                  <FormLabel>Min</FormLabel>
                  <Input type="number" name={`min-${index}`} value={rate.min} onChange={(e) => handleRateChange(e, index)} />
                </FormControl>
                <FormControl>
                  <FormLabel>Max</FormLabel>
                  <Input type="number" name={`max-${index}`} value={rate.max} onChange={(e) => handleRateChange(e, index)} />
                </FormControl>
                <FormControl>
                  <FormLabel>Tarif</FormLabel>
                  <Input type="number" name={`rate-${index}`} value={rate.rate} onChange={(e) => handleRateChange(e, index)} />
                </FormControl>
              </HStack>
            </Box>
          ))}
  
          <HStack mt={4}>
            <Button colorScheme="green" onClick={handleAddRate}>Ajouter un Tarif</Button>
            <Button colorScheme="blue" onClick={handleSaveRate}>{selectedRate._id ? "Modifier" : "Ajouter"}</Button>
          </HStack>
        </Box>
  
        {/* Section d'affichage des tarifs */}
        <Box w="100%">
          <Heading as="h4" size="md" mb={4}>Tarifs de Livraison Actuels</Heading>
          {shippingRates.map((rate, index) => (
            <Box key={index} borderWidth="1px" p={4} borderRadius="md" mb={4}>
              <Text fontWeight="bold">{rate.carrier} - Destinations: {rate.destinations.join(", ")}</Text>
              {rate.rates.map((rateDetail, detailIndex) => (
                <Text key={detailIndex}>Poids: {rateDetail.min} - {rateDetail.max} kg, Tarif: {rateDetail.rate} €</Text>
              ))}
              <HStack mt={2}>
                <Button colorScheme="blue" onClick={() => handleSelectRate(rate)}>Modifier</Button>
                <Button colorScheme="red" onClick={() => deleteShippingRate(rate._id)}>Supprimer</Button>
              </HStack>
            </Box>
          ))}
        </Box>
      </VStack>
    </Box>

  )};