import React, {useState} from "react";
import {  IconButton,Box, Image, Flex, Text } from "@chakra-ui/react";
import {InfoOutlineIcon } from '@chakra-ui/icons';

const EffetsMagiquesBanner = () => {

  const [openIndex, setOpenIndex] = useState(null);
    
    const handleIconClick = (index) => {
      if (openIndex === index) {
        setOpenIndex(null);
      } else {
        setOpenIndex(index);
      }
    };


  const effetsMagiques = [
    {
      nom: "Boost",
      description:
        "Comme un espresso!Réveillez votre espace avec des notes énergisantes!☕⚡",
      icone: "/svg/boost_icone.svg",
    },
    {
      nom: "Focus",
      description:
        "Éveille votre génie intérieur.Stimule la concentration et la créativité. 🖌️📚🧠",
      icone: "/svg/focus_icone.svg",
    },
    {
      nom: "Cocooning",
      description:
        "Bougie pour amateurs de Chocolat chaud, chaussettes douillettes et feu de cheminée. 🍵🔥✨",
      icone: "/svg/cocooning_icone1.svg",
    },
    {
      nom: "Relax",
      description:
        "Comme une caresse sur l'âme.Favorise le repos et la relaxation.☮️💤🌸 ",
      icone: "/svg/relax_icone.svg",
    },
    {
      nom: "Emoti",
      description:
        "Réchauffe l'esprit et apaise le cœur, comme une étreinte d'amour et de réconfort. 😊🌞🩷",
      icone: "/svg/emoti_icone.svg",
    },
    {
      nom: "Invigorating",
      description:
        "Plongez dans un océan de fraîcheur!Imaginez une vague d'embruns vivifiants qui vous enveloppe.✨🌊💦",
      icone: "/svg/invigorating_icone.svg",
    },
    {
      nom: "Meditate",
      description:
        "Votre séance de méditation avec une touche parfumée pour atteindre le nirvana.🕉️✨",
      icone: "/svg/meditate_icone.svg",
    },
    {
      nom: "Festive",
      description:
        "La fête dans un pot!Parfaite pour vos moments de célébration!🎉🎈",
      icone: "/svg/festive_icone.svg",
    },
    {
      nom: "New",
      description: "Nouvelle création! 🌟🎇✨",
      icone: "/Icones/new.svg",
    },
    {
      nom: "Romantic",
      description:
        "Pour des soirées à deux.Amour, lumière tamisée et magie dans l'air!🌹🥂",
      icone: "/svg/romantic_icone.svg",
    },

    // Ajoutez d'autres effets magiques selon le besoin
  ];

  return (
    <Box
      backgroundColor="#dce5df"
      padding="4"
      ml={{ base: "0%", md: "3%", lg: "0%", xl: "8%", "2xl": "9%" }}
      mr={{ base: "0%", md: "3%", lg: "0%", xl: "8%", "2xl": "9%" }}
      mt="1%"
      textAlign="center"
      fontSize={{ base: "sm", md: "md", lg: "md", xl: "lg", "2xl": "2xl" }}
      fontWeight="bold"
      fontFamily="Roboto"
      color="#293d36"
      borderRadius="xl"
    >
      <Box mb="1%" mt="0.5%">
        <p>"Bienvenue dans mon atelier enchanté! ✨🔮</p>
      </Box>

      <Box
        ml={{ base: "0.5%", md: "1%", lg: "1%", xl: "1%", "2xl": "1%" }}
        mr={{ base: "0.5%", md: "1%", lg: "1%", xl: "1%", "2xl": "1%" }}
        mt="1%"
      >
        <p>
          Je suis Flameus, gardien des flammes magiques 🔥🧙‍♂️. Chaque icône
          révèle un pouvoir spécial de mes bougies.
        </p>
      </Box>

      <Box mb="2%" mt="0.5%">
        <p>Plongez dans leur légende et laissez la magie opérer !"</p>
      </Box>
      <Flex overflowX="auto" direction="row">
        {effetsMagiques.map((effect, index) => (
          <Flex key={index} direction="column" alignItems="center" mb="1%" minWidth={{ base: "120px", md: "150px" }} mr={{ base: "-16%", md: "-10%",xl:"-6%","2xl":"0" }}>
            <Box position="relative" mr={{ base: "60%", md: "0" }}>
              <Image src={effect.icone} alt={effect.nom} boxSize={{ base: "50px", md: "60px" }}/>
              <IconButton
                icon={<InfoOutlineIcon />}
                onClick={() => handleIconClick(index)}
                size="xs"
                variant="ghost"
                position="absolute"
                bottom={{ base: "-2px", md: "-10px" }}
                right="-10px"
                backgroundColor="white"
              />
            </Box>
            {openIndex === index && (
              <Box mt="2" p="2" backgroundColor="white" borderRadius="md">
                <Text fontSize={{ base: "xs", md: "md" }} textAlign="center">
                  {effect.description}
                </Text>
              </Box>
            )}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
  
export default EffetsMagiquesBanner;
