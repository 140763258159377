import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API_URLS } from "../apiConfig.js"

import {
  useToast,
  Box,
  Container,
  Text,
  Image,
  Grid,
  Spinner,
  Alert,
  AlertIcon,
  Button,
  Center,
  VStack,
  Select,
} from "@chakra-ui/react";
import ProductForm from "./ProductForm";

const STATIC_URL = process.env.REACT_APP_STATIC_URL || 'http://localhost:5000/uploads/';

const ProductDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [product, setProduct] = useState(null);
  const [newImages, setNewImages] = useState([]);
  const [newMainImage, setNewMainImage] = useState(null);
  const [newVideos, setNewVideos] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const toast = useToast();

  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const isAdmin = useSelector(state => state.user.isAdmin);

  useEffect(() => {
    // Vérifier si l'utilisateur est connecté et est un administrateur
    if (!isLoggedIn || !isAdmin) {
      navigate('/');
    }
  }, [isAdmin, isLoggedIn, navigate]);


  
  useEffect(() => {
    setLoading(true);
    setError(null);

    const fetchProductData = async () => {
      try {
        const response = await fetch(API_URLS.GET_PRODUCT_DETAIL(id));
        if (!response.ok) throw new Error("Erreur lors de la récupération du produit");
        const data = await response.json();

        // Mise à jour des états avec les détails du produit
        setProduct(data);
        setSelectedSize(data.sizes && data.sizes.length > 0 ? data.sizes[0] : null);
        
        // Appeler les fonctions pour récupérer les commentaires et la note moyenne
        await fetchComments(data.title);
        await fetchAverageRating(data.title);

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  const fetchComments = async (productTitle) => {
    try {
      const response = await fetch(API_URLS.GET_REVIEWS_FOR_PRODUCT(productTitle));
      const data = await response.json();
      if (!response.ok) throw new Error("Failed to fetch comments");
      setReviews(Array.isArray(data) ? data : []); // Assurez-vous que les données sont un tableau
    } catch (error) {
      console.error("Error fetching comments:", error);
      setReviews([]); // Si une erreur se produit, définissez reviews sur un tableau vide
    }
  };

  const fetchAverageRating = async (productTitle) => {
    try {
    const response = await fetch(API_URLS.GET_AVERAGE_RATING(productTitle));
      const ratingData = await response.json();


      if (response.ok) {
        setAverageRating(ratingData.averageRating);
        setTotalReviews(ratingData.totalReviews);
      } else {
        throw new Error("Failed to fetch average rating");
      }
    } catch (error) {
      console.error("Error fetching average rating:", error);
    }
  };

  const deleteImage = async (image) => {
    try {
        // Supprimer l'image du tableau d'images du produit
        const updatedImages = product.images.filter(img => img !== image);
        setProduct(prevProduct => ({ ...prevProduct, images: updatedImages }));
        // Envoyer une requête pour supprimer l'image du serveur
        await fetch(API_URLS.DELETE_MEDIA(image), { method: 'DELETE', credentials: 'include' });
    } catch (error) {
        setError("Erreur lors de la suppression de l'image");
    }
}

const handleSizeChange = (e) => {
  const selected = product.sizes.find(s => s.contenance === e.target.value);
  setSelectedSize(selected);
};


const handleFileChange = (e) => {
  const { name, files } = e.target;
  if (name === "newImages") {
      setNewImages(files);
  } else if (name === "newMainImage") {
      setNewMainImage(files[0]);
  } else if (name === "newVideos") {
      setNewVideos(files);
  }
}

  const handleEdit = () => {
    setIsEditing(true);
  };

  const deleteVideo = async (video) => {
    try {
      // Supprimer la vidéo du tableau de vidéos du produit
      const updatedVideos = product.videos.filter(v => v !== video);
      setProduct(prevProduct => ({ ...prevProduct, videos: updatedVideos }));
  
      // Envoyer une requête pour supprimer la vidéo du serveur
      await fetch(API_URLS.DELETE_MEDIA(video), {
        method: 'DELETE',
        credentials: 'include',
      });
    } catch (error) {
      setError("Erreur lors de la suppression de la vidéo");
    }
  }
 
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduct(prevProduct => ({
      ...prevProduct,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  

  const handleDeleteReview = async (reviewId) => {
    try {
      await fetch(API_URLS.DELETE_REVIEW(reviewId), {
        method: 'DELETE',
        credentials: 'include',
      });
      setReviews(reviews.filter(review => review._id !== reviewId));
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  };
  
  const handleToggleVerified = async (review) => {
    try {
      const response = await fetch(API_URLS.TOGGLE_REVIEW_VERIFICATION(review._id), {
        method: 'PATCH',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isVerified: !review.isVerified }),
      });
  
      if (response.ok) {
        setReviews(reviews.map(r => r._id === review._id ? { ...r, isVerified: !r.isVerified } : r));
        toast({
          title: "Review Updated",
          description: "The review verification status has been updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to update review');
      }
    } catch (error) {
      console.error("Error updating review:", error);
      toast({
        title: "Error",
        description: "Failed to update the review verification status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
   

  const updateProductInformation = async () => {
         // Mise à jour des autres détails du produit
         const productData = {
          sku: product.sku,
          discount: product.discount,
          category: product.category,
          benefits:  product.benefits,
          title: product.title,
          underTitle: product.underTitle,
          description: product.description,
          information: product.information,
          sizes: product.sizes,
          isVisible: product.isVisible
        };
  
  
        try {
          let response = await fetch(API_URLS.UPDATE_PRODUCT(product._id), {
            method: "PATCH",
            credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productData),
      });
  
      if (!response.ok) {
        throw new Error("La mise à jour des informations du produit a échoué");
      }
  
      // Afficher un toast de succès
      toast({
        title: "Succès",
        description: "Les informations du produit ont été mises à jour avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
    } catch (error) {
      // Afficher un toast d'erreur
      toast({
        title: "Erreur",
        description: `Une erreur est survenue : ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const handleSave = async () => {
    try {
      // Si nous avons de nouvelles images ou vidéos à télécharger
      const formData = new FormData();
      
      if (newImages.length) {
        for (let i = 0; i < newImages.length; i++) {
          formData.append("newImages", newImages[i]);
        }
      }
      
      if (newVideos.length) {
        for (let i = 0; i < newVideos.length; i++) {
          formData.append("newVideos", newVideos[i]);
        }
      }
    
      if (formData.has("newImages") || formData.has("newVideos")) {
        let response = await fetch(API_URLS.UPLOAD_MEDIA(id), {
          method: "PATCH",
          credentials: 'include',
          body: formData,
        });
    
        if (!response.ok) {
          throw new Error("Erreur lors de l'upload des médias");
        }
      }
    
      // Si une nouvelle mainImage est présente, utilisez la route spécifique pour la télécharger
      if (newMainImage) {
        const mainImageFormData = new FormData();
        mainImageFormData.append("mainImage", newMainImage);
    
        let response = await fetch(API_URLS.UPLOAD_MAIN_IMAGE(id), {
          method: "POST",
          credentials: 'include',
          body: mainImageFormData,
        });
    
        if (!response.ok) {
          throw new Error("Erreur lors de l'upload de l'image principale");
        }
      }

           // Mise à jour des tailles et des prix
    if (product.contenances && product.prices) {
      if (!product.sizes) {
        product.sizes = [];
      }
      
      for (let i = 0; i < product.contenances.length; i++) {
        product.sizes.push({ 
          contenance: product.contenances[i], 
          price: parseFloat(product.prices[i]) 
        });
      }
    }
    // Appel de updateProductInformation pour mettre à jour les informations du produit
    await updateProductInformation();
 
   
      // Une fois que tout est terminé
      setLoading(false);
      setNewImages([]);
      setNewMainImage(null);
      setNewVideos([]);
    } catch (error) {
      // Gestion des erreurs avec toast
      toast({
        title: "Erreur lors de l'enregistrement",
        description: `Une erreur est survenue : ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      alert(error.message);
    }
    
};
  

 


  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error || !product) {
    return (
      <Alert status="error" variant="subtle" flexDirection="column" justifyContent="center" textAlign="center" height="220px">
        <AlertIcon size="40px" mr={0} />
        Une erreur s'est produite lors du chargement du produit.
      </Alert>
    );
  }

  return (
    <Container mt={{ base: "10", md: "8", lg: "10%" }} maxW="container.xl" py={6} px={8} bg="white" borderRadius="lg" boxShadow="sm" position="relative"
    zIndex="2">
      <VStack spacing={6} align="start">
        <VStack align="start" spacing={2} ml="45%">
          <Text fontSize="2xl" fontWeight="bold">{product.title}</Text>
          <Text fontSize="md" color="gray.500">{product.underTitle}</Text>
        </VStack>
        
        <Image src={`${STATIC_URL}${product.mainImage}`} alt="Main Image" w="50%" borderRadius="md" />        <Grid templateColumns="repeat(3, 1fr)" gap={4} mt={4}>
          {product.images.slice(0, 3).map((image, index) => (
          <Image key={index} src={`${STATIC_URL}${image}`} alt={`Photo ${index + 1}`} borderRadius="md" />
          ))}
        </Grid>

        {isEditing ? (
          <ProductForm
            product={product} 
            handleSave={handleSave} 
            handleInputChange={handleInputChange}
            deleteImage={deleteImage}
            deleteVideo={deleteVideo} 
            handleFileChange={handleFileChange}
          />
        ) : (
          <>
            <VStack align="start" spacing={4} w="full">
              <Select 
                onChange={handleSizeChange}
                value={selectedSize ? selectedSize.contenance : ''}
                bg="white"
                width="200px"
                boxShadow="md"
                borderRadius="md"
                placeholder="Select a size"
              >
                {product.sizes.map((size, index) => (
                  <option key={index} value={size.contenance}>
                    {size.contenance} ml
                  </option>
                ))}
              </Select>
              <Text fontSize="lg" fontWeight="bold" mt={4}>
                Price: {selectedSize ? `${selectedSize.price} €` : ''}
              </Text>
              <Text>
                In Stock: {selectedSize ? `${selectedSize.quantity} available` : ''}
              </Text>
              <Text>Discount: {product.discount}%</Text>
              <Text>Category Type: {product.category}</Text>
            </VStack>

            <VStack align="start" spacing={4} w="full">
  <Text fontSize="lg" fontWeight="bold">Bénéfices :</Text>
  {product.benefits && product.benefits.length > 0 ? (
    <VStack spacing={2}>
      {product.benefits.map((benefit, index) => (
        <Text key={index} fontSize="md">
          - {benefit} 
        </Text>
      ))}
    </VStack>
  ) : (
    <Text fontSize="md">Aucun bénéfice spécifique n'a été ajouté pour ce produit.</Text>
  )}
</VStack>

            {reviews && reviews.length > 0 && (
              <Box>
                <Text fontSize="xl" mb="4%">Average Rating: {averageRating} ({totalReviews} reviews)</Text>
                {reviews.map((review, index) => (
                  <Box key={index} borderWidth="1px" borderRadius="md" p={4}>
                    <Text fontWeight="semibold">{review.firstName} {review.lastName.charAt(0)} ({review.rating}/5)</Text>
                    <Text mt={2}>{review.comment}</Text>
                    {review.media && review.media.map((media, idx) => (
                      <Image key={idx} src={`${STATIC_URL}${media}`} alt={`Review Media ${idx + 1}`} />
                    ))}
                    <Text>{new Date(review.date).toLocaleDateString()}</Text>
                    <Button onClick={() => handleToggleVerified(review)} mr="4%">
                      {review.isVerified ? 'Unverify' : 'Verify'}
                    </Button>
                    <Button onClick={() => handleDeleteReview(review._id)}>
                      Delete
                    </Button>
                  </Box>
                ))}
              </Box>
            )}

            <Box>
              <Text>Is Visible?: {product.isVisible ? 'Yes' : 'No'}</Text>
              <Text>Description: {product.description}</Text>
              <Text>Information: {product.information}</Text>
              <Button colorScheme="teal" onClick={handleEdit} mt="2%">Modifier</Button>
            </Box>
          </>
        )}
      </VStack>
    </Container>
  );
};

export default ProductDetails;