const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

export const API_URLS = {
  CREATE_ITEM: `${API_BASE_URL}/items/createProduct`,
  READ_SHIPPING_RATES: `${API_BASE_URL}/carrier/shippingRates`,
  ADD_SHIPPING_RATES: `${API_BASE_URL}/carrier/shippingRates/add`,
  GET_ALL_USERS: `${API_BASE_URL}/users/getAllUsers`,
  GET_ADMIN_ORDERS: `${API_BASE_URL}/orders/admin/orders`,
  GET_ALL_PROMO_CODES: `${API_BASE_URL}/promotionCode/getAllPromotionCodes/`,
  CREATE_PROMO_CODE: `${API_BASE_URL}/promotionCode/create`,
  GET_REVIEWS: `${API_BASE_URL}/reviews/reviews`,



  // Ajoutez une fonction pour gérer les URL avec des paramètres
  getUpdateShippingRatesUrl: (rateId) => `${API_BASE_URL}/carrier/shippingRates/${rateId}`,
  getDeleteShippingRatesUrl: (rateId) => `${API_BASE_URL}/carrier/shippingRates/${rateId}`,
  GET_SHIPPING_INFO: `${API_BASE_URL}/users/shippingInfo`,
  GET_SHIPPING_RATES: `${API_BASE_URL}/carrier/shippingRates`,


  getUserDetailsUrl: (userId) => `${API_BASE_URL}/users/getUserDataByAdmin/${userId}`,
  getUpdateUserUrl: (userId) => `${API_BASE_URL}/users/updateUserByAdmin/${userId}`, 
  getDeleteUserUrl: (userId) => `${API_BASE_URL}/users/deleteUserByAdmin/${userId}`,
  GET_USER_DATA: `${API_BASE_URL}/users/getUserData`,
  LOGIN_USER: `${API_BASE_URL}/users/loginUser`,
  FORGOT_PASSWORD: `${API_BASE_URL}/users/forgotPassword`,
  LOGOUT: `${API_BASE_URL}/users/logout`,
  GET_USER_ADDRESS: `${API_BASE_URL}/users/addressUser`,
  UPDATE_USER_SHIPPING_ADDRESS: (userId) => `${API_BASE_URL}/users/updateUserShippingAddress/${userId}`,
  UPDATE_USER_PROFILE: `${API_BASE_URL}/users/updateUserProfile`,
  CHECK_EMAIL: (email) => `${API_BASE_URL}/users/checkEmail?email=${encodeURIComponent(email)}`,
  REGISTER_USER: `${API_BASE_URL}/users/register`,
  DELETE_USER: `${API_BASE_URL}/users/deleteUser`,


  CHECK_SESSION: `${API_BASE_URL}/users/checkSession`,
  RESET_PASSWORD: `${API_BASE_URL}/users/resetPassword`,
  CREATE_STRIPE_SESSION: `${API_BASE_URL}/orders/payment`,
  


  GET_REVIEWS_FOR_PRODUCT: (productTitle) => `${API_BASE_URL}/reviews/getReviewsForProduct/${encodeURIComponent(productTitle)}`,
  getTotalReviewsByUserUrl: (userId) => `${API_BASE_URL}/reviews/totalReviewsByUser/${userId}`,
  TOGGLE_REVIEW_VERIFICATION: (reviewId) => `${API_BASE_URL}/reviews/isVerified/${reviewId}`,
  DELETE_REVIEW: (reviewId) => `${API_BASE_URL}/reviews/delete/${reviewId}`,
  GET_AVERAGE_RATING: (productTitle) => `${API_BASE_URL}/reviews/averageRating/${encodeURIComponent(productTitle)}`,
  CHECK_USER_PURCHASE: (userId, productId) => `${API_BASE_URL}/reviews/checkPurchase/${userId}/${productId}`,
  CREATE_REVIEW: `${API_BASE_URL}/reviews/createReview`,


  getTotalOrdersByUserUrl: (userId) => `${API_BASE_URL}/orders/totalOrdersByUser/${userId}`,
  GET_ORDER_DETAILS: (orderId) => `${API_BASE_URL}/orders/details/${orderId}`,
  UPDATE_ORDER_STATUS: (orderId) => `${API_BASE_URL}/orders/updateOrder/${orderId}`,
  DELETE_ORDER: (orderId) => `${API_BASE_URL}/orders/delete/${orderId}`,
  GET_USER_ORDERS: (userId) => `${API_BASE_URL}/orders/${userId}`,

  CHECK_PAYMENT_STATUS: `${API_BASE_URL}/orders/check`,
  CREATE_ORDER: `${API_BASE_URL}/orders/createOrder`,

  UPDATE_PROMO_CODE: (codeId) => `${API_BASE_URL}/promotionCode/update/${codeId}`,
  DELETE_PROMO_CODE: (codeId) => `${API_BASE_URL}/promotionCode/delete/${codeId}`,

 


  PROMOTE_USER_TO_ADMIN: (userId) => `${API_BASE_URL}/users/promoteToAdmin/${userId}`,



  GET_PRODUCT_DETAIL: (itemId) => `${API_BASE_URL}/items/productDetail/${itemId}`,
  UPDATE_PRODUCT: (productId) => `${API_BASE_URL}/items/updateProduct/${productId}`,
  DELETE_MEDIA: (mediaId) => `${API_BASE_URL}/media/${mediaId}`,
  UPLOAD_MEDIA: (productId) => `${API_BASE_URL}/media/${productId}/uploadMedia`,
  UPLOAD_MAIN_IMAGE: (productId) => `${API_BASE_URL}/media/${productId}/uploadMainImage`,
  UPDATE_SIZE: (itemId) => `${API_BASE_URL}/items/updateSize/${itemId}`,
  REMOVE_SIZE: (itemId) => `${API_BASE_URL}/items/${itemId}/removeSize`,
  ADD_SIZE: (itemId) => `${API_BASE_URL}/items/${itemId}/addSize`,
  DELETE_PRODUCT: (productId) => `${API_BASE_URL}/items/deleteProduct/${productId}`,
  GET_PRODUCT_LIST: `${API_BASE_URL}/items/productList`,









};
