import React, { useState, useRef, useEffect } from "react";
import {
  Box, Flex, Heading, FormControl, FormLabel, Input, Button, useToast,
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader,
  AlertDialogOverlay, AlertDialogContent,Center,
} from "@chakra-ui/react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { API_URLS } from "../../Components/apiConfig.js";



const ClientInformations = ({ clientData }) => {
  const [formData, setFormData] = useState(clientData || {});
  const [country, setCountry] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);


  useEffect(() => {
    const fetchUserData = async () => {
      if (!isLoggedIn) {
        navigate("/Connexion");
        return;
      }
  
      try {
        // Récupérer les données utilisateur
        const dataResponse = await fetch(API_URLS.GET_USER_DATA, { 
          method: "GET",
          credentials: 'include',
        });
  
        if (!dataResponse.ok) {
          throw new Error("Failed to fetch user data");
        }
  
        const data = await dataResponse.json();
        setFormData(data);
        setCountry(data.country ? { label: data.country, value: data.country } : "");
      } catch (error) {
        console.error("Erreur lors de la récupération des données utilisateur:", error);
        navigate("/Connexion");
      }
    };
  
    fetchUserData();
  }, [isLoggedIn, navigate]);


  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
    setFormData(prevData => ({
      ...prevData,
      country: selectedOption.value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(API_URLS.UPDATE_USER_PROFILE, {
        method: "PUT",  
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      if (response.ok) {
        setIsEditing(false);
        toast({
          title: "Modification enregistrée avec succès !",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const data = await response.json(); // Added to inspect error messages from the server
        console.error("Erreur lors de la sauvegarde des modifications :", data);
        toast({
          title: "Erreur lors de la sauvegarde des modifications",
          description: "Veuillez réessayer plus tard.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des modifications :", error);
      toast({
        title: "Erreur lors de la sauvegarde des modifications",
        description: "Veuillez réessayer plus tard.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleDeleteAccount = async () => {
    try {
      const response = await fetch(API_URLS.DELETE_USER, {
        method: "DELETE",
        credentials: 'include',
      });

      if (response.ok) {
        toast({
          title: "Compte supprimé avec succès !",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate('/'); // Redirige vers la page d'accueil
      } else {
        throw new Error("Échec de la suppression du compte");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du compte :", error);
      toast({
        title: "Erreur lors de la suppression du compte.",
        description: "Veuillez réessayer plus tard.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };


  return (
    <Flex flexDirection="column"  justifyContent="center" alignItems="center" >
      <Helmet>
      <title>Informations du Client - Imagine Factory</title>
      <meta name="description" content="Modifiez vos informations personnelles ou supprimez votre compte sur Imagine Factory." />
    </Helmet>
    
       <Box
        rounded="lg"
        bg="white"
        boxShadow="lg"
        p="4"
        mt={{ base: "25%", md: "15%", lg: "15%", xl:"10%" }}
        w="full"
        maxW={{ base: "90%", md: "40%", lg: "50%", xl:"20%" }}
        mb="5%"
        position="relative"
        zIndex="2"
      >
      <Heading as="h1" size="lg" mb="5%" color="#293d36" textAlign="center">
        Mes informations
      </Heading>
      <Box width="full">
        {[
          { name: 'firstName', label: 'Prénom', type: 'text', isRequired: true },
          { name: 'lastName', label: 'Nom', type: 'text', isRequired: true },
          { name: 'email', label: 'Email', type: 'email', isRequired: true },
          { name: 'phone', label: 'Téléphone', type: 'tel', isRequired: true},
          { name: 'address', label: 'Adresse', type: 'text', isRequired: true },
          { name: 'additiveAddress', label: 'Complément d\'adresse', type: 'text' },
          { name: 'zipcode', label: 'Code postal', type: 'text', isRequired: true },
          { name: 'state', label: 'Région', type: 'text', isRequired: false },
        ].map(({ name, label, type, isRequired }) => (
          <FormControl id={name} isRequired={isRequired} mb="3" key={name}>
            <FormLabel>{label}</FormLabel>
            <Input 
              type={type}
              name={name}
              value={formData[name] || ""}
              onChange={handleInputChange}
              isReadOnly={!isEditing || name === 'email'}
              focusBorderColor="#293d36"
              borderWidth="2px"
            />
          </FormControl>
        ))}
        <FormControl id="country" mb="3" isRequired>
          <FormLabel>Pays</FormLabel>
          {isEditing ? (
            <Select
              options={countryList().getData()}
              value={country}
              onChange={handleCountryChange}
            />
          ) : (
            <Input 
              type="text"
              name="country"
              value={country.label || ""}
              isReadOnly={true}
            />
          )}
        </FormControl>
        {isEditing ? (
          <Center>
          <Button color="#293d36" background="#98b29f" borderColor="#98b29f" onClick={handleSave}>
            Enregistrer
          </Button>
          </Center>
        ) : (
          <>
          <Center>
            <Button color="#293d36" background="#98b29f" onClick={handleEdit} mt="2%">
              Modifier
            </Button>
            <Button ml="3%" mt="2%" colorScheme="red" onClick={() => setIsOpen(true)}>
              Supprimer le compte
            </Button>
            </Center>
          </>
        )}
      </Box>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le compte
            </AlertDialogHeader>
            <AlertDialogBody>
              Etes-vous sûr? Cela entrainera la suppression définitive de votre compte et ses informations.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAccount} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </Box>
    </Flex>
  );
};

export default ClientInformations;
