import { configureStore, combineReducers } from '@reduxjs/toolkit';
import cartReducer from './slices_ReduxToolkit/cartSlice.js';
import userReducer from './slices_ReduxToolkit/userSlice.js';
import orderReducer from './slices_ReduxToolkit/orderSlice.js'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // utilise le stockage local pour par défaut
import cartMiddleware from './slices_ReduxToolkit/cartMiddleware.js';
import articlesSlice from './slices_ReduxToolkit/articleSlice.js'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'cart', 'isLoggedIn', 'userId','isAdmin','country','shippingCountry','selectedCarrier','relayPoint','shippingRates', 'order'],
};

const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
  order: orderReducer,
  articles: articlesSlice,
  
  // ... d'autres réducteurs
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE'],
        // Ignorer les chemins spécifiques dans l'état et les actions
        ignoredPaths: ['_persist'],
      },
    }).concat(cartMiddleware),
    });


const persistor = persistStore(store);

export { store, persistor };