import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  Input,
  Button,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Center,
  Text,
  Box,
  useToast // Importez useToast
} from '@chakra-ui/react';
import emailjs from '@emailjs/browser';


export default function ContactForm() {
  const form = useRef();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const toast = useToast(); // Initialisez useToast

  const sendEmail = (data) => {
  
     
    const templateParams = {
      from_name: data.name,
      from_email: data.email,
      message: data.message,
      // autres champs si nécessaire
    };
  
    emailjs.send(
      process.env.REACT_APP_SERVICE_EMAILJS_ID,
      process.env.REACT_APP_TEMPLATE_EMAILJS_ID,
      templateParams,
      process.env.REACT_APP_PUBLIC_EMAILJS_KEY,
    )
    .then(
      (result) => {
        toast({
          title: "Message envoyé avec succès!",
          description: "Nous avons reçu votre message",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "bottom"
        });
      },
      (error) => {
        toast({
          title: "Echec de l'envoi du message",
          description: "Réessayer plus tard",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "bottom"
        });
      }
    );
  };
  
  
        
     
  return (
    <Box mt={{
      base:'-60%',
      sm:'-60%',
      md:'-40%',
      lg:'-30%',
      xl:'-30%',
      '2xl':'-30%',
      }}>
    <Text 
      color='white'
      m='5%'
      fontSize='2xl' 
      fontFamily='Roboto'
      style={{textAlign:'center'}}>Une question? N'hésitez pas à me contacter!</Text>
    <form 
    mt={{
      base:'-22%',
      sm:'-22%',
      md:'-12%',
      lg:'-12%',
      xl:'-12%',
      '2xl':'-12%'
    }}
    style={{
        padding:'10%',
        margin:'10%',
        marginTop:'-12%',
        flexDirection: 'column',
      }}
      ref={form}
      onSubmit={handleSubmit(sendEmail)}
    >
      
      <FormControl isInvalid={errors.name}>
        <FormLabel color='#dce5df'>Nom</FormLabel>
        <Input
          type="text"
          placeholder="Votre nom"
          backgroundColor={'white'}
          {...register("name", {
            required: "Ce champ est requis",
          })}
        />
        <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.email} mt={4}>
        <FormLabel color='#dce5df'>Email</FormLabel>
        <Input
          type="email"
          placeholder="Votre email"
          backgroundColor={'white'}
          {...register("email", {
            required: "Ce champ est requis",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email invalide",
            },
          })}
        />
        <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={errors.message} mt={4}>
        <FormLabel color='#dce5df'>Message</FormLabel>
        <Textarea
          placeholder="Votre message"
          backgroundColor={'white'}
          {...register("message", {
            required: "Ce champ est requis",
            minLength: {
              value: 10,
              message: "Le message doit contenir au moins 10 caractères",
            },
          })}
        />
        <FormErrorMessage>{errors.message && errors.message.message}</FormErrorMessage>
      </FormControl>

      <Center mt={6}>
        <Button
        type="submit" 
        color="#293d36"
        background="white">
          Envoyer
        </Button>
      </Center>
    </form>
    </Box>
  );
}
