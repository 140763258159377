import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./menu.css";
import LogoutButton from "../Logout";
import FacebookIcon from "./socialMediaIcons_Facebook.js";
import InstagramIcon  from "./socialMediaIcons_Instagram.js";


export default function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleItemClick() {
    setIsOpen(false);
  }

  function handleMenuToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <nav role="navigation" >
      <div id="menuToggle">
        <input type="checkbox" checked={isOpen} onChange={handleMenuToggle} />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu" ref={menuRef} >
          <Link to="/" onClick={handleItemClick}>
            <li>Accueil</li>
          </Link>
          <Link to="/myAccount" onClick={handleItemClick}>
            <li>Mon compte</li>
          </Link>
          <Link to="/Connexion" onClick={handleItemClick}>
            <li>Connexion</li>
          </Link>
          <LogoutButton />
          <li>
            <a href="https://www.facebook.com/ImagineFactoryCandles/" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/imaginefactorycandles/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
